@import "../../styles/utils/mixins.scss";

.loginText {
  color: #007bff;
  font-weight: bold;
  cursor: pointer;
}

.verify-btn {
  display: block;
  margin: 20px auto;
}

.successMessage {
  font-weight: bold;
}

.sign-up-header {
  padding: 15px;
  border-bottom: 2px solid #eee;
  margin-bottom: 0;

  @include mobile-breakpoint {
    margin-bottom: 20px;
  }

  h3 {
    font-weight: bold;
    font-size: 18px;

    @include mobile-breakpoint {
      font-size: 20px;
    }
  }
}

.reg-form-inner {
  padding: 15px;
}

.error,
#error {
  border: 1px solid red !important;
}

.bd-example {
  padding: 1.5rem;
  margin-right: 0;
  margin-left: 0;
  border-width: 1px;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.MuiOutlinedInput-root {
  margin-bottom: 10px;
}

.description {
  min-height: 100px;
}

.alert-danger {
  margin: auto 15px;
}
.cust-reg {
  padding: 30px 10px !important;
  box-shadow: none !important;
  margin: 0 auto !important;
  
  input {
    padding-left: 5px;
  }
}

.PhoneInputInput {
  display: block;
  width: 100%;
  border: 2px solid #ced4da;
  height: 45px;
  font-size: 14px !important;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.PhoneInputCountryIcon {
  height: 30px !important;
  width: auto !important;
}

.error-message {
  color: red !important;
  text-align: center;
  font-size: 11px;
}

.btn-wapper {
  margin-left: 0 !important;
}

.customer-reg-btn-wrapper {
  justify-content: center;
  margin-right: 0;

  .customer-reg-btn {
    width: 100%;
    padding: 20px;
  }
}