@import "../../styles/utils/mixins.scss";

.notification-cont {
    position:absolute;
    top:50px;
    left:-100px;
    width: 400px;
    height: 600px;
    overflow: auto;
    max-height: 600px;
    border: solid 1px #ccc;
    background: #fff;
    z-index: 11;
    -webkit-box-shadow: 0 0 2px #888;
    box-shadow: 0 0 2px #888;


        &::-webkit-scrollbar {
          width: 5px;
        }
        &::-webkit-scrollbar-thumb {
          background: #007bff;
          border-radius: 25px;
        }
      
        &::-webkit-scrollbar-track {
          background-color: fff;
        }

        @include sm-breakpoint {
          left:-200px;
          width:300px;
          height: 100vh;
        }
   
}

.notification-cont-title{
    font-size: 16px;
    font-weight: bold;
    color: #333;
    padding: 15px;
    text-align: left;
    }

.notification-item-cont{
min-height: 50px;
font-size: 13px;
background: #fff;
display: flex;
justify-content: flex-start;
align-items: left;
padding: 5px 25px;
color: #b1b0b0;

&:nth-child(even) {
    background: #f7f7f7 ;
  }

&:hover {
    background: #f1f1f1 ;
}

}

.notification-cont:nth-child(even) {
    background: #fff !important;
  }

.no__notification__message {
  text-align: center;
  color: #007bff;  
}






