 /*SP LISTING*/
 @import "../../../styles/utils/mixins.scss";

 .sp-listing-bg {
    background: #ebebeb;
    height: 50px;
}
.listing-search {
    margin: 15px 0;
}
.listing-filter {
    float:right;
    font-size: 14px;
    font-weight: bold;
    border: 2px solid #ced4da;
    border-radius: 3px;
    width: 110px;
    padding: 10px;
    text-align: center;
    cursor: pointer;
}
.listing-filter-icon {
    margin-right: 8px;
}
.sp-listing-img {
    width: 70px;
    height: 70px;
    object-fit: cover;
    margin: 4px 8px 8px 5px;
    border-radius: 3px;
}
.sp-listing-container {
    background: #f8f8f8;
    padding: 32px 0;
}
.sp-listing-item {
    background: #ffffff;
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 15px;
}
.sp-listing-feedback {
    margin: 6px 0 6px 0;
}
.sp-listing-last-feedback {
    margin: 0 0 8px 6px;
}
.sp-listing-inviteBtn {
    display: block;
    position: relative;
    font-size: 13px;
    padding: 12px 35px;
    border-radius: 4px;
    margin-top: 15px;
    width: 100%;

     @include mobile-breakpoint {
        position: absolute;
        right: 3px;
        top: 0;
        font-size: 13px;
        padding: 8px 25px;
        border-radius: 4px;
        width: auto;
     }
}

.sp-listing-inviteBtn-mobile {
    display: none;
}
.header-profile-mobile {
    display: none;
}
.system-notifications-header {
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    background: #f8f8f8;
    padding: 10px 0;
}
.system-notifications-header-devider {
    margin-top: 0;
}
.system-notifications {
    text-align: right;
    margin-top: 24px;
    right: 20px;
}
.system-notifications-dropdown {
    left: auto;
    right: -110px;
    margin-top: 10px;
    padding: 0 0 10px 0;
}
.notification-item {
    font-size: 12px;
    font-weight: normal;
}
.see-all-notifications {
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    color: #007bff;
}

/* ==== ADDED ==== */
.sp-listing-search {
   /* display: none;*/
   margin: 15px 0;
}
.job-posted-container {
    width: 250px;
    margin: 20px auto 20px auto;
    text-align: center;
    display: none;
}
.job-posted-success {
    margin: auto;
    text-align: center;
}

.container {
    .paginationButtons {
        justify-content: center;
        margin: 50px 0 0;
        list-style: none;
        display: flex;
        
        
  
        .page-item {
            width: 25px;
            text-align: center;
            margin: 5px;
            
            &.paginationActive{
                .page-link {
                    background: #3a89ff;
                    border-radius: 50%;
                    text-decoration: none;
                    color: #fff;
                }   
            }
  
        .page-link {
            position: relative;
            display: block;
            padding: 6px;
            color: #202020;
            font-weight: bold;
            border: 0;
            font-size: 12px;
        }
        }
    }
  }
  