.homepage {
    width: 500px;
    height: 500px;
    padding: 2rem;
    background-color: #007bff;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    border-radius: 5px;
    margin: 50px auto;
    color: #fff;
    
    h1 {
        font-size: 25px;
    }

    input {
      height: 50px;
      width: 80%;
      background-color: #fff;
      border: none;
      padding-left: 1rem;
      border-radius: 5px;
      &:focus {
        outline: none;
      }
    }
    button {
      font-size: 1rem;
      padding: 0.5rem 1rem 0.5rem 1rem;
      width: 100px;
      border: none;
      background-color: #28a745;
      border-radius: 5px;
  
      color: #fff;
      &:hover {
        cursor: pointer;
      }
    }
  }