*,*:focus,*:hover{
    outline:none !important;
}
.no-margin {
    margin: 0 !important;
}
.bell-icon {
    float: right;
    width: 40px;
    margin-top: 5px; /*CHANGED*/
}
.bell-icon-img {
    width: 16px;
    height: auto;
}
.login-button-mobile {
    display: none;
}
.profile-button {
    float: right;
    font-size: 14px;
    font-weight: bold;
    width: 90%;
}
.profile-button-img {
    width: 20px;
    height: 20px;
    object-fit: cover;
    border-radius: 50%;
}
.form-control {
    border: 2px solid #ced4da; /*CHANGED*/
    height: 50px;
    font-size: 14px;
}
.ic-search {
    position: absolute;
    display: block;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
    margin-top: 14px;
    margin-left: 14px;
    width: 18px; /*CHANGED*/
    height: 18px; /*CHANGED*/
}

/*PROFILE TABS*/
.edit-btn-modal {
    background: none;
    border: none;
    color: #3989f4;
    font-weight: 600;
}
.upload-portfolio-img {
    font-size: 14px;
    width: 30%;
}
.pagination-controls { /*CHANGED*/
    position: relative;
    display: block;
    padding: 6px;
    color: #202020;
    font-weight: bold;
    border: 0;
    font-size: 12px;
}

/*MOBILE MENU*/
.quit-profile {
    position: absolute;
    top: 35px;
    right: 20px;
}

/* JOBS TABS */
.jobs-tabbed-menu {
    margin-top: 0 !important;
}

.jobs-tab-header>.nav-tabs {
    border: none;
    margin: 0px;
    height: 30px;
}
.jobs-tab-header>.nav-tabs>li {
    margin-right: 5px;
    min-width: 10%;
}
.jobs-tab-header>.nav-tabs>li>a {
    border: 0;
    margin-right: 0;
    text-align: center;
    color: #737373;
    padding: 0 3px 0 3px;
    height: 32px;
}
.jobs-tab-header>.nav-tabs>.alljobsTab>a.active {
    
    border-bottom: 2px solid #ff7b00;
    color: #ff7b00;
    height: 32px;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    border-bottom: 2px solid #ff7b00;
    color: #ff7b00;
}

.nav-item {
    cursor: pointer;
}

.jobs-tab-header>.nav-tabs>.alljobsTab>a:hover {
    height: 32px;
    color: #ff7b00;
}
.jobs-tab-header>.nav-tabs>.interestedjobsTab>a.active {
    
    border-bottom: 2px solid #007bff;
    color: #007bff;
    height: 32px;
}
.jobs-tab-header>.nav-tabs>.interestedjobsTab>a:hover {
    height: 32px;
    color: #007bff;
}
.jobs-tab-header>.nav-tabs>.shortlistedTab>a.active {
    
    border-bottom: 2px solid #12b448;
    color: #12b448;
    height: 32px;
}
.jobs-tab-header>.nav-tabs>.shortlistedTab>a:hover {
    height: 32px;
    color: #12b448;
}
.jobs-tab-header>.nav-tabs>.hiredTab>a.active {
    
    border-bottom: 2px solid #ffe600;
    color: #ffe600;
    height: 32px;
}
.jobs-tab-header>.nav-tabs>.hiredTab>a:hover {
    height: 32px;
    color: #ffe600;
}
.jobs-tab-header>.nav-tabs>.unavailable>a.active {
    
    border-bottom: 2px solid #999999;
    color: #999999;
    height: 32px;
}
.jobs-tab-header>.nav-tabs>.unavailable>a:hover {
    height: 32px;
    color: #999999;
}
.jobs-filter, .jobsItem-inner {
    margin-top: 20px;
}
.jobs-cat-inner {
    background: #f8f8f8;
    border-radius: 5px;
    margin-top: 20px;
    padding: 12px 12px 12px 12px;
}
.jobs-content {
    background: #ffffff;
    border-radius: 5px;
    padding: 10px 10px 8px 8px;
    margin: 0 0 20px 0;
    border-bottom: 1px #f8f8f8 solid;
}
.jobs-title {
    font-size: 13px;
    font-weight: bold;
    margin: 0;
    padding: 0;
}
.jobs-img-container {
    max-width: 100px;
}
.jobs-img {
    width: 70px;
    height: 90px;
    object-fit: cover;
    margin: 4px 8px 8px 5px;
    border-radius: 3px;
}
.jobs-inner {
    margin: 3px 0 0 3px;
}
.jobs-price {
    font-size: 15px;
    color: #408df4;
    font-weight: bold;
    padding: 0;
    margin: 3px 0 0 0;
}
.jobs-invited {
    font-size: 12px;
    color: #0cb458;
    margin: 3px 0 0 0;
    text-align: right;
    font-weight: 600;
}
.invited-check {
    font-size: 14px;
    margin-right: 5px;
    vertical-align: middle;
}
.jobs-content-footer {
    font-size: 13px;
    border-top: 2px #f8f8f8 solid;
    margin: 4px 0 4px 6px;
}
.jobs-location {
    width: 50%;
    margin-top: 8px;
    display: flex;
    color: #202020;
}
.jobs-location-name {
    margin-left: 3px;
}
.jobs-post-date, .shortlisted, .interested {
    font-size: 12px;
    width: 50%;
    text-align: right;
    margin-top: 8px;
    color: #aaa;
}
#tab-2 {
    display: none;
}/*JOBS VERTICAL TAB*/
.jobs-gallery-container,
.thumbs-wrapper {
    position: absolute;
    display: flex;
    bottom: -30px;
    margin: 0 5% !important;
    width: 100%;
    z-index: 2;
}

.jobs-tabbed-gallery, .apply-container {
    position: relative;
    margin: 20px 0 0 10px;
}
.jobs-gallery, 
.thumb {
    display: inline-flex !important;
    width: 40% !important;
    padding: 5px !important;
    border: none !important;
    height: 55px;
}

/* Style the images inside the grid */
.jobs-gallery img, 
.thumb img {
    cursor: pointer;
    border-radius: 3px;
}

/* The expanding image container */
.jobs-img-expand {
    display: flex;
    width: 100%;
    height: 300px;
    object-fit: contain;
    // margin-top: 12px;

    img {
        object-fit: cover;
    }
}
.no-photo {
    background: #f8f8f8;
    border-radius: 3px;
}
.no-photo-expanded {
    display: block;
    width: 48px;
    height: 48px;
    margin: 120px auto;
}

.jobs-gallery-tag {
    position: absolute;
    right: 20px;
    margin-top: 15px;
    background: #3989f4;
    font-size: 11px;
    color: #fff;
    padding: 3px 10px;
    border-radius: 20px;
    font-style: normal;
    font-weight: 600;
    z-index: 9;
}

.tag-shortlisted {
    background: #0cb458;
    z-index: 9;
}
.tag-hired {
    background: #ffe600;
    z-index: 9;
}
.tag-unavailable {
    background: #6b6b6b;
    z-index: 9;
}

.jobs-galleryImg-lg {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
    border-radius: 3px;
}
.jobs-details-container {
    display: flex;
    height: 25px;
}
.job-poster {
    font-size: 13px;
    font-weight: 600;
    color: #408df4;
    width: 50%;
    text-align: left;
}
.jobs-price-label {
    font-size: 12px;
    font-weight: 600;
    width: 50%;
    text-align: right;
}
.jobs-category {
    font-size: 15px;
    width: 50%;
    font-weight: 600;
}
.jobs-details-location {
    color: #aaa;
}
.jobs-apply-container {
    margin: 30px 0 0 10px;
}
.jobs-apply-info {
    font-size: 12px;
    color: #aaa;
    border-bottom: 2px #f8f8f8 solid;
    padding-bottom: 10px;
    margin: 5px 0 10px 0;
}

.jobs-description {
    font-size: 14px;
    font-weight: bold;
    color: rgb(77, 76, 76);
    border-bottom: 2px #f8f8f8 solid;
    padding-bottom: 10px;
    margin: 5px 0 10px 0;
}

.fee {
    margin-left: 8px;
}
.interested {
    text-align: left !important;
}
.apply-container {
    display: flex;
    margin-top: 0;
}
.apply-btn {
    margin-left: 10px;
    font-size: 13px;
}
.accept-btn, .interest-btn {
    margin: 10px 0 0 10px;
    font-size: 13px;
    width: 100%;
}
.jobs-tablinks:hover, .jobs-tablinks.active {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.express-interest-form {
    .modal-dialog{
        top: 10%;

        .form-control {
            padding-left: 5px;
        }

        .react-datepicker {
            position: absolute;
            z-index: 100;
        }
    }
}


.time__dayweekmonthpicker { 
    position: relative;
}

.jobs-work-time {
    position: absolute;
    width: 70px;
    height: 20px;
    right: 2px;
    top: 2px;
    background: #f8f8f8;
    border: 0;
    font-size: 13px;
    border-radius: 3px;
}
.jobs-work-time-txt {
    padding-right: 90px;
}
.jobs-salary {
    display: flex;
}
.jobs-salary-checkbox {
    margin: 2px 5px 0 3px;
    vertical-align: middle;
}
.jobs-salary-label {
    font-size: 12px;
    font-weight: 600;
}
.jobs-apply-modal {
    font-size: 12px;
    color: #aaa;
}
.jobs-apply-success-icon {
    font-size: 36px;
    color: #0cb458;
    display: block;
    margin-left: auto;
    width: 55%;
}
.apply-success {
    text-align: center !important;
    font-weight: normal !important;
}
.bi-telephone, .bi-chat-left-dots {
    margin-right: 5px;
}
.hire-request-container {
    display: flex;
    margin-bottom: 15px;
}
.hire-request-txt {
    max-width: 60% !important;
    width: auto;
    margin: 0;
}
.hire-request-btn {
    font-size: 13px;
    max-width: 50%;
    width: 40%;
}
.hired-feedback-img {
    margin: 8px 0 5px 0;
}
.hired-feedback {
    margin: 10px 0 0 7px;
}
.feedback-reminder {
    width: 50%;
    font-size: 12px;
    text-align: right;
    margin-top: 8px;
    font-weight: bold;
    text-decoration: underline;
}
.reminder-container {
    border: 2px #ced4da solid;
    border-radius: 3px;
    padding: 0 8px;
    height: 38px;
}
.unavailable-job {
    text-align: center;
}
.job-listing-container {
    background: #f8f8f8;
}
.job-listing-item {
    background: #ffffff;
    border-radius: 5px;
}
.chat-modal-footer {
    flex-wrap: nowrap !important;
}
.chat-input {
    width: 100%;
    border: none;
    font-size: 12px;
}
.send-msg-btn {
    width: 120px;
}
.send-msg-icon {
    margin-right: 7px;
    vertical-align: text-bottom;
}
.chat-msg-line {
    display: flex;
    float: left;
    margin-bottom: 10px;
}
.chat-msg-line-right {
    display: flex;
    float: right;
    margin-top: 12px;
}
.chat-profile-pic {
    width: 35px;
    height: 35px;
}
.chat-msg-me, .apply-form {
    background: #f8f8f8;
    font-size: 12px;
    padding: 8px;
    border-radius: 3px;
    margin-left: 5px;
    max-width: 150px;
    color: #545454;
}
.chat-msg-other {
    background: #0cb458;
    font-size: 12px;
    padding: 8px;
    border-radius: 3px;
    color: #fff;
    margin: 0 5px 12px 5px;
}
.chat-msg-time {
    height: 30px;
    font-size: 8px;
    color: #aaa;
    margin-left: 5px;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
}
.form-dot {
    display: inline-block;
    color: #fff;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    border: 2px #59a1ff solid;
    margin-right: 8px;
}
.job-start-date, .work-time, .salary {
    font-size: 12px;
    display: inline-block;
    margin-bottom: 10px;
}
.salary {
    margin-left: 14px;
}
.apply-form {
    width: 185px;
    max-width: 200px;
}
.form-modal-footer {
    border-top: 0 !important;
}
.send-new-form {
    width: fit-content;
    background: #f8f8f8;
    border-radius: 20px;
    margin-left: 15px;
    padding: 8px 12px;
    cursor: pointer;
    color: #59a1ff;
    font-size: 13px;
}
.use-template {
    margin-top: 8px;
    margin-bottom: 8px;
}
.send-new-form a, .send-new-form a:hover {
    font-size: 13px;
    padding: 12px;
    text-decoration: none;
}
#chat-template {
    position: absolute;
    font-size: 13px;
    bottom: 125px;
    margin-left: 20px;
    max-width: 260px;
    box-shadow: 2px 2px 8px 4px rgba(0, 0, 0, 0.2);
    display: none;
  }
  
  .chat-template-header {
    font-weight: bold;
    color: rgb(26, 26, 26);
    margin-bottom: 3px;
    margin-top: 10px;
  }
  .chat-template-txt {
      padding-bottom: 10px;
      border-bottom: 2px #f8f8f8 solid;
      margin: 0;
  }
  #chat-template-msg {
      display: none;
  }  
  .chat-template-container {
    padding: 15px 15px 0 15px;
    background-color: rgb(255, 255, 255);
    color: rgb(43, 43, 43);
    cursor: pointer;
  }
  
  .chat-template-closebtn {
    position: absolute;
    right: 10px;
    color: rgb(75, 75, 75);
    font-size: 30px;
    cursor: pointer;
  }
  
  .chat-template-closebtn:hover {
    color: rgb(51, 51, 51);
  }

  @media screen and (max-width: 521px) {
    
    .navi-user-container {
        padding-top: 15px;
    }

    .login-button-mobile {
        display: block;
        float: right;
        padding: 12px;
        background: #f8f8f8;
        border-radius: 50%;
    }
    .profile-button {
        display: none;
    }
    .bell-icon {
        // float: right;
        width: 40px;
        // margin-top: 10px; /*CHANGED*/
        // padding-left: 10px;
    }
    .insta-icon {
        margin-left: 5px;
    }
    .job-carousel {
        max-height: 200px;
        object-fit: cover;
    }
}

@media screen and (min-width: 521px) {
    .job-carousel {
        max-height: 350px;
        object-fit: cover;
    }
    
    .chat-modal {
        max-width: 360px !important;
    }
}

@media screen and (max-width: 991px) {
    .bell-icon-img {
        width: 22px;
        height: 20px;
    }
    .sidebar-header-mobile { /*CHANGED*/
        display: flex;
        width: 100%;
    }
    /*JOBS*/
    .all-jobs-title {
        background: #f76437;
        color: #fff;
        margin: 0;
    }
    .interested-jobs-title {
        background: #3989f4;
        color: #fff;
        margin: 0;
    }
    .shortlisted-title {
        background: #0cb458;
        color: #fff;
        margin: 0;
    }
    .hired-title {
        background: #fccc30fa;
        color: #fff;
        margin: 0;
    }
    .unavailable-title {
        background: #8a8a8a;
        color: #fff;
        margin: 0;
    }
    .jobs-filter {
        margin: 0;
        padding: 12px 12px 8px 12px !important;
        background: #fff;
    }
    .jobs-cat-inner {
        margin-top: 0;
    }
    .jobs-title {
        font-size: 15px;
    }
    .jobs-price {
        width: 50%;
        float: left;
    }
    .jobsItem-price {
        width: 50%;
        text-align: right;
    }
    .jobs-invited {
        width: 50%;
        float: right;
    }
    .feedback-pagination {
        margin: 0;
    }
    .sidebar-jobs {
        background: #f8f8f8;
    }
    .sidebar-header-jobs {
        background: #fff;
    }
    .shortlisted-btns {
        border-bottom: 2px #f8f8f8 solid;
        padding: 5px 0 8px 0;
    }
    .shortlisted-mobile-btns {
        margin-top: 10px;
    }
    .accept-btn {
        margin: 0 0 0 5px;
    }
    .jobsItem-accept-btn {
        margin: 15px 0;
    }
    .interest-btn {
        margin: 0;
    }
    .badge {
        position: absolute;
        top: -10px;
        right: 8px;
        padding: 3px 7px;
        border-radius: 50%;
        background-color: red;
        color: white;
        border: 2px #fff solid;
        font-size: 11px;
    }
    .jobs-location-name {
        font-size: 13px;
    }
    .reminder-txt {
        width: 100%;
        text-align: center;
    }
    .reminder-container {
        width: 100%;
        margin-top: 8px;
        display: flex;
    }
    .feedback-reminder {
        width: 100%;
        text-align: center;
        text-decoration: none;
    }
    .jobs-apply-container {
        margin: 15px 0 0 0;
        border-top: 2px #f8f8f8 solid;
    }
    .apply-container {
        margin-left: 0;
    }
    .close-btn {
        position: absolute;
        top: 25px;
        right: 15px;
    }
    .jobsItem-close {
        font-size: 30px;
    }
    .apply-btn {
        margin: 0;
    }
    .jobs-img-expand {
        height: 200px;
        margin-top: 0;
    }
    .no-photo-expanded {
        margin: 80px auto;
    }
    .hired-job-feedback {
        border-top: 2px #f8f8f8 solid;
        margin-top: 12px;
        padding-top: 10px;
    }
    .hire-request-container {
        display: block;
        margin: 10px 0;
        padding-bottom: 15px;
        border-bottom: 2px #f8f8f8 solid;
    }
    .hired-txt {
        text-align: center;
        margin-bottom: 10px;
    }
    .send-hire-request {
        color: #408df4;
        font-size: 13px;
        font-weight: 600;
    }
}
.tab-content {
    min-height: 300px;
}

.shortlisted-btn-wrapper {
display: flex !important;
.call-btn , 
.chat-btn {
    margin-right: calc(20px - 2px);
    margin-top: 0 !important;
}
}