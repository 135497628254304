.faq__container{
    background-color: #f7f7f7;
    width: 50%;
    margin: 50px auto 50px auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    padding: 1rem 0;
}

.faq__question {
    height: 50px;
    display: flex;
    gap: 2rem;
    font-weight: bold;
    align-items: center;
    padding-left: 1rem;
    cursor: pointer;
}


.faq__answer {
    text-align: justify;
    margin: 0 1rem;
    color: #909090;
}

.rotate90 {
    transform: rotate(45deg);
    transition: 0.2s linear all;
}
.rotateneg90 {
    // transform: rotate(45deg);
    transition: 0.2s linear all;
}

.faq__content {
    width: 90%;
    background-color: #fff;
}


.line__before {
    height: 2px;
    margin: 0 2px 1rem 0;
    background-color: #f7f7f7;
  }

.faq__tabs ul > li {
    cursor: pointer;
    list-style: none;
    display: inline-block;
    font-size: 18px;
    font-weight: bold;
    margin-right: 2rem;
}

.faq__tab {
    color: #007bff;
    text-decoration: underline;
    text-underline-offset: 8px;
}

@media only screen and (max-width: 450px){
    .faq__container{
        width: 100%;
    }

    .faq__question {
        height: auto;
        justify-content: space-between;
        font-size: 15px;
        padding: 0.5rem 1rem 0.5rem 1rem;
    }

    .faq__message {
        text-align: center;
    }
    
    .faq__tabs {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .faq__tabs ul {   
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .faq__tabs ul > li {
        margin: 0;  
        font-size: 16px;
    }
}


