.comment__textbox__container {
    width: 700px;
    font-family: Montserrat;
}

.blog__leave__comment {  
    width: 100%;
    height: 70px;
    padding: 8px;
    border-radius: 4px;
    border: solid 1px #dedede;
    font-size: 14px;
    text-align: left;
    color: #707070;
}

.leave__comment__button {
    width: 80px;
    height: 44px;
    margin: 1rem 1rem 0 0;
    border: none;
    border-radius: 4px;
    background-color: #3989f4;
    color: #efefef;
    // opacity: 0.5;
    font-size: 14px;
    font-weight: 600;
    align-self: flex-end;
  }

.leave__comment__button_disabled {
    width: 80px;
    height: 44px;
    margin: 1rem 1rem 0 0;
    border: none;
    border-radius: 4px;
    background-color: #3989f4;
    color: #efefef;
    opacity: 0.5;
    font-size: 14px;
    font-weight: 600;
    align-self: flex-end;
  }

  @media only screen and (max-width: 600px){
    .comment__textbox__container {
      width: auto;
  }
  }