@import "~bootstrap/scss/bootstrap";
@import "./styles/utils/mixins.scss";
@import "src/components/home/home.scss";
@import "./globals";

html {
  overflow-x: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.auth-background {
  // background: url(./images/register_bg.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  // min-height: 100%;
  margin: 0;
  padding: 0;
  // height: 100vh;
  // padding-bottom: 75%;
}

.body-background {
  background: url(./images/register_bg.png);
  background-position: center center;

}

.error {
  color: #d84144;
}
.hidden {
  display: none;
}
.react-datepicker-wrapper {
  width: 100%;
}

.landing-page-shown {
  display: table-cell;
  width: 100vw;
  height: 100vh;
  margin-top: -10px;
  background: url(./images/register_bg.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 50px;
  .landing-header {
    padding: 15px;
    text-align: center;
  }

  .header-wrapper,
  .footer {
    display: none;
  }

  .pre-register {
    width: 200px;
    margin: 100px auto;
  }

  .content {
    color: #fff;
    margin: 120px auto;
    text-align: center;
    padding: 0 20px;
  }
}

.chat-app {
  width: 100%;
  height: 100vh;
  background-color: $backgroundColor;
  display: flex;
  justify-content: center;
  align-items: center;
  .right {
    flex: 2;
  }
  .left {
    flex: 1;
  }
}

.modal {
  background: rgba(0, 0, 0, 0.7) !important;
}

.language-select {
  display: inline-flex;
  width: 70px;
  padding-left: 5px;

  @include mobile-breakpoint {
    width: 30%;
  }
}

.alert-danger {
  display: block;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  justify-content: center;

  .alertMessage {
    justify-content: center;
  }
}
