@import "../../../styles/utils/mixins.scss";
/* CUSTOMER SIDE */

.customer-jobs-container {
    background: #f8f8f8;
    border-radius: 5px;
}
.job-inner-customer {
    padding: 20px 20px 0 20px;
}
.job-inner-container {
    background: #ffffff;
    border-radius: 5px;
    padding: 10px 10px 8px 8px;
}
.job-inner-container:hover {
    // box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    cursor: pointer;
}
.job-top-section {
    border-bottom: 2px #f8f8f8 solid;
    margin: 0 6px;
}
.job-top-section-left {
    width: 50%;
}
.job-top-section-right {
    width: 50%;
    text-align: right;
}
.job-category-customer {
    font-size: 13px;
    color: #007bff;
    margin-top: 5px;
    font-weight: 600;
}    
.job-posted-time {
    font-size: 11px;
    color: #aaa;
    margin-bottom: 5px;
}
.jobs-reacted {
    font-size: 12px;
}
.job-bottom-section {
    display: grid;
}
.invite-sp {
    font-size: 12px;
    font-weight: bold;
    text-decoration: underline;
    margin: 20px auto 10px auto;
}
.shortlist-persons {
    font-size: 12px;
    color: #aaa;
    margin: auto;
}
.interested-sp-btn-container {
    max-width: 260px;
    margin: 15px auto 12px auto;
}
.interested-sp-btn {
    font-size: 12px;
    padding: 10px 15px;
}
.hired-job-btn-container {
    width: 80%;
    margin: 20px auto 0 auto;
}
.customer-msg-badge {
    position: absolute;
    top: 0px;
    right: 0;
    padding: 3px 8px;
    border-radius: 50%;
    background-color: red;
    color: white;
    border: 2px #fff solid;
    font-size: 9px;
}
.empty-space {
    padding-top: 20px;
}


/* 404 */
.not-found-container {
    width: 300px;
    margin: 100px auto 100px auto;
    display: grid;
}
.not-found-img {
    height: 150px;
    width: auto;
    margin: auto;
}
.not-found {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
}
.not-found-txt {
    font-size: 12px;
    color: #aaa;
    text-align: center;
}
.not-found-btn {
    font-size: 12px;
    width: 100px;
    margin: auto;
    background: #007bff;
    border-radius: 3px;
    color: #fff;
    text-align: center;
    padding: 7px 0;
}
.not-found-btn:hover {
    color: #fff;
    text-decoration: none;
}

/* CONTACT US */
.contact-container, .whoweare-container {
    margin-top: 30px;
    display: flex;
}
.contact-img {
    max-height: 350px;
    width: auto;
}
.contact-info {
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 6px;
    margin-top: 35px;
}
.contact-detail {
    font-size: 12px;
    color: #aaa;
}
.contact-form-title {
    margin-top: 40px;
    margin-left: 12px;
    font-size: 16px;
    font-weight: bold;
}
.contact-text textarea {
    height: 106px;
}
.contact-send-btn {
    font-size: 14px;
    padding: 10px 0;
}
.contact-form-container {
    margin-bottom: 160px;
}

/* CAREER */
.career-vacancies-bg {
    background: #f8f8f8;
}
.vacancies-container {
    margin: 50px auto 100px auto;
}
.vacancy-row {
    padding-right: 8px;
    padding-left: 8px;
}
.vacancy-container {
    background: #fff;
    border-radius: 4px;
    padding: 12px;
    margin-top: 16px;
}
.vacancy-container-expired {
    background: rgba(0, 0, 0, 0.61);
    border-radius: 4px;
    padding: 12px;
    margin-top: 16px;
}
.vacancy-title {
    width: 100%;
}
.vacancy-date {
    font-size: 11px;
    text-align: right;
}
.exp-dot, .exp-dot-expired {
    position: absolute;
    right: 13px;
    margin-top: -7px;
    width: 5px;
    height: 5px;
    background: #0cb458;
    border-radius: 50%;
}
.exp-dot-expired {
    background: #850101; 
}
.vacancy-description, .whoWeAre-description {
    font-size: 13px;
    color: #aaa;
    margin-bottom: 0;
}
.vacancy-description-expired {
    font-size: 13px;
    margin-bottom: 0;
}
.vacancy-expired-text {
    position: absolute;
    margin-top: 26px;
    left: 50%;
    font-size: 18px;
    color: white;
    transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
}
.vacancy-description-full {
    margin-left: 0;
}
.vacancies-container > li {
    list-style-type: circle;
    color: #0076d6;
    margin-top: 12px;
}
.job-apply-container {
    margin: 20px auto 70px auto;
}
.job-apply-section {
    border-top: 2px #f8f8f8 solid;
}
.contact-form-info {
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
}
.upload-cv-container {
    width: 300px;
    margin: 150px auto;
}
.upload-cv-inner {
    padding: 15px 0;
    border-top: 2px #f8f8f8 solid;
    border-bottom: 2px #f8f8f8 solid;
}
.upload-cv-label {
    position: absolute;
    z-index: 1;
    font-size: 13px;
    font-weight: bold;
    margin-left: 80px;
    margin-top: 10px;
}
.upload-cv-icon {
    text-align: left;
    float: left;
    font-size: 18px;
    margin-top: -1px;
}
.upload-cv-btn {
    font-size: 13px;
    width: 300px;
    margin: 18px auto;
}
#send-form {
    display: block;
}
#send-success {
    display: none;
    text-align: center;
    margin-top: 100px;
}
.send-success-icon {
    color: #0cb458;
    font-size: 30px;
    border: 2px #0cb458 solid;
    border-radius: 50%;
    padding: 6px 10px;
}
.send-success-txt {
    font-size: 13px;
    color: #0cb458;
    margin-top: 20px;
}

/* WHO WE ARE */
.whoweare-container {
    margin-bottom: 70px;
}
.whoWeAre-title {
    margin-top: 40px;
    font-size: 16px;
    font-weight: bold;
}
.whoWeAre-carousel {
    width: 45%;
    float: left;
    margin-right: 15px;
}
.about-slider {
    width: 100%;
    height: 260px;
    object-fit: cover;
}
.who-we-are {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}
.whoWeAre-bottom-section {
    margin-bottom: 100px;
}

/* FAQ */
.question-container {
    background: #fff;
    border-radius: 4px;
    padding: 20px 15px 5px 15px;
    margin-top: 16px;
}
.question-card {
    transition: 0.3s;
    display: inline-block;
    margin-top: 5px;
    border-top: 1px solid rgba(0,0,0,.125);
}
.question-card-body {
    padding: 20px 0 10px 0;
    width: 100%;
}
.faq-arrow-container {
    float: right;
}
.faq-arrow, .faq-arrow-down {
    width: 20px;
    height: 20px;
}
.faq-collapsible.collapsed .faq-arrow {
    display: block;
}
.faq-collapsible.collapsed .faq-arrow-down {
    display: none;
}
.faq-collapsible:not(.collapsed) .faq-arrow {
    display: none;
}
.faq-collapsible:not(.collapsed) .faq-arrow-down {
    display: block;
}
.faq-collapsible:not(.collapsed){
    // box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}

/* HOW IT WORKS */
.howItWorks-img {
    height: 70px;
    width: auto;
}
.howItWorks-mobile {
    display: none;
}
.howItWorks-title {
    font-size: 15px;
    font-weight: bold;
    margin-top: 8px;
}
.howItWoeks-description {
    font-size: 12px;
    color: #aaa;
    width: 140px;
}
.howItWorks-btn-mobile {
    display: none;
}
.howItWorks-btn {
    font-size: 13px;
    float: right;
}
.howItWorks {
    margin: 40px auto 150px auto;
}
.howItWorks-top {
    margin-bottom: 40px;
}
.howItWorks-hr-mobile {
    display: none;
}

/* LEAVE FEEDBACK */
.positive-feedback-img, .neutral-feedback-img, .negative-feedback-img {
    width: 40px;
    height: 40px;
    cursor: pointer;
}
.neutral-feedback-img {
    display: block;
    margin: auto;
}
.negative-feedback-img {
    display: block;
    margin: 0 4px 0 auto;
}
.feedback-type-pos, .feedback-type-neu, .feedback-type-neg {
    font-size: 12px;
    font-weight: bold;
    margin: 8px 0 0 0;
}
.feedback-type-neu {
    text-align: center;
}
.feedback-type-neg {
    text-align: right;
}
.feedback-modal-footer, .feedback-modal-header {
    border: none;
}

/* ======CUSTOMER JOBS PAGE====== */
.customer-jobs-menu button {
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    background: #fff;
    padding: 12px 8px;
    border-radius: 4px;
    margin-bottom: 20px;
    cursor: pointer;
    border: none;
    width: 100%;
}
.customer-job-page, .edit-customer-job-page, .delete-customer-job-page{
    margin: 0;
    padding: 0 5px !important;
}

.sp-description {
    color: rgb(72, 62, 62);
    font-weight: bold;
    text-align: justify;
    font-size: 15px;
}

.customer-jobs-menu button:hover {
    background: #408df4;
    color: #fff;
}

.customer-jobs-details-container {
    display: flex;
}
.customer-jobs-img {
    width: 100%;
}
#customer-jobs-gallery {
    position: absolute;
    margin-top: 8px;
    left: 8px;
    width: 142px;
    background: #fff;
    height: 30px;
    border-radius: 20px;
    cursor: pointer;
    display: none;
    z-index: 9;
}
.customer-jobs-addImg-btn {
    font-size: 11px;
    font-weight: bold;
    position: absolute;
    left: 8px;
}
#customer-jobs-gallery-del {
    position: absolute;
    margin-top: 8px;
    right: 8px;
    width: 80px;
    background: #fff;
    height: 30px;
    border-radius: 20px;
    cursor: pointer;
    display: none;
    z-index: 9;
}
.customer-jobs-del-btn {
    font-size: 11px;
    font-weight: bold;
    position: absolute;
    right: 14px;
    margin-top: 6px;
}

.plus-icon {
    font-size: 20px;
    vertical-align: sub;
}
.trash-icon {
    font-size: 14px;
    vertical-align: middle;
}
#customer-edit-container {
    margin-top: 15px;
    border: 2px solid #ced4da;
    border-radius: 4px;
    height: 75px;
    padding-bottom: 115px;
    display: none;
}
.customer-job-description {
    font-size: 13px;
    overflow: hidden;
    border: none !important;
}
.customer-edit-job-submit {
    font-size: 13px;
    padding: 7px 20px;
    position: absolute;
    right: 8px;
    margin-top: 4px;
}
.customer-jobs-gallery {
    margin: 0;
}
.customer-gallery-inner {
    margin: 0 auto;
    display: flex;
    width: 90%;
}
.customer-jobs-gallery-img {
    display: inline-flex;
    padding: 5px;
    width: auto;
    height: 65px;
    margin: auto;
}
.remove-job-modal {
    max-width: 500px;
}
.remove-job-options {
    padding: 15px;
    margin-left: 25px;
}
.remove-job-options-hr {
    border: 1px #f8f8f8 solid;
    margin: 0;
}
.remove-job-pencil-icon {
    position: absolute;
    right: 25px;
    top: 25px;
}
.remove-job-max-symbols {
    position: absolute;
    right: 25px;
    top: 115px;
    font-size: 10px;
    color: #929292;
}
.remove-job-radio:checked ~ label {
    color: #007bff;
  }
.sp-list-container {
    height: 1035px;
    overflow-y: auto;
}
.sp-customerView-msg {
    font-size: 12px;
    margin: auto;
    padding-top: 7px;
    text-align: center;
    color: #0cb458;
}
.customer-jobs-inner {
    background: #f8f8f8;
    border-radius: 5px;
    margin-top: 20px;
}
.customer-jobs-menu {
    display: flex;
    padding: 10px 5px 0 5px;
}
.customer-jobs-invite-txt {
    width: 300px;
    margin: auto;
}
.customer-jobs-invite-btn {
    font-size: 12px;
    font-weight: bold;
    margin: 10px auto 10px auto;
    text-align: center;
}
.customer-jobs-posted {
    font-size: 11px;
    color: #aaa;
    padding: 0 10px 0 10px;
}
.customer-jobs-hired-sp, .customer-jobs-shortlisted-sp, .customer-jobs-interested-sp {
    font-size: 13px;
    font-weight: bold;
    color: #ffe600;
    margin: auto 5px auto 10px;
}
.customer-jobs-shortlisted-sp {
    color: #12b448;
}
.customer-jobs-interested-sp {
    color: #007bff;
}
.customer-jobs {
    margin: 10px 10px 20px 10px;
}
.sp-profileView-slider {
    display: flex;
    width: 100%;
    height: 260px;
    object-fit: contain;
    margin-top: 8px;
}
.sp-indicators .active {
    background: none;
    border: 2px #fff solid;
}
.sp-indicators li {
    background: #fff;
    opacity: 1;
}
.sp-profileView-feedback {
    display: flex;
    margin: 12px 0;
    padding-bottom: 15px;
    border-bottom: 2px #f8f8f8 solid;
}
.portfolio-title {
    margin-left: 12px;
}
.sp-portfolio {
    -webkit-mask-image: initial !important;
}
.sp-portfolio-img-container {
    margin-right: 10px;
    box-shadow: none !important;
    border: none;
}
.sp-portfolio-img-container:hover {
    box-shadow: none !important;
}
.sp-portfolio-img {
    height: 120px;
    width: 120px;
    border-radius: 4px;
    object-fit: cover;
}
.sp-portfolio-arrow-l, .sp-portfolio-arrow-r{
    width: 40px;
    height: 40px;
}
.sp-portfolio-next {
    margin-top: 55px;
    right: 5px;
}
.sp-portfolio-prev {
    margin-top: 55px;
    left: 0;
}
.sp-certificates {
    display: flex;
}
.sp-certificate-container {
    background: #fff;
    border-radius: 4px;
    padding: 15px;
    margin-top: 16px;
}
.sp-certificate-pdf {
    color: #0076d6;
}
.sp-certificate-title {
    margin-top: 7px;
}
.sp-allFeedbacks-container {
    background: #fff;
    border-radius: 4px;
    padding: 15px;
    min-width: 300px;
    max-width: 350px;
    white-space: normal;
}
.sp-allFeedback-next {
    margin-top: 95px;
    right: 5px;
}
.sp-allFeedback-prev {
    margin-top: 95px;
    left: 0;
}
.sp-buttons-container {
    display: flex;
    margin: 10px auto 10px auto;
}
.sp-button-right {
    padding: 0 0 0 5px;
}
.sp-button-left {
    padding: 0 5px 0 0;
}
.sp-button-center {
    padding: 0;
}
.sp-buttons {
    width: 100%;
    font-size: 13px;
}

/* Works on Firefox */
.sp-list-container {
    scrollbar-width: none;
    scrollbar-color: #0076d6 #f8f8f8;
  }
  
  /* Works on Chrome, Edge, and Safari */
  .sp-list-container::-webkit-scrollbar {
    width: 4px;
  }
  
  .sp-list-container::-webkit-scrollbar-track {
    background: none;
  }
  
  .sp-list-container::-webkit-scrollbar-thumb {
    background-color: #0076d6;
    border-radius: 30px;
    border: none;
  }
  .all-feedbacks-nav {
    padding: 0 0 12px 12px;
    margin: 0;
    /* Make this scrollable when needed */
    overflow-x: auto;
    /* We don't want vertical scrolling */
    overflow-y: hidden;
    /* Make an auto-hiding scroller for the 3 people using a IE */
    -ms-overflow-style: -ms-autohiding-scrollbar;
    /* For WebKit implementations, provide inertia scrolling */
    -webkit-overflow-scrolling: touch;
    /* We don't want internal inline elements to wrap */
    white-space: nowrap;
    /* Remove the default scrollbar for WebKit implementations */
    -webkit-mask-image: linear-gradient(90deg, black, rgba(59, 59, 59, 0.6));
}
.all-feedbacks-nav::-webkit-scrollbar {
    display: none;
}
.navv-prev {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 0;
    margin-top: 100px;
    z-index: 3;
    display: none;
    cursor: pointer;
}

.navv-next {
    width: 50px;
    height: 50px;
    position: absolute;
    right: 7px;
    margin-top: 100px;
    z-index: 3;
    cursor: pointer;
}


/* FORGOT PASSWORD SUCCESS TOAST */
.success-toast {
    position: absolute; 
    bottom: 0;
    width: 300px; 
    z-index: 3; 
    margin: auto;
}


@media screen and (max-width: 521px) {
    /* CUSTOMER SIDE */
    .job-top-section-left {
        width: 70%;
    }
    .job-top-section-right {
        width: 30%;
    }
    .hired-job-btn-container {
        width: 100%;
        display: flex;
    }

    /* CAREER */
    .vacancy-title {
        font-size: 14px !important;
    }
    .vacancy-expired-text {
        margin-top: 32px;
    }
}

@media screen and (max-width: 769px) {
    /* HOW IT WORKS */
    .howItWorks-img {
        display: none;
    }
    .howItWorks-mobile {
        display: block;
    }
    .howItWorks-img-mobile {
        width: auto;
        height: 110px;
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 991) {
    .howItWorks-btn {
        display: none;
    }
    .howItWorks-btn-mobile {
        display: grid;
        margin: 20px auto;
    }
    .howItWorks-mobile-txt {
        font-size: 13px;
        font-weight: bold;
        text-align: center;
    }
    .howItWorks-button {
        margin: auto;
    }
    .howItWorks-hr-mobile {
        display: block;
    }
    .howItWorks-hr {
        border: 1px #f1f1f1 solid;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 767px) {
    /* CUSTOMER SIDE */
    .customer-menu-title {
        font-weight: bold;
        font-size: 18px;
        width: 100%;
        text-align: center;
        margin-top: 10px;
    }
    .customer-msg-badge {
        top: -10px;
        right: -5px;
    }

    /* CONTACT US */
    .contact-img {
        height: auto;
        width: 100%;
    }
    .contact-info {
        font-size: 17px;
        font-weight: bold;
        margin-bottom: 6px;
        margin-top: 20px;
        text-align: center;
    }
    .contact-detail {
        font-size: 13px;
        color: #aaa;
        text-align: center;
    }
    .contact-details-hr {
        border-top: 2px #f8f8f8 solid;
    }
    .contact-form-container {
        background: #f8f8f8;
        padding: 20px 15px 100px 15px;
        max-width: 100%;
        margin-bottom: 0;
    }
    .contact-mobile-inner-container {
        background: #fff;
        padding: 15px;
        border-radius: 4px;
    }
    .contact-form-title {
        margin-top: 10px;
    }
    .upload-cv-container {
        margin-top: 15px;
    }
    #send-success {
        margin-bottom: 60px;
    }

    /* WHO WE ARE */
    .whoWeAre-carousel {
        width: 100%;
        margin: 0 auto 20px auto;
    }


    /* CUSTOMER JOB */
    .sp-listing-bg {
        display: none;
    }
    .customer-jobs-posted {
        padding-left: 0;
    }
    .customer-job-details {
        display: flex;
    }
    #submit-edited-job {
        display: none;
    }
    .sp-customerView-msg {
        padding: 10px;
    }
    .sp-response-title {
        background: #fff;
        font-size: 13px;
        color: #408df4;
        padding: 0 0 8px 15px;
    }
    .sp-customerView-hr {
        border-top: 2px #f8f8f8 solid;
        margin: 7px auto 10px auto;
    }
    .sp-profileView-title {
        margin-bottom: 0;
    }
    .sp-profileView-slider {
        margin-top: 0;
    }
    .sp-profileView-location {
        width: 50%;
        text-align: right;
    }
    .sp-profileView-feedback {
        border-top: 2px #f8f8f8 solid;
        padding-top: 12px;
    }
    .sp-portfolio {
        padding: 0;
    }
    .portfolio-title {
        margin:0;
    }
    .sp-certificates-container {
        padding: 0;
    }
    .sp-certificates-inner {
        padding: 25px 15px;
    }
    .open-pdf-icon {
        float: right;
        width: 14px;
        height: 14px;
        margin-top: 4px;
    }
    /* FORGOT PASSWORD SUCCESS TOAST */
    .success-toast {
        position: unset;
    }
}

#chatModal, #formModal, #AllFeedbacksModal, #delCustomerJobImgModal {
    display: none;

    &.fade:not(.show) {
        opacity: 0 !important;
    }
}

.cat-modal-dialog, 
.feedback-modal-desktop {
    margin-top: 20% !important;

    @include mobile-breakpoint {
        margin-top: 10% !important;
    }
}

//Sass styles for customer jobs
.customer-jobs-container {
    .pagination {
        justify-content: center;
        margin: 50px 0 0;
        .page-item {
            width: 25px;
            text-align: center;
            margin: 5px;
            
            &.active{
                .page-link {
                    background: #3a89ff;
                    border-radius: 50%;
                    text-decoration: none;
                    color: #fff;
                }   
            }

        .page-link {
            position: relative;
            display: block;
            padding: 6px;
            color: #202020;
            font-weight: bold;
            border: 0;
            font-size: 12px;
        }
        .pagination-numbers {
            font-size: 13px;
            position: relative;
            display: block;
            text-align: center;
            color: #202020;
            font-weight: bold;
            border: 0;
            padding: 2px 0 2px0;
            margin-top: 4px;
            margin-left: 4px;
        }
        }
    }
}

.cert-fallback {
    padding: 20px !important;
    text-align: center;
    display: flex;
    justify-content: center;
    color: red;
    margin: auto;
}

.description-callback{
    padding: 20px; 
    font-size: 14px;
    font-weight: bold;
    color: #408df4;
}