.rst_cfrm_pass{
    margin-top: 50px;
}

.rst_cfrm_pass h2{
    color: rgb(58, 137, 255);
    text-transform: uppercase;
    text-align: center;
    font-size: 2rem;
    margin: 50px, 0;
    letter-spacing: 1.3px;      
}

.rst_cfrm_pass .rst_pass_cfrm_components{
    max-width: 500px;
    margin: auto;
    padding: 0, 10px;    
}

.rst_cfrm_pass .rst_pass_cfrm_components input{
    width: 100%;
    height: 45px;
    border: 1px solid #ccc;
    outline: none;
    padding: 0 15px;
    border-radius: 3px;
    border-color: rgb(58, 137, 255);
    margin: 5px 0;
}

.rst_cfrm_pass .rst_pass_cfrm_components label{
    color: rgb(58, 137, 255);    
}

.rst_cfrm_pass .rst_pass_cfrm_components button{
    background-color: rgb(58, 137, 255);
    color: white;
    padding: 10px 30px;
    letter-spacing: 1.3px;
    border: none;
    border-radius: 5px;
    // border-radius: 3px;
    margin-top: 15px;
    margin-bottom: 50%;
    
}

.rst_cfrm_pass .rst_pass_cfrm_components button:hover{
    background-color: rgb(12, 90, 207);
    
}