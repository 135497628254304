/* ACCOUNT */
.invoice-datepicker {
    width: 168px;
    font-weight: bold;
    font-size: 13px;
}
.invoice-datepicker-container {
    display: flex;
    float: right;
}
.invoice-datepicker-container > input[type="date"]::-webkit-inner-spin-button,
.invoice-datepicker-container >input[type="date"]::-webkit-calendar-picker-indicator {
    opacity: 0;
    -webkit-appearance: none;
    z-index: 2;
}
.invoice-datepicker-icon {
    position: absolute;
    right: 12px;
    top: 14px ;
    font-size: 13px;
    z-index: 1;
}
.unpaid-invoices-title {
    width: 100%;
}
.all-unpaid-label {
    font-size: 11px;
    float: right;
}
.payAll-invoice-checkbox {
    margin-top: 13px;
}
.unpaid-invoice-item {
    display: flex;
}
.unpaid-invoice-container {
    width: 100%;
    padding: 10px;
    margin-right: 12px;
}
.invoice-spinner {
    width: 100%;
    text-align: center;
}
.title-container {
    padding: 10px 10px 0 0;
}
.unpaid-invoice-number, .automatic-payment {
    font-size: 13px;
    font-weight: bold;
}
.invoice-pay-day, .all-unpaid-label {
    font-size: 12px;
    margin-bottom: 0;
}
.invoice-checkbox {
    margin-top: 30px;
}
.invoice-paid {
    font-size: 13px;
    margin: 12px 0 0 0;
    border-top: 2px #f8f8f8 solid;
    padding-top: 10px;
}
.paid-price {
    color: #474747;
}
.invoice-paid-badge {
    font-size: 11px;
    font-weight: bold;
    color:#0cb458;
    float: right;
}
.invoice-paid-icon {
    vertical-align: middle;
    margin-right: 8px;
}

.select-card {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
    height: auto;
}
.payment-dropdown-menu {
    margin-top: 0;
    width: 300px;
}
.payment-dropdown-icon {
    float: right;
}
.card-type-img {
    width: auto;
    height: 15px;
    margin-right: 10px;
}
.pay-btn-container {
    text-align: center;
    margin: 15px 0 25px 0;
}
.pay-now-btn {
    font-size: 13px;
    width: 100%;
    padding: 15px 0;
}

.addCard-container {
    width: 260px;
    margin:auto;
    text-align: center;
    margin-top: 100px;
}
.addCard-title {
    font-weight: bold;
}
.addCard-button {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    border: 2px #007bff dashed;
    margin: 12px auto;
}
.addCard-plus {
    color: #007bff;
    font-size: 50px;
    margin-top: 24px;
}
.card-month {
    padding-right: 6px;
}
.card-year {
    padding-left: 6px;
}
.automatic-payment-container {
    display: flex;
}
.automatic-payment {
    margin-top: 15px;
}

/*---Automatic payment toggle*/
.auto-pay-switch {
    position: relative;
    display: inline-block;
    width: 52px;
    height: 26px;
    margin: 12px 0 0 10px;
}

.auto-pay-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.auto-pay-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.auto-pay-slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .auto-pay-slider {
background-color: #12b448;
}

input:focus + .auto-pay-slider {
box-shadow: 0 0 1px #2196F3;
}

input:checked + .auto-pay-slider:before {
-webkit-transform: translateX(26px);
-ms-transform: translateX(26px);
transform: translateX(26px);
}

/* Rounded sliders */
.auto-pay-slider.round {
border-radius: 34px;
}

.auto-pay-slider.round:before {
border-radius: 50%;
}

#added-card {
    // display: none;
}

.search-invoice {
    margin-left: 20px;
    margin-right: 15px;
}

.datepicker-invoice {
    flex-grow: 0;
    margin-right: 10px;
    .invoice-date-filter {
        width: 15px;
    }
    .invoice-datepicker-icon {
        right: 23px;
    }
}

.jobs-price,
.invoice-price {
    font-size: 15px;
    color: #408df4;
    font-weight: bold;
    padding: 0;
    margin: 3px 0 0 0;
}

.add-card-form {
    width: 100% !important;
}


.card-background {
    width: 320px;
    height: 170px;
    border-radius: 12px;
    background: rgb(163, 163, 163);
    // background: -moz-linear-gradient(left, rgb(28, 182, 97, 0) 0%, rgba(0, 119, 255, 0) 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgb(28, 182, 97)), color-stop(100%, rgb(0, 119, 255)));
    // background: -webkit-linear-gradient(left, rgb(28, 182, 97, 0) 0%, rgba(0, 119, 255, 0) 100%);
    background: -o-linear-gradient(left, rgba(28, 182, 97, 255) 0%, rgba(0, 119, 255, 255) 100%);
    background: -ms-linear-gradient(left, rgba(28, 182, 97, 255) 0%, rgba(0, 119, 255, 255) 100%);
    background: linear-gradient(to right top, rgba(28, 182, 97, 255) 0%, rgba(0, 119, 255, 255) 100%);
    // filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#ff0000', endColorstr='#00ff00', GradientType=1);
}
.card-details-top, .card-details-bottom {
    padding: 20px 20px;
    height: 50%;
    display: flex;
}
.card-details-bottom {
    margin-top: 20px;
}
.card-owner-name {
    color: #fff;
    font-weight: bold;
    width: 100%;
}
.delete-card-btn {
    float: right;
    height: 24px;
    width: auto;
    cursor: pointer;
}
.card-number {
    color: #fff;
    width: 100%;
}

.cardNumber, .cvcNumber input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.cardNumber input[type=number] {
  -moz-appearance: textfield;
}

.invoice-preview {
    font-size: 13px;
    margin-top: 10px;
    padding-bottom: 14px;
}
.invoice-logo {
    margin-top: -10px;
}
.invoice-preview-details {
    text-align: right;
}
.invoice-addressee {
    display: grid;
    line-height: 22px;
    margin-top: 12px !important;
    font-size: 12px;
}
.invoice-borders {
    border-bottom: 2px #f8f8f8 solid;
}
.invoice-borders-top {
    border-top: 2px #f8f8f8 solid;
}
/* ACCOUNT */

.invoice-description-container {
    border: 2px #f8f8f8 solid;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 12px;
}

.invoice-description {
    font-size: 12px;
    color: #aaa;
}

.invoice-sum, .invoice-sum-right {
    width: 50%;
    display: grid;
    line-height: 30px;
    margin-top: 12px;
}
.invoice-sum-right {
    text-align: right;
}
.invoice-sum-details {
    padding-top: 8px;
}
.invoice-total-sum {
    font-size: 13px;
    color: #408df4;
    font-weight: bold;
}
.invoice-modal-footer {
    border: none;
    display: grid;
}
.print-invoice, .pay-now-btn {
    font-size: 13px;
    font-weight: 600;
    padding: 10px 100px;
    color: #007bff;
    margin: 10px auto;
}
.pay-now-btn {
    color: #fff;
    width: auto;
    margin-top: 5px;
    margin-bottom: 20px;
}

/* NOTIFICATIONS PAGE */
.notifications-container {
    margin-bottom: 10px;
}
.notification-date {
    color: #0095f8;
    font-size: 13px;
    font-weight: bold;
}
.notification-grey, .notification-white {
    background: #f8f8f8;
    font-size: 12px;
    padding: 10px;
    margin: 0;
}
.notification-white {
    background: none;
}

@media screen and (max-width: 521px) {
    /* SP LISTING */
    .listing-filter {
        width: 50px !important;
        margin-left: 6px;
    }

    .listing-filter-icon {
        margin: 0;
        color: #007bff;
    }
    .sp-listing-inviteBtn {
        display: none;
    }
    .sp-listing-inviteBtn-mobile {
        display: block;
        position: relative;
        font-size: 13px;
        background: #007bff;
        padding: 12px 35px;
        border-radius: 4px;
        color: #fff;
        text-decoration: none;
        cursor: pointer;
        margin-top: 15px;
    }
    .sp-listing-inviteBtn-mobile p {
        text-align: center;
        margin: 0;
        font-size: 14px;
    }

    /* CARD DETAIlS */
    .addCard-container {
        margin-top: 50px;
    }
}

@media only screen and (max-width: 321px) {
    /* SP LISTING */
    .listing-filter {
        width: 45px !important;
    }
    .auto-pay-switch {
        width: 100px !important;
    }
    .card-background {
        width: 300px;
    }
    /* INVOICE */
    .invoice-logo {
        height: 65px;
    }
}
@media screen and (max-width: 769px) {
    /* SP LISTING */
    .listing-filter {
        width: 70px;
        margin-left: 6px;
    }
    .listing-filter-txt {
        display: none;
    }
    .listing-filter-icon {
        color: #007bff;
    }
    .navi-user-container {
        display: none;
    }
    .header-profile-mobile {
        display: flex;
        margin-top: 15px;
    }
    .header-mobile-jobsBtn {
        font-weight: bold;
        margin-top: 12px;
    }
    .bell-icon {
        text-align: right !important;
        margin-top: 9px;
    }
    .system-notifications {
        display: none;
    }
    .system-notifications-dropdown {
        right: -70px !important;
        left: auto;
        margin-right: auto;
        margin-left: auto;
    }
}

@media screen and (max-width: 991px) {
    /* SP LISTING */
    .system-notifications-dropdown {
        right: -90px;
    }

    /*NOTIFICATIONS*/
    .notifications-page {
        background: #f8f8f8;
        padding-bottom: 60px;
    }
    .notifications-page-header {
        background: #fff;
    }
    .notifications-page-title {
        margin-bottom: 0;
    }
    .notifications-container {
        margin-bottom: 10px;
        background: #fff;
    }
    .notification-white {
        border-top: 2px #f8f8f8 solid;
        padding: 14px;
    }
    .notification-date-container {
        display: flex;
        padding-top: 10px;
    }
    .notification-date {
        margin-left: 14px;
        width: 50%;
        font-size: 14px;
    }
    .date-right {
        font-size: 13px;
        float: right;
        width: 50%;
        text-align: right;
        margin-right: 12px;
    }
    .profile-desktop {
        display: none;
    }

    /* CARD DETAILS */
    .auto-pay-switch {
        width: 72px;
    }
    .added-card-container {
        padding: 10px;
    }
    .card-mobile {
        margin: auto;
    }

    // .invoice-datepicker-icon {
    //     position: absolute;
    //     top: 0;
    //     right: 0;
    //     font-size: 13px;
    //     z-index: 1;
    //     // border: 2px #ced4da solid;
    //     border-radius: 4px;
    //     padding: 11px 13px;
    //     color: #0076d6;
    // }
    .search-invoice {
        width: 100%;
    }
    .datepicker-invoice {
        max-width: 50px;
    }
    .listing-search {
        margin: 15px 15px;
    }

    .listing-search input[type="date"] {
        border: 0;
        height: 45px;
        opacity: 0;
        z-index: 99999;
        width: 45px;
    }
    .payment-dropdown-menu {
        margin: auto !important;
    }
    .invoice-logo {
        margin-top: 12px;
    }
    .invoice-preview-details {
        line-height: 10px;
    }
    .print-invoice, .pay-now-btn {
        padding: 10px 0;
        width: 100%;
    }
    .pay-btn-container {
        margin-left: auto;
        margin-right: auto;
    }
}
@media only screen and (max-width: 400px) {
    .auto-pay-switch {
        width: 82px !important;
    }
}


/* =============================================OLD======================================== */

*,*:focus,*:hover{
    outline:none !important;
}
.no-margin {
    margin: 0 !important;
}
.bell-icon {
    float: right;
    width: 40px;
    margin-top: 5px; /*CHANGED*/
}
.bell-icon-img {
    width: 16px;
    height: auto;
}
.login-button-mobile {
    display: none;
}
.profile-button {
    float: right;
    font-size: 14px;
    font-weight: bold;
    width: 90%;
}
.profile-button-img {
    width: 20px;
    height: 20px;
    object-fit: cover;
    border-radius: 50%;
}
// .form-control {
//     border: 2px solid #ced4da !important; /*CHANGED*/
//     height: 45px;
//     font-size: 14px;
// }
.ic-search {
    position: absolute;
    display: block;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
    margin-top: 14px;
    margin-left: 14px;
    width: 18px; /*CHANGED*/
    height: 18px; /*CHANGED*/
}

/*PROFILE TABS*/
.edit-btn-modal {
    background: none;
    border: none;
    color: #3989f4;
    font-weight: 600;
}
.upload-portfolio-img {
    font-size: 14px;
    width: 30%;
}
.pagination-controls { /*CHANGED*/
    position: relative;
    display: block;
    padding: 6px;
    color: #202020;
    font-weight: bold;
    border: 0;
    font-size: 12px;
}

/*MOBILE MENU*/
.quit-profile {
    position: absolute;
    top: 35px;
    right: 20px;
}

/* JOBS TABS */
.jobs-tabbed-menu {
    margin-top: 0 !important;
}

.jobs-tab-header>.nav-tabs {
    border: none;
    margin: 0px;
    height: 30px;
}
.jobs-tab-header>.nav-tabs>li {
    margin-right: 5px;
    min-width: 10%;
}
.jobs-tab-header>.nav-tabs>li>a {
    border: 0;
    margin-right: 0;
    text-align: center;
    color: #737373;
    padding: 0 3px 0 3px;
    height: 32px;
}
.jobs-tab-header>.nav-tabs>.alljobsTab>a.active {

    border-bottom: 2px solid #ff7b00;
    color: #ff7b00;
    height: 32px;
}
.jobs-tab-header>.nav-tabs>.alljobsTab>a:hover {
    height: 32px;
    color: #ff7b00;
}
.jobs-tab-header>.nav-tabs>.interestedjobsTab>a.active {

    border-bottom: 2px solid #007bff;
    color: #007bff;
    height: 32px;
}
.jobs-tab-header>.nav-tabs>.interestedjobsTab>a:hover {
    height: 32px;
    color: #007bff;
}
.jobs-tab-header>.nav-tabs>.shortlistedTab>a.active {

    border-bottom: 2px solid #12b448;
    color: #12b448;
    height: 32px;
}
.jobs-tab-header>.nav-tabs>.shortlistedTab>a:hover {
    height: 32px;
    color: #12b448;
}
.jobs-tab-header>.nav-tabs>.hiredTab>a.active {

    border-bottom: 2px solid #ffe600;
    color: #ffe600;
    height: 32px;
}
.jobs-tab-header>.nav-tabs>.hiredTab>a:hover {
    height: 32px;
    color: #ffe600;
}
.jobs-tab-header>.nav-tabs>.unavailable>a.active {

    border-bottom: 2px solid #999999;
    color: #999999;
    height: 32px;
}
.jobs-tab-header>.nav-tabs>.unavailable>a:hover {
    height: 32px;
    color: #999999;
}
.jobs-filter, .jobsItem-inner {
    margin-top: 20px;
}
.jobs-cat-inner {
    background: #f8f8f8;
    border-radius: 5px;
    margin-top: 20px;
    padding: 12px 12px 12px 12px;
}
.jobs-content {
    background: #ffffff;
    border-radius: 5px;
    padding: 10px 10px 8px 8px;
    margin: 0 0 20px 0;
    border-bottom: 1px #f8f8f8 solid;
}
.jobs-title {
    font-size: 13px;
    font-weight: bold;
    margin: 0;
    padding: 0;
}
.jobs-img-container {
    max-width: 100px;
}
.jobs-img {
    width: 70px;
    height: 90px;
    object-fit: cover;
    margin: 4px 8px 8px 5px;
    border-radius: 3px;
}
.jobs-inner {
    margin: 3px 0 0 3px;
}
.jobs-price {
    font-size: 15px;
    color: #408df4;
    font-weight: bold;
    padding: 0;
    margin: 3px 0 0 0;
}
.jobs-invited {
    font-size: 12px;
    color: #0cb458;
    margin: 3px 0 0 0;
    text-align: right;
    font-weight: 600;
}
.invited-check {
    font-size: 14px;
    margin-right: 5px;
    vertical-align: middle;
}
.jobs-content-footer {
    font-size: 13px;
    border-top: 2px #f8f8f8 solid;
    margin: 4px 0 4px 6px;
}
.jobs-location {
    width: 50%;
    margin-top: 8px;
    display: flex;
    color: #202020;
}
.jobs-location-name {
    margin-left: 3px;
}
.jobs-post-date, .shortlisted, .interested {
    font-size: 12px;
    width: 50%;
    text-align: right;
    margin-top: 8px;
    color: #aaa;
}
#tab-2 {
    display: none;
}/*JOBS VERTICAL TAB*/
.jobs-gallery-container {
    position: absolute;
    display: flex;
    bottom: -20px;
    margin-left: 5%;
    margin-right: 5%;
    width: 100%;
    z-index: 2;
}
.jobs-tabbed-gallery, .apply-container {
    position: relative;
    margin: 20px 0 0 10px;
}
.jobs-gallery {
    display: inline-flex;
    width: 18%;
    padding: 5px;
}

/* Style the images inside the grid */
.jobs-gallery img {
    cursor: pointer;
    border-radius: 3px;
}

/* The expanding image container */
.jobs-img-expand {
    display: flex;
    width: 100%;
    height: 300px;
    object-fit: contain;
    margin-top: 12px;
}
.no-photo {
    background: #f8f8f8;
    border-radius: 3px;
}
.no-photo-expanded {
    display: block;
    width: 48px;
    height: 48px;
    margin: 120px auto;
}

.jobs-gallery-tag {
    position: absolute;
    right: 20px;
    margin-top: 15px;
    background: #3989f4;
    font-size: 11px;
    color: #fff;
    padding: 3px 10px;
    border-radius: 20px;
    font-style: normal;
    font-weight: 600;
    z-index: 9;
}

.tag-shortlisted {
    background: #0cb458;
    z-index: 9;
}
.tag-hired {
    background: #ffe600;
    z-index: 9;
}
.tag-unavailable {
    background: #6b6b6b;
    z-index: 9;
}

.jobs-galleryImg-lg {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
    border-radius: 3px;
}
.jobs-details-container {
    display: flex;
    height: 25px;
}
.job-poster {
    font-size: 13px;
    font-weight: 600;
    color: #408df4;
    width: 50%;
    text-align: left;
}
.jobs-price-label {
    font-size: 12px;
    font-weight: 600;
    width: 50%;
    text-align: right;
}
.jobs-category {
    font-size: 15px;
    width: 50%;
    font-weight: 600;
}
.jobs-details-location {
    color: #aaa;
}
.jobs-apply-container {
    margin: 30px 0 0 10px;
}
.jobs-apply-info {
    font-size: 12px;
    color: #aaa;
    border-bottom: 2px #f8f8f8 solid;
    padding-bottom: 10px;
    margin: 5px 0 10px 0;
}
.fee {
    margin-left: 8px;
}
.interested {
    text-align: left !important;
}
.apply-container {
    display: flex;
    margin-top: 0;
}
.apply-btn {
    margin-left: 10px;
    font-size: 13px;
}
.accept-btn, .interest-btn {
    margin: 10px 0 0 10px;
    font-size: 13px;
    width: 100%;
}
.jobs-tablinks:hover, .jobs-tablinks.active {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.jobs-salary {
    display: flex;
}
.jobs-salary-checkbox {
    margin: 2px 5px 0 3px;
    vertical-align: middle;
}
.jobs-salary-label {
    font-size: 12px;
    font-weight: 600;
}
.jobs-apply-modal {
    font-size: 12px;
    color: #aaa;
}
.jobs-apply-success-icon {
    font-size: 36px;
    color: #0cb458;
    display: block;
    margin-left: auto;
    width: 55%;
}
.apply-success {
    text-align: center !important;
    font-weight: normal !important;
}
.bi-telephone, .bi-chat-left-dots {
    margin-right: 5px;
}
.hire-request-container {
    display: flex;
    margin-bottom: 15px;
}
.hire-request-txt {
    max-width: 60% !important;
    width: auto;
    margin: 0;
}
.hire-request-btn {
    font-size: 13px;
    max-width: 50%;
    width: 40%;
}
.hired-feedback-img {
    margin: 8px 0 5px 0;
}
.hired-feedback {
    margin: 10px 0 0 7px;
}
.feedback-reminder {
    width: 50%;
    font-size: 12px;
    text-align: right;
    margin-top: 8px;
    font-weight: bold;
    text-decoration: underline;
}
.reminder-container {
    border: 2px #ced4da solid;
    border-radius: 3px;
    padding: 0 8px;
    height: 38px;
}
.unavailable-job {
    text-align: center;
}
.job-listing-container {
    background: #f8f8f8;
}
.job-listing-item {
    background: #ffffff;
    border-radius: 5px;
}
.chat-modal-footer {
    flex-wrap: nowrap !important;
}
.chat-input {
    width: 100%;
    border: none;
    font-size: 12px;
}
.send-msg-btn {
    width: 120px;
}
.send-msg-icon {
    margin-right: 7px;
    vertical-align: text-bottom;
}
.chat-msg-line {
    display: flex;
    float: left;
    margin-bottom: 10px;
}
.chat-msg-line-right {
    display: flex;
    float: right;
    margin-top: 12px;
}
.chat-profile-pic {
    width: 35px;
    height: 35px;
}
.chat-msg-me, .apply-form {
    background: #f8f8f8;
    font-size: 12px;
    padding: 8px;
    border-radius: 3px;
    margin-left: 5px;
    max-width: 150px;
    color: #545454;
}
.chat-msg-other {
    background: #0cb458;
    font-size: 12px;
    padding: 8px;
    border-radius: 3px;
    color: #fff;
    margin: 0 5px 12px 5px;
}
.chat-msg-time {
    height: 30px;
    font-size: 8px;
    color: #aaa;
    margin-left: 5px;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
}
.form-dot {
    display: inline-block;
    color: #fff;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    border: 2px #59a1ff solid;
    margin-right: 8px;
}
.job-start-date, .work-time, .salary {
    font-size: 12px;
    display: inline-block;
    margin-bottom: 10px;
}
.salary {
    margin-left: 14px;
}
.apply-form {
    width: 185px;
    max-width: 200px;
}
.form-modal-footer {
    border-top: 0 !important;
}
.send-new-form {
    width: fit-content;
    background: #f8f8f8;
    border-radius: 20px;
    margin-left: 15px;
    padding: 8px 12px;
    cursor: pointer;
    color: #59a1ff;
    font-size: 13px;
}
.use-template {
    margin-top: 8px;
    margin-bottom: 8px;
}
.send-new-form a, .send-new-form a:hover {
    font-size: 13px;
    padding: 12px;
    text-decoration: none;
}
#chat-template {
    position: absolute;
    font-size: 13px;
    bottom: 125px;
    margin-left: 20px;
    max-width: 260px;
    box-shadow: 2px 2px 8px 4px rgba(0, 0, 0, 0.2);
    display: none;
  }

  .chat-template-header {
    font-weight: bold;
    color: rgb(26, 26, 26);
    margin-bottom: 3px;
    margin-top: 10px;
  }
  .chat-template-txt {
      padding-bottom: 10px;
      border-bottom: 2px #f8f8f8 solid;
      margin: 0;
  }
  #chat-template-msg {
      display: none;
  }
  .chat-template-container {
    padding: 15px 15px 0 15px;
    background-color: rgb(255, 255, 255);
    color: rgb(43, 43, 43);
    cursor: pointer;
  }

  .chat-template-closebtn {
    position: absolute;
    right: 10px;
    color: rgb(75, 75, 75);
    font-size: 30px;
    cursor: pointer;
  }

  .chat-template-closebtn:hover {
    color: rgb(51, 51, 51);
  }

  @media screen and (max-width: 521px) {

    .navi-user-container {
        padding-top: 15px;
    }

    .login-button-mobile {
        display: block;
        float: right;
        padding: 12px;
        background: #f8f8f8;
        border-radius: 50%;
    }
    .profile-button {
        display: none;
    }
    .bell-icon {
        float: right;
        width: 40px;
        margin-top: 10px; /*CHANGED*/
        padding-left: 10px;
    }
    .insta-icon {
        margin-left: 5px;
    }
    .job-carousel {
        max-height: 200px;
        object-fit: cover;
    }
}

@media screen and (min-width: 521px) {
    .job-carousel {
        max-height: 350px;
        object-fit: cover;
    }

    .chat-modal {
        max-width: 360px !important;
    }
}

@media screen and (max-width: 991px) {
    .bell-icon-img {
        width: 22px;
        height: 20px;
    }
    .sidebar-header-mobile { /*CHANGED*/
        display: flex;
        width: 100%;
    }
    /*JOBS*/
    .all-jobs-title {
        background: #f76437;
        color: #fff;
        margin: 0;
    }
    .interested-jobs-title {
        background: #3989f4;
        color: #fff;
        margin: 0;
    }
    .shortlisted-title {
        background: #0cb458;
        color: #fff;
        margin: 0;
    }
    .hired-title {
        background: #fccc30fa;
        color: #fff;
        margin: 0;
    }
    .unavailable-title {
        background: #8a8a8a;
        color: #fff;
        margin: 0;
    }
    .jobs-filter {
        margin: 0;
        padding: 12px 12px 8px 12px !important;
        background: #fff;
    }
    .jobs-cat-inner {
        margin-top: 0;
    }
    .jobs-title {
        font-size: 15px;
    }
    .jobs-price {
        width: 50%;
        float: left;
    }
    .jobsItem-price {
        width: 50%;
        text-align: right;
    }
    .jobs-invited {
        width: 50%;
        float: right;
    }
    .feedback-pagination {
        margin: 0;
    }
    .sidebar-jobs {
        background: #f8f8f8;
    }
    .sidebar-header-jobs {
        background: #fff;
    }
    .shortlisted-btns {
        border-bottom: 2px #f8f8f8 solid;
        padding: 5px 0 8px 0;
    }
    .shortlisted-mobile-btns {
        margin-top: 10px;
    }
    .accept-btn {
        margin: 0 0 0 5px;
    }
    .jobsItem-accept-btn {
        margin: 15px 0;
    }
    .interest-btn {
        margin: 0;
    }
    .badge {
        position: absolute;
        top: -10px;
        right: 8px;
        padding: 3px 7px;
        border-radius: 50%;
        background-color: red;
        color: white;
        border: 2px #fff solid;
        font-size: 11px;
    }
    .jobs-location-name {
        font-size: 13px;
    }
    .reminder-txt {
        width: 100%;
        text-align: center;
    }
    .reminder-container {
        width: 100%;
        margin-top: 8px;
        display: flex;
    }
    .feedback-reminder {
        width: 100%;
        text-align: center;
        text-decoration: none;
    }
    .jobs-apply-container {
        margin: 15px 0 0 0;
        border-top: 2px #f8f8f8 solid;
    }
    .apply-container {
        margin-left: 0;
    }
    .close-btn {
        position: absolute;
        top: 25px;
        right: 15px;
    }
    .jobsItem-close {
        font-size: 30px;
    }
    .apply-btn {
        margin: 0;
    }
    .jobs-img-expand {
        height: 200px;
        margin-top: 0;
    }
    .no-photo-expanded {
        margin: 80px auto;
    }
    .hired-job-feedback {
        border-top: 2px #f8f8f8 solid;
        margin-top: 12px;
        padding-top: 10px;
    }
    .hire-request-container {
        display: block;
        margin: 10px 0;
        padding-bottom: 15px;
        border-bottom: 2px #f8f8f8 solid;
    }
    .hired-txt {
        text-align: center;
        margin-bottom: 10px;
    }
    .send-hire-request {
        color: #408df4;
        font-size: 13px;
        font-weight: 600;
    }
}

.remember-card {
    position: relative;
    margin-left: 20px;
}

.invoice-wrapper,
.payment-details {
    display: block !important;
}