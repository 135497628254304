@mixin mobile-breakpoint {
  @media screen and (min-width: 769px) {
    @content;
  }
}

@mixin sm-breakpoint {
@media screen and (max-width: 769px) {
  @content;
}
}