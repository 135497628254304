.footer-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #383838;
    width: 100%;
    color: #d4d4d4;    
}

.footer__menu__sections {
    width: 85%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.services, .company, .contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 350px !important;
    // background-color: red;
}

.footer__menu {
    display: flex;
    flex-direction: column;
    font-size: 14px;
}

.footer__bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    width: 100%;
    background-color: #2b2b2b;
    color: #616060;
    height: 50px;
}

.footer__bottom__sections {
    display: flex;
    width: 75%;
}

.social__media {
    display: flex;
    gap: 1rem;
}

.footer__copyright {
    margin-left: 50%;
}

@media only screen and (max-width: 450px) {
    .footer__copyright {
        margin-left: 10%;
        font-size: 12px;
    }

    .footer__menu a, .footer__menu p {
        font-size: 12px;
    }

    .footer__menu__sections {
        width: 100%;
    }
  }
