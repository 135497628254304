.howitworks__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.howitworks__title h3 {
    font-size: 20px;
    font-weight: bold;    
}

.howitworks__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.howitworks__button {
    font-size: 12px;
}

.howitworks__diagram {
    display: grid;
    grid-template-columns: 40% 20% 40%;
    width: 90%;
    margin-top: 2rem;
}

.grid__block {
    display: grid;
    justify-content: center;
    align-items: center;
    -webkit-text-stroke: 1.5px;
}



