@import "../../styles/utils/mixins.scss";
/* PROFILE - TOP & SIDEBAR */

.profile-title-bg {
    background: #ebebeb;
    height: 50px;
    margin-bottom: 40px;
}

.profile-title {
    padding-top: 12px;
    font-weight: bold;
    font-size: 15px;
}

.mobile-sidebar {
    display: none;
}

.sidebar-menu-containers {
    min-height: 77px;
    padding: 15px 15px 8px 15px;
}

.sidebar-menu-items {
    height: 30px;
    border-bottom: solid 1px #f7f7f7;
    margin-bottom: 10px;
    display: flex !important;
}

.profile-picture {
    width: auto;
    margin: 0 10px 0 15px;
    display: inline-block;
    position: relative;
}

.profile-picture-img {
    width: 45px;
    height: 45px;
    object-fit: cover;
    border-radius: 50%;
    border: 2px #e2e2e2 solid;
    padding: 2px;
    pointer-events: none;
    opacity: 1;
}

.profile-picture-input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.profile-name {
    font-weight: 600;
    font-size: 13px;
    margin-bottom: 3px;
}

.profile-feedback {
    font-size: 11px;
    color: rgb(40, 206, 115);
    font-weight: 600;
}

.sidebar-hr {
    border-top: 1px #fff solid;
    margin: 0;
}

.account-complete-container {
    height: 35px;
}

.account-completion {
    font-size: 13px;
    font-weight: 600;
}

.completed {
    float: right;
}

#account-progress {
    width: 90%;
}

.myJobs-menu {
    background: url('../../resources/icons/my-jobs.png');
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    margin: 0 10px 0 15px;
    border: none;
}

.myJobs-menu:hover {
    background: url('../../resources/icons/my-jobs-dark.png');
    background-repeat: no-repeat;
    // background-size: cover;
    text-decoration: none;
}

.profile-menu {
    background: url('../../resources/icons/profile.png');
    background-repeat: no-repeat;
    width: 100%;
    height: 24px;
    margin: 0 10px 0 15px;
    padding-right: 15px;
    border: none;

    @include mobile-breakpoint {
        width: 24px;
    }
}

.profile-menu:hover {
    background: url('../../resources/icons/profile-dark.png');
    background-repeat: no-repeat;
    // background-size: cover;
    text-decoration: none;
}

.account-menu {
    background: url('../../resources/icons/my-account.png');
    background-repeat: no-repeat;
    width: 100%;
    height: 24px;
    margin: 0 10px 0 15px;
    border: none;

    @include mobile-breakpoint {
        width: 24px;
    }
}

.account-menu:hover {
    background: url('../../resources/icons/my-account-dark.png');
    background-repeat: no-repeat;
    // background-size: cover;
    text-decoration: none;
}

.post-job-menu {
    background: url('../../resources/icons/post-job.png');
    background-repeat: no-repeat;
    width: 100%;
    height: 24px;
    margin: 0 10px 0 15px;
    border: none;

    @include mobile-breakpoint {
        width: 24px;
    }
}

.post-job-menu:hover {
    background: url('../../resources/icons/post-job-dark.png');
    background-repeat: no-repeat;
    // background-size: cover;
    text-decoration: none;
}

.notifications-menu {
    background: url('../../resources/icons/notifications.png');
    background-repeat: no-repeat;
    width: 100%;
    height: 24px;
    margin: 0 10px 0 15px;
    border: none;

    @include mobile-breakpoint {
        width: 24px;
    }
}

.notifications-menu:hover {
    background: url('../../resources/icons/notifications-dark.png');
    background-repeat: no-repeat;
    // background-size: cover;
    text-decoration: none;
    border: none;
}

.deactivate-menu {
    background: url('../../resources/icons/deactivate.png');
    background-repeat: no-repeat;
    width: 100%;
    height: 24px;
    margin: 0 10px 0 15px;
    border: none;

    @include mobile-breakpoint {
        width: 24px;
    }
}

.deactivate-menu:hover {
    background: url('../../resources/icons/deactivate-dark.png');
    background-repeat: no-repeat;
    // background-size: cover;
    text-decoration: none;
}

.logout-menu {
    background: url('../../resources/icons/logout.png');
    background-repeat: no-repeat;
    width: 100%;
    height: 24px;
    margin: 0 10px 0 15px;

    @include mobile-breakpoint {
        width: 24px;
    }
}

.logout-menu:hover {
    background: url('../../resources/icons/logout-dark.png');
    background-repeat: no-repeat;
    // background-size: cover;
    text-decoration: none;
}

.menu-item {
    font-size: 15px !important;
    font-weight: 600;
    padding-top: 2px;
    color: #707070 !important;
    min-width: 200px;
    padding-left: 30px;
    height: 23px;
}

.menu-item:hover {
    color: #353535;
    font-weight: 900;
}

a.customer-view-btn {
    color: #353535;
}

.customer-view-btn:hover {
    text-decoration: none;
    cursor: pointer;
    color: #353535;
}

.customer-view {
    width: 150px;
    text-align: center;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 5px;
    left: 0;
    right: 0;
    font-size: 13px;
    font-weight: 600;
    color: #353535;
}


/*PROFILE TABBED MENU*/

.tabbed-card {
    background-color: #ffffff;
    border: 1px solid rgba(0, 34, 51, 0.1);
    box-shadow: 2px 4px 10px 0 rgba(0, 34, 51, 0.05), 2px 4px 10px 0 rgba(0, 34, 51, 0.05);
    border-radius: 0.15rem;
    margin-right: 0;
    border: none;
    box-shadow: none;
}

.tabbed-card:hover {
    margin-right: 0;
    border: none;
    box-shadow: none;
}


/* Tabs Card */

.mt-3,
.my-3 {
    margin-top: 0 !important;
    margin-left: 16px;
}

.details-text {
    font-size: 14px;
    border-bottom: 2px solid rgba(134, 134, 134, 0.075);
    margin: 0;
    padding: 10px 0 5px 0;
    display: inline-block;
    width: 100%;
}

.edit-myDetails {
    float: right;
    color: rgb(238, 238, 238);
    width: 30px;
    height: 30px;
    background: rgb(37, 134, 245);
    border-radius: 20px;
    padding: 5px 0 0 8px;
    margin-top: -4px;
    cursor: pointer;
}

.edit-myDetailsm {
    float: right;
    color: rgb(238, 238, 238);
    width: 30px;
    height: 30px;
    background: rgb(37, 134, 245);
    border-radius: 20px;
    padding: 5px 0 0 8px;
    margin-top: -35px;
    cursor: pointer;
}

.card-header {
    background: none;
    padding: 0;
    border-bottom: 2px solid rgba(134, 134, 134, 0.075);
}

.tab-title,
.nav-link {
    font-size: 13px;
    font-weight: 700;
}


/* Default mode */

.tab-card-header>.nav-tabs {
    border: none;
    margin: 0px;
    height: 30px;
}

.tab-card-header>.nav-tabs>li {
    margin-right: 5px;
    min-width: 15%;
}

.tab-card-header>.nav-tabs>li>a {
    border: 0;
    margin-right: 0;
    text-align: center;
    color: #737373;
    padding: 0 3px 0 3px;
    height: 32px;
}

.tab-card-header>.nav-tabs>li>a.active {
    border-bottom: 2px solid #007bff;
    color: #007bff;
    height: 32px;
}

.tab-card-header>.nav-tabs>li>a:hover {
    color: #007bff;
    height: 32px;
}

.p-3 {
    padding: 0 !important;
}

.tab-card-header>.tab-content {
    padding-bottom: 0;
}

.nav-tabs .nav-link {
    border: 0;
}

.user-attribute,
.feedback-cat {
    font-size: 12px;
    color: #aaa;
    margin: 7px 0 0 0;
}

.user-parameter {
    font-size: 13px;
    font-weight: bold;
    padding-bottom: 5px;
    margin: 0;
}

.edit-details {
    text-align: right;
    margin-top: 15px;
    font-size: 13px;
}

.details-hr {
    height: 2px;
    background: rgba(134, 134, 134, 0.075);
    border-width: 0px;
    margin: 0;
}

.id-card {
    padding: 10px 0 10px 0;
}

.job-cat-container {
    background: #f8f8f8;
    border-radius: 5px;
    margin-top: 20px;
}

.job-cat-inner {
    padding: 20px;
}

.job-category {
    background: #ffffff;
    border-radius: 5px;
    padding: 20px 0 20px 0;
    margin: 0 0 20px 0;
}

.job-category-txt {
    font-size: 14px;
    font-weight: bolder;
    margin: 0 8px 0 0;
}

.delete-btn {
    float: right;
    margin-right: 8px;
    cursor: pointer;
}

.delete-btn-modal {
    background: none;
    border: none;
    color: #eb0404;
    font-weight: 600;
}

.dismiss-btn-modal {
    background: none;
    border: none;
    color: #353535;
    font-weight: 600;
}

.add-category-btn {
    font-size: 13px;
    font-weight: 600;
    margin-top: -3px;
}

.edit-template,
.feedback-date {
    text-align: right;
    font-size: 13px;
}

.template-title,
.row-margin {
    margin: 0;
}

.template-txt {
    word-wrap: break-word;
    margin: 0 50px 0 20px;
    font-size: 13px;
}

.template-btn {
    padding: 0 !important;
    margin-top: 20px;
}

.add-template {
    padding: 5px 0 5px 0;
}

.upload-certificate {
    margin-top: 30px;
}

.upload-certificate-icon {
    text-align: left;
    float: left;
    font-size: 18px;
    color: #007bff;
    margin-top: -1px;
}

.upload-certificate-btn {
    border: 2px solid #dfdfdf;
    border-radius: 3px;
    background: none;
}

.upload-certificate-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    font-size: 13px;
    color: #007bff;
    font-weight: bold;
    margin: 10px 0 0 20px;
}

.cat-modal-dialog {
    max-width: 350px;
    margin: 1.75rem auto;
    max-height: calc(100vh - 10rem);
}

.cat-container {
    border-bottom: 1px solid #e6e6e66b;
}

.cat-modal-header {
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #e6e6e66b;
}

.cat-checkbox label:after {
    content: '';
    display: table;
    clear: both;
}

.cat-checkbox {
    position: relative;
    display: inline-block;
    width: 100%;
    min-height: 40px;
    border: none;
    padding: 10px 0 5px 15px;
}

#catRow {
    display: none;
}

.cr {
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;
    border: none;
}

.cat-checkbox .bi-check {
    position: absolute;
    font-size: 24px;
    color: #0cb458;
    line-height: 0;
    right: 10px;
}

.cat-checkbox label input[type="checkbox"] {
    display: none;
}

.cat-checkbox input[type="checkbox"]+.cr>.bi-check {
    opacity: 0;
}

.cat-checkbox input[type="checkbox"]:checked+.cr>.bi-check {
    opacity: 1;
}

.cat-checkbox label input[type="checkbox"]:checked+.cr {
    color: #0cb458;
}

.cat-checkbox label input[type="checkbox"]:disabled+.cr {
    opacity: .5;
}

.cat-modal-footer {
    padding: 0 12px 12px 12px;
    margin: 0;
}

.cat-submit {
    font-size: 13px;
    font-weight: 600;
}

#portfolio-img {
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
}

#portfolio-img:hover {
    opacity: 0.7;
}


/* The Modal (background) */

.portfolioModal {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 2;
    /* Sit on top */
    padding-top: 100px;
    /* Location of the box */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.9);
    /* Black w/ opacity */
}


/* Modal Content (image) */

.img-lg {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
}


/* Add Animation */


/* Category list of SP */

.category__list__mobile {
    display: flex;
    align-items: center;
    font-weight: 600;
    justify-content: space-between;    
    
}

.delete__category__trash {
    display: flex; 
    cursor: pointer;   
} 

.add__remove__cat {
    position: absolute;
    height: 100%;
    width: 88%;
    background: transparent;
}

.img-lg {
    -webkit-animation-name: zoom;
    -webkit-animation-duration: 0.6s;
    animation-name: zoom;
    animation-duration: 0.6s;
}

.portfolio-imgrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 5px;
    margin-top: 15px;
}

.portfolio-grid-img {
    width: 100%;
    max-height: 110px;
    object-fit: cover;
}

.portfolio-img-container {
    position: relative;
}

.portfolio-img-remove {
    position: absolute;
    width: 22px;
    height: 22px;
    background: rgb(82, 82, 82);
    border-radius: 50%;
    color: white;
    right: 0;
    margin: 3px 3px 0 0;
    cursor: pointer;
    z-index: 1;
}

.img-remove-x {
    position: absolute;
    font-size: 20px;
    margin-left: 5px;
    margin-top: -4px;
}

@-webkit-keyframes zoom {
    from {
        -webkit-transform: scale(0)
    }

    to {
        -webkit-transform: scale(1)
    }
}

@keyframes zoom {
    from {
        transform: scale(0)
    }

    to {
        transform: scale(1)
    }
}


/* The Close Button */

.closemodal {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
}

.closemodal:hover,
.closemodal:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}

.addcat-btn-container {
    position: relative;
    max-width: 300px;
    padding-bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
}

.uploadcert-btn-container {
    position: relative;
    max-width: 300px;
    padding-bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
}

.uploadimg-btn-container {
    position: relative;
    max-width: 260px;
    padding-bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
}

.feedback-filter {
    margin-top: 20px;
}

.feedback-userid {
    font-size: 13px;
    font-weight: bold;
    margin: 0;
    color: #007bff;
}

.feedback-container {
    background: #ffffff;
    border-radius: 5px;
    padding: 20px 12px 20px 12px;
    margin: 0 0 20px 0;
}

.feedback-emoji {
    margin-top: 12px;
    border-top: 2px solid #f1f1f1a8;
}

.feedback-txt {
    word-wrap: break-word;
    margin: 0 50px 0 0;
    font-size: 13px;
    vertical-align: bottom;
    color: #aaa;
}

.emoji-img {
    margin-top: 12px;
    width: 24px;
    height: 24px;
}

.feedback-positive {
    font-size: 13px;
    font-weight: bold;
    color: #0cb458;
    vertical-align: bottom;
    margin-left: 3px;
}

.feedback-neutral {
    font-size: 13px;
    font-weight: bold;
    color: #f3ac04;
    vertical-align: bottom;
    margin-left: 3px;
}

.feedback-negative {
    font-size: 13px;
    font-weight: bold;
    color: #eb2d2d;
    vertical-align: bottom;
    margin-left: 3px;
}

.feedback-pagination {
    margin-top: 35px;
}

.pagination-controls {
    position: relative;
    display: block;
    padding: 6px;
    color: #202020;
    font-weight: bold;
    background-color: #fff;
    border: 0;
    font-size: 12px;
}

.pagination-controls:hover {
    text-decoration: none;
}

.page-number-container {
    width: 28px;
    height: 24px;
}

.page-number-container.active .pagination-numbers {
    background: rgb(58, 137, 255);
    border-radius: 50%;
    text-decoration: none;
    color: #fff;
}

.pagination-numbers {
    font-size: 13px;
    position: relative;
    display: block;
    text-align: center;
    color: #202020;
    font-weight: bold;
    border: 0;
    padding: 2px 0 2px 0;
    margin-top: 4px;
    margin-left: 4px;
}

.pagination-numbers:hover {
    background: rgb(58, 137, 255);
    border-radius: 50%;
    text-decoration: none;
    color: #fff;
}


.quotation-mark {
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: bold;
    font-size: 35px;
    line-height: 20px;
    color: #007bff;
}

.not-reviewed {
    font-size: 13px;
    text-align: center;
    color: #aaa;
    margin-top: 12px;
}

.feedback-remineder-btn {
    padding: 0 !important;
}

.edit-details-icon {
    position: absolute;
    z-index: 9;
    display: block;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
    margin-top: 10px;
    right: 25px;
    color: #000;
}

.edit-details-label {
    position: absolute;
    z-index: 9;
    display: block;
    font-size: 12px;
    pointer-events: none;
    color: #aaa;
    margin: 8px 0 0 15px;
}

.details-form {
    padding-top: 25px;
    height: 55px;
    font-weight: 600;
}

.details-symbol-limit {
    margin-right: 10px;
}

.details-textarea {
    padding-top: 25px;
    font-weight: 600;
    margin-bottom: 10px;
}

.upload-form {
    opacity: 0;
    height: 100px;
    width: 100%;
}

.upload-details-form {
    padding-top: 25px;
    height: 135px;
    font-weight: 600;
}

.id-card-edit {
    background-repeat: no-repeat;
    background-size: contain;
    width: auto;
    height: 80px;
    position: absolute;
    top: 40px;
}

.upload-id-icon {
    margin: 0 !important;
}

/*Device compatibility*/

@media screen and (max-width: 600px) {
    h1 {
        font-size: 28px;
        font-weight: bold;
    }

    .add__remove__cat {        
        width: 80%;
    }
}

@media screen and (max-height: 450px) {
    .overlay a {
        font-size: 20px
    }

    .overlay .closebtn {
        font-size: 40px;
        top: 15px;
        right: 35px;
    }

    
}

@media screen and (max-width: 1200px) {
    .nav-button {
        display: block;
    }

    .navi-menu {
        display: none;
    }
}

@media screen and (max-width: 521px) {
    .logo {
        // height: 35px;
        // margin-top: 22px;
        margin-left: 10px;
    }

    .cards-title {
        padding-left: 40px;
        font-size: 20px;
    }

    .login-button {
        display: none;
    }

    .bell-icon {
        float: right;
        width: 40px;
        margin-top: 5px;
        padding-left: 0;
    }

    .user-login {
        float: right;
        display: block;
        width: 38px;
        height: 38px;
        padding: 10px;
        background: #f5f5f5;
        border-radius: 20px;
    }

    .dropdown-menu {
        margin-left: -85px;
    }
}

@media screen and (max-width: 769px) {
    .categories-large {
        display: none;
        padding: 0 15px 0 0;
        margin-top: 25px
    }

    .categories-title {
        float: left;
        width: 70%;
    }

    .seeAll-categories {
        display: block;
        float: left;
        width: 30%;
        text-align: right;
        margin-top: 10px;
        font-weight: bold;
        color: #408df4;
    }

    .rate-container {
        width: 200px;
        height: 38px;
    }

    .rate-percent {
        background: none;
    }

    .card-name {
        font-size: 15px;
    }

    .card-job-title {
        font-size: 12px;
    }

    .cata-sub-nav {
        -webkit-mask-image: unset;
    }

    .nav-prev {
        visibility: hidden;
    }

    .nav-next {
        visibility: hidden;
    }

    .footer-menu {
        line-height: 10px;
        color: #d4d4d4;
    }

    .language {
        display: none;
    }

    .chat-icon {
        right: 15px;
    }

    .post-job-logo {
        height: 75px;
    }

    .post-job-bck-btn-lg {
        display: none;
    }

    .post-job-bck-btn-sm {
        display: inline-block;
        text-align: right;
        font-size: 14px;
    }
}

@media screen and (max-width: 991px) {
    .category-list {
        line-height: 15px;
        -moz-column-count: 1;
        -webkit-column-count: 1;
        column-count: 1;
    }

    .services {
        width: 100%;
        float: left;
    }

    .company {
        width: 100%;
        float: left;
    }

    #more {
        display: none;
    }

    #moree {
        display: none;
    }

    .see-more {
        display: block;
        cursor: pointer;
        font-size: 14px;
    }

    .post-job-title {
        display: none;
    }

    .post-job-text {
        display: none;
    }

    .profile-title-bg {
        display: none;
    }

    .header-mobile {
        display: none;
    }

    .sidebar-header-mobile {
        width: 100%;
        padding: 0 12px 0 12px;
    }

    .mobile-sidebar {
        display: inline-block;
        width: 100%;
        margin-top: 25px;
    }

    .sidebar-menu-containers {
        // display: flex;
        padding: 0;
        min-height: 67px;
    }

    .account-container {
        background: #f8f8f8;
        padding: 12px 18px 15px 18px;
    }

    a.customer-view-btn {
        color: #408df4;
    }

    .customer-view {
        width: 100%;
        background: #fff;
        width: 100vw;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        padding: 20px 0 20px 0;
    }

    .account-completion {
        font-size: 18px;
    }

    .mobile-spacing {
        display: none;
    }

    .mobile-menu {
        position: relative;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
    }

    .mobile-menu-header {
        margin-bottom: 0;
        border-bottom: 2px solid rgba(0, 0, 0, .05);
    }

    .mobile-menu-btn {
        border: none;
        width: 100%;
        padding: 20px 0 20px 0;
        background: none;
    }

    .mobile-menu-btn:not(.collapsed) .menu-arrow {
        color: #000;
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    .mobile-menu-btn.collapsed .menu-arrow {
        color: #8b8b8b;
    }

    .mobile-menu-btn:not(.collapsed) .menu-item {
        color: #000;
    }

    .mobile-menu-btn.collapsed .menu-item {
        color: #8b8b8b;
    }

    .mobile-menu-btn:not(.collapsed) .profile-menu {
        background: url(../../resources/icons/profile-dark.png);
    }

    .mobile-menu-btn.collapsed .profile-menu {
        background: url(../../resources/icons/profile.png);
    }

    .mobile-menu-btn:not(.collapsed) .myJobs-menu {
        background: url(../../resources/icons/my-jobs-dark.png);
    }

    .mobile-menu-btn.collapsed .myJobs-menu {
        background: url(../../resources/icons/my-jobs.png);
    }

    .mobile-menu-btn:not(.collapsed) .account-menu {
        background: url(../../resources/icons/my-account-dark.png);
    }

    .mobile-menu-btn.collapsed .account-menu {
        background: url(../../resources/icons/my-account.png);
    }

    .mobile-menu-btn:not(.collapsed) .notifications-menu {
        background: url(../../resources/icons/notifications-dark.png);
    }

    .mobile-menu-btn.collapsed .notifications-menu {
        background: url(../../resources/icons/notifications.png);
    }

    .mobile-menu-btn:not(.collapsed) .post-job-menu {
        background: url(../../resources/icons/post-job-dark.png);
    }

    .mobile-menu-btn.collapsed .post-job-menu {
        background: url(../../resources/icons/post-job.png);
    }


    .mobilemenu-subitem {
        width: 100%;
        height: 50px;
        display: flex;
        margin-bottom: 15px;
        border-bottom: solid 1px #f7f7f7;
        padding-bottom: 15px;
    }

    .mobilemenu-subitem-iconcont {
        width: 32px;
        height: 32px;
        background: #f7f7f7;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .mobilemenu-subitem-icon {
        width: 20px;
        height: 20px;
    }

    .mobilemenu-subitem-label {
        height: 32px;
        font-size: 14px;
        font-weight: bold;
        color: #2b2b2b;
        padding-left: 15px;
        display: flex;
        align-items: center;
        justify-content: start;
        padding-bottom: 2px;
    }

    .menu-btn-container {
        display: flex;
    }

    .menu-btn-inner {
        width: 100%;
    }

    .menu-item {
        text-align: left;
        font-size: 16px;
        width: 100%;
        padding: 0;
        margin-left: 38px;

    }

    .menu-arrow {
        float: right;
        margin-right: 12px;
        color: #8b8b8b;
    }

    .mobile-sidebar-bottom {
        background: #f8f8f8;
        height: 130px;
    }

    .submenu-container {
        padding: 12px 12px 12px 0;
        margin-left: 10px;
        border-bottom: 2px solid rgba(0, 0, 0, 0.05);
    }

    .mobile-menu-header li:last-child {
        border-bottom: none;
    }

    .mobile-menu-header ul>li {
        display: inline;
    }

    .submenu-icon {
        width: 35px;
        height: 35px;
        padding: 8px;
        background: #f8f8f8;
        border-radius: 3px;
    }

    .submenu-notifications {
        margin-left: 15px;
        font-size: 14px;
        font-weight: bold;
        margin-top: 6px;
        white-space: nowrap;
        width: 100%;
    }

    .submenu-txt {
        display: flex;
        width: 100%;
        color: #474747;
    }

    .submenu-txt:hover {
        text-decoration: none;
        color: #474747;
    }

    .submenu-message-sum {
        margin-left: 8px;
        font-size: 12px;
        font-weight: bold;
        margin-top: 8px;
        color: #408df4;
        width: 100%;
    }

    .submenu-arrow {
        font-size: 13px;
        margin-top: 8px;
    }

    /* HIDDEN TABBED MENU */
    .profile-tabbed-menu {
        // display: none;
    }

    .details-text-mobile {
        font-weight: 600;
        padding: 8px 12px 12px 13px;
        margin-bottom: 18px;
    }

    .edit-details {
        background: #f8f8f8;
        height: 60px;
        margin-bottom: 10px;
        margin-top: 0;
    }

    .editDetails-title-container {
        padding: 12px 0 0 10px;
        display: flex;
    }

    .editDetails-back-btn {
        font-size: 26px;
    }

    .edit-details-title {
        font-weight: bold;
        font-size: 18px;
        width: 80%;
        text-align: center;
        margin-top: 5px;
    }

    .edit-details-btn {
        font-size: 14px;
        font-weight: 600;
        color: #9e9e9e94;
        margin: 0;
        padding: 0 0 5px 0;
        display: inline-block;
        width: 100%;
    }

    .details-container {
        padding: 0 12px 0 12px;
        margin: 0;
    }

    .jobcat-mobile-container {
        background: #f8f8f8;
    }

    .jobcat-mobile-inner {
        padding: 0 0 20px 0;
    }

    .templates-mobile {
        background: #ffffff;
        border-radius: 5px;
        padding: 8px 0 10px 0;
        margin: 0 0 15px 0;
    }

    .edit-template-mobile {
        margin-top: 5px !important;
        left: -50px !important;
    }

    .edit-template-btn {
        text-align: right;
    }

    .edit-template-container {
        display: flex;
        border: none;
    }

    .edit-template-item {
        font-size: 13px;
    }

    .edit-template-icon {
        margin-left: 12px;
        font-size: 15px;
        margin-top: 3px;
    }

    .feedback-filter {
        margin-top: 10px;
        height: 60px !important;
    }

    .feedback-date {
        font-size: 12px;
        padding-top: 25px;
        margin: 0;
    }

    .feedback-cat-title {
        font-size: 14px;
        font-weight: bolder;
        margin: 0 8px 10px 0;
    }

    .portfolio-imgrid {
        grid-template-columns: 1fr 1fr 1fr;
        padding: 0 22px 0 22px;
    }

    .portfolio-img-remove {
        margin: 0;
        right: 3px;
        top: 3px;
    }
}

@media only screen and (max-width: 500px) {
    .portfolio-grid-img {
        max-height: 70px;
    }
}

@media only screen and (max-width: 400px) {
    .mobile-menu-header {
        padding-left: 0;
        padding-right: 6px;
    }

    .menu-btn-container {
        width: 100%;
    }

    .submenu-container {
        padding: 12px 6px 12px 0;
        margin-left: 6px;
    }

    .submenu-notifications {
        min-width: inherit !important;
        width: 100% !important;
    }
}

/* 100% Image Width on Smaller Screens */

@media only screen and (max-width: 700px) {
    .modal-content {
        width: 100%;
    }
}

.modal {
    display: block;
}

.fade:not(.show) {
    opacity: 1;
}

#editdetails {
    .modal-dialog {
        transform: none !important;
        max-width: 600px !important;
    }
}

#upload {
    width: 100%;
}

// Mobile Menu 

.wrapper {
    // width: 600px;
    margin: 0 auto;
}

.accordion-wrapper {
    &+* {
        margin-top: 0.5em;
    }
}

.accordion-item {
    overflow: hidden;
    transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
    height: auto;
    max-height: 9999px;
}

.accordion-item.collapsed {
    max-height: 0;
    transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}

.accordion-title {
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    color: #666;
    padding: 0.5em 2.5em;
    // border: solid 1px #ccc;
    // border-radius: 1.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &::after {
        content: "";
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid currentColor;
    }

    &:hover,
    &.open {
        color: black;
    }

    &.open {
        &::after {
            content: "";
            border-top: 0;
            border-bottom: 5px solid;
        }
    }
}


.logoutdv::after, .pageitems::after {
    content: none;
}

.accordion-content {
    padding: 1em 1.5em;
}