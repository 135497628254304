@font-face {
  font-family: "helvetica-b";
  src: url("resources/fonts/helveticageobold.woff2")
    format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: "helvetica-m";
  src: url("resources/fonts/helveticageomed.woff2")
    format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: "helvetica-l";
  src: url("resources/fonts/helveticageolight.woff2")
    format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: auto;
}

body {
  margin: 0;
   font-family: helvetica-m !important; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
