.jobs-cat-inner {
  .paginationButtons {
      justify-content: center;
      margin: 50px 0 0;
      list-style: none;
      display: flex;
      
      

      .page-item {
          width: 25px;
          text-align: center;
          margin: 5px;
          
          &.paginationActive{
              .page-link {
                  background: #3a89ff;
                  border-radius: 50%;
                  text-decoration: none;
                  color: #fff;
              }   
          }

      .page-link {
          position: relative;
          display: block;
          padding: 6px;
          color: #202020;
          font-weight: bold;
          border: 0;
          font-size: 12px;
      }
      }
  }
}
