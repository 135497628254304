.blog__comments {
    width: 720px;
    margin-left: 2rem;
}

.comment__author {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.07px;
    color: #383838;
    margin-top: 1rem;
}

.comment__author span {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0.06px;
    color: #909090;
}

.author__status {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.67;
    letter-spacing: 0.06px;
    color: #3989f4;
}

.blog__comment {
    width: 708px;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #707070;
    margin: 1rem 0;
}

.reply__button {
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.07px;
    color: #2b2b2b;
    cursor: pointer;
}

.comment-reply {
    font-size: 12px;
    cursor: pointer;
    font-weight: 600;
    // background-color: red;
}

.expand__replies__arrow__open {
    width: 10px;
    margin-right: 2px;
    transform: rotate(0deg);
}
.expand__replies__arrow__close {
    width: 10px;
    margin-right: 2px;
    transform: rotate(-90deg);
}

@media only screen and (max-width: 600px){
    .blog__comments {
        width: auto;
        margin-left: 1rem;
    }

    .blog__comment {
        width: auto;
    }
    

}
