.errMsg{
    background: white;
    color: red;
    text-align: left;
    padding: 10px 0;
    letter-spacing: 1.3px;
}

.successMsg{
    background: white;
    color: green;
    text-align: left;
    padding: 10px 0;
    letter-spacing: 1.3px;
}