.process {
    align-items: center;
    min-height: 500px;
    padding: 2rem;
    width: 450px;
    flex-direction: column;
    display: flex;
    margin-right: 12rem;
    justify-content: space-evenly;
  
    h5 {
      span {
        color: yellow;
      }
      font-weight: 400;
      margin-bottom: 5rem;
      color: rgb(4, 238, 4);
    }
    h4 {
      font-weight: 400;
      color: rgb(4, 238, 4);
    }
    p {  
      font-size: 1rem;
      padding: 1.2rem;
      margin-top: 0.5rem;
      border-radius: 5px;
      background-color: #fff;
      text-overflow: auto;
    }
    .incoming {
      width: 100%;
      margin-bottom: 15rem;
      overflow: auto;
      text-overflow: auto;
    }
    .crypt {
      width: 100%;
      overflow: auto;
      height: 100%;
    }
  }