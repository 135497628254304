/*overlay menu*/
@import "../../../styles/utils/mixins.scss";

.nav-button {
  display: none;
  padding-top: 17px;
  font-size: 30px;
  cursor: pointer;
}

.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgb(31, 31, 31);
  overflow-x: hidden;
  transition: 0.5s;
}

.overlay-content {
  position: relative;
  top: 25%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 28px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.overlay a:hover,
.overlay a:focus {
  color: #f1f1f1;
}

.overlay .closebtn {
  position: absolute;
  top: 10px;
  right: 45px;
  font-size: 35px;
  color: #ffffff;
}

.overlay .title {
  position: absolute;
  top: 20px;
  left: 45px;
  font-size: 30px;
  color: #ffffff;
}

hr.overlay-menu {
  border: 1px solid rgb(43, 43, 43);
}

/*horizontal srolling cards*/

.cards-title {
  word-wrap: break-word;
  white-space: normal;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  display: inline-block;
  margin-right: 20px;
  margin-top: 15px;
  border-radius: 5px;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.card-container {
  width: 260px;
  padding: 12px 10px 0 10px;
  word-wrap: break-word;
  white-space: normal;
}

.card-img {
  border-radius: 2px 2px 0 0;
  width: 260px;
}

.card-name {
  margin: 0;
}

.card-job-title {
  color: #3989f4;
  font-weight: bold;
  font-size: 15px;
  margin: 0;
}

.rate-container {
  width: 240px;
  height: 24px;
}

.rate {
  float: left;
  height: 26px;
  padding: 0;
}

.rate:not(:checked) > input {
  position: absolute;
  top: -9999px;
}

.rate:not(:checked) > label {
  float: right;
  width: 1em;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 20px;
  color: #ccc;
}

.rate:not(:checked) > label:before {
  content: "★ ";
}

.rate > input:checked ~ label {
  color: #ffc700;
}

.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
  color: #deb217;
}

.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
  color: #c59b08;
}

.rate-percent {
  float: right;
  font-size: 12px;
  margin-top: 5px;
  padding: 3px 10px 3px 10px;
  background: #f5f5f5;
  color: #818181;
  border-radius: 5px;
}

.card-description {
  font-size: 13px;
  padding-top: 10px;
  word-wrap: break-word;
  white-space: normal;
  color: #818181;
}

.hr-container {
  width: 100%;
  background: #f5f5f5;
  height: 500px;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.hr-container::-webkit-scrollbar {
  display: none;
}

.inner-container {
  padding-top: 50px;
}

.cata-sub-nav {
  padding: 0 0 12px 12px;
  margin: 0;
  /* Make this scrollable when needed */
  overflow-x: auto;
  /* We don't want vertical scrolling */
  overflow-y: hidden;
  /* Make an auto-hiding scroller for the 3 people using a IE */
  -ms-overflow-style: -ms-autohiding-scrollbar;
  /* For WebKit implementations, provide inertia scrolling */
  -webkit-overflow-scrolling: touch;
  /* We don't want internal inline elements to wrap */
  white-space: nowrap;
  /* Remove the default scrollbar for WebKit implementations */
  -webkit-mask-image: linear-gradient(90deg, black, rgba(59, 59, 59, 0.6));
}

.cata-sub-nav::-webkit-scrollbar {
  display: none;
}

.nav-prev {
  width: 50px;
  height: 50px;
  position: absolute;
  left: 40px;
  margin-top: 200px;
  z-index: 999999;
  display: none;
  cursor: pointer;
}

.nav-next {
  width: 50px;
  height: 50px;
  position: absolute;
  right: 7px;
  margin-top: 200px;
  z-index: 999999;
  cursor: pointer;
}

.first-flow-image {
  float: left;
}

.flow-image {
  float: right;
  max-width: 120px;
  height: auto;
}

.flow-hr-line {
  position: absolute;
  width: 100%;
  background: #408df4;
  height: 2px;
  margin-top: 100px;
  z-index: -1;
}

.post-job-btn {
  float: right;
}

.banner-bottom {
  object-fit: cover;
}

.banner-bottom-title {
  position: absolute;
  text-align: center;
  margin: 0;
  padding-top: 45px;
  left: 0;
  right: 0;
  color: #ffffff;
}

.banner-bottom-text {
  margin-top: 45px;
}

.banner-bottom-button {
  position: absolute;
  text-align: center;
  margin: 0;
  padding-top: 135px;
  left: 0;
  right: 0;
  color: #ffffff;
}

.chat-icon {
  position: fixed;
  top: 92%;
  right: 40px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 99999999;
}

.chat-icon a {
  display: block;
  text-align: center;
  padding: 16px;
  transition: all 0.3s ease;
  color: white;
  font-size: 20px;
}

.footer {
  background: #383838;
  width: 100%;
  height: auto;
}

.footer-titles {
  font-size: 14px;
  padding-top: 30px;
  color: #fff;
  font-weight: bold;
}

a.footer-menu-urls {
  color: #dfdfdf;
  text-decoration: none;
  line-height: 28px;
}

a:hover.footer-menu-urls {
  text-decoration: underline;
}

.category-list {
  -moz-column-count: 4;
  -moz-column-gap: 30px;
  -webkit-column-count: 4;
  -webkit-column-gap: 30px;
  column-count: 4;
  column-gap: 30px;
  font-size: 14px;
  line-height: 15px;
  color: #d4d4d4;
}

.services {
  width: 70%;
  float: left;
}

.company {
  width: 30%;
  float: right;
}

.insta-icon {
  margin-left: 20px;
}

.copyright-text {
  font-size: 14px;
  text-align: center;
  vertical-align: middle;
  color: #6d6d6d;
}

.copyright-symbol {
  font-size: 30px;
}

.language-icon {
  float: right;
}

a.language {
  font-size: 14px;
  float: right;
  margin-left: 10px;
  color: #f5f5f5;
  cursor: pointer;
}

/*REGISTRATION & POST A JOB*/

// .register {
//     background: url("../../../images/register_bg.png");
//     background-position: center center;
//     background-repeat: no-repeat;
//     background-size: cover;
//     min-height: 100%;
//     margin: 0;
//     padding: 0;
// }

.register-header {
  text-align: right;
  margin-top: 10px;
}

.post-job-title {
  color: #fff;
}

.post-job-text {
  color: #ccc;
  font-size: 18px;
}

a.post-job,
a.post-job:hover {
  color: #fff;
  text-decoration: underline;
  font-weight: 500;
}

.post-job-logo {
  height: 65px;
}

.post-job-bck-btn-lg,
.unpaid-invoices,
.paid-invoices {
  font-size: 13px;
  font-weight: 600;
}

.post-job-bck-btn-sm {
  display: none;
}

.post-job-btn-lg {
  font-size: 14px;
  font-weight: 600;
  text-decoration: none !important;
}

// s
.post-job-sbmt-btn {
  font-size: 14px;
  font-weight: 600;
}

.post-job-carousel {
  background: #fff;
  padding: 20px;
  border-radius: 4px;
  margin: 20px 0;

  @include mobile-breakpoint {
    margin: auto;
  }
}

.post-job-carousel-inner {
  margin-top: 25px;
}

.progress-bar {
  background: rgb(163, 163, 163);
  // background: -moz-linear-gradient(left, rgb(28, 182, 97) 0%, rgba(0, 119, 255) 100%);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0%, rgb(28, 182, 97)),
    color-stop(100%, rgb(0, 119, 255))
  );
  // background: -webkit-linear-gradient(left, rgb(28, 182, 97) 0%, rgba(0, 119, 255) 100%);
  // background: -o-linear-gradient(left, rgba(28, 182, 97) 0%, rgba(0, 119, 255) 100%);
  // background: -ms-linear-gradient(left, rgba(28, 182, 97) 0%, rgba(0, 119, 255) 100%);
  // background: linear-gradient(to right, rgba(28, 182, 97) 0%, rgba(0, 119, 255) 100%);
  // filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#ff0000', endColorstr='#00ff00', GradientType=1);
}

.carousel-indicators .active {
  background: #ffffff;
  width: 4px;
  height: 4px;
  border: rgb(49, 49, 49) 2px solid;
  border-radius: 20px;
  margin-top: -2px;
}

.carousel-indicators li {
  background: #707070;
  width: 3px;
  height: 3px;
  border: 0;
  border-radius: 10px;
  vertical-align: bottom;
}

.post-job-form {
  margin-top: 15px;
}

.post-job-file-label {
  font-size: 14px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.post-job-file-label-icon {
  text-align: right;
  float: right;
  font-size: 22px;
  font-weight: bold;
}

.post-job-input-label-icon {
  float: right;
  font-size: 16px;
  font-weight: bolder;
  margin-top: -32px;
  margin-right: 8px;
}

.custom-control-label {
  font-size: 12px;
  padding-top: 3px;
}

.post-job-chckbox {
  padding-top: 12px;
}

.progress {
  height: 4px;
}

#progressbar {
  width: 25%;
}

.read-terms {
  font-size: 13px;
  padding-top: 13px;
  text-align: right;
  color: #3989f4;
  cursor: pointer;
  text-decoration: underline;
}

.read-terms-text {
  font-size: 13px;
  color: #929292;
}

.sign-in-user-icon {
  position: absolute;
  z-index: 9;
  display: block;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
  margin-top: 20px;
  right: 10px;
  color: #000;
}
.sign-in-forgot-pass {
  font-size: 11px;
  color: #aaa;
}
.sign-in-hrLine {
  border-bottom: 2px #f8f8f8 solid;
  margin-bottom: 15px;
  padding: 0;
}
.forgot-pass-link {
  color: #2b2b2b;
  font-weight: bold;
  text-decoration: underline;
  margin-left: 5px;
}
.sign-in-links-container {
  margin-bottom: 15px;
}
.sign-in-links {
  font-size: 12px;
  color: #5fa3ff;
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
  margin-left: 3px;
}
#new-password,
#submit-new-pass {
  display: none;
}

.modal-title {
  font-size: 14px;
  font-weight: bold;
}

.pencil-icon {
  position: absolute;
  right: 10px;
  top: 8px;
}

.max-symbols,
.max-symbols-signup {
  position: absolute;
  right: 15px;
  top: 100px;
  font-size: 10px;
  color: #929292;
}
.max-symbols-signup {
  top: 58px;
}

.ui-widget.ui-widget-content {
  margin: 10px;
}

option {
  font-size: 13px;
}

/* PROFILE - TOP & SIDEBAR */

.profile-title-bg {
  background: #ebebeb;
  height: 50px;
  margin-bottom: 40px;
}

.profile-title {
  padding-top: 12px;
  font-weight: bold;
  font-size: 15px;
}

.mobile-sidebar {
  display: none;
}

.sidebar-menu-containers {
  min-height: 77px;
  padding: 15px 15px 8px 15px;
}

.sidebar-menu-items {
  height: 40px;
}

.profile-picture {
  width: auto;
  margin: 0 10px 0 15px;
}

.profile-picture-img {
  width: 45px;
  height: 45px;
  object-fit: cover;
  border-radius: 50%;
  border: 2px #e2e2e2 solid;
  padding: 2px;
}

.profile-name {
  font-weight: 600;
  font-size: 13px;
  margin-bottom: 3px;
}

.profile-feedback {
  font-size: 11px;
  color: rgb(40, 206, 115);
  font-weight: 600;
}

.sidebar-hr {
  border-top: 1px #fff solid;
  margin: 0;
}

.account-complete-container {
  height: 35px;
}

.account-completion {
  font-size: 13px;
  font-weight: 600;
}

.completed {
  float: right;
}

#account-progress {
  width: 90%;
}
.myJobs-menu {
  background: url("../../../resources/icons/my-jobs.png");
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  margin: 0 10px 0 15px;
  border: none;
}

.myJobs-menu:hover {
  background: url("../../../resources/icons/my-jobs-dark.png");
  background-repeat: no-repeat;
  background-size: cover;
  text-decoration: none;
}
.myJobs-menu-services{
  background: url("../../../resources/icons/all-jobs.png");
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  margin: 0 10px 0 15px;
  border: none;
}
.myJobs-menu-my-account{
  background: url("../../../resources/icons/shortlist.png");
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  margin: 0 10px 0 15px;
  border: none;
}
.myJobs-menu-notifications{
  background: url("../../../resources/icons/notifications.png");
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  margin: 0 10px 0 15px;
  border: none;
}

.myJobs-menu-post-job {
  background: url("../../../resources/icons/hired.png");
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  margin: 0 10px 0 15px;
  border: none;
}

.myJobs-menu-logout-menu{
  background: url("../../../resources/icons/logout-dark.png");
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  margin: 10px 10px 0 15px;
  border: none;

}

.myJobs-menu-services:hover, 
  .myJobs-menu-my-account:hover, 
    .myJobs-menu-notifications:hover, 
      .myJobs-menu-post-job:hover, 
        .myJobs-menu-logout-menu:hover {
            background-size: cover;
            text-decoration: none;
          }

.sidebar-menu-items .menu-item:hover{
  color: #000 !important;
}

// .profile-menu {
//   background: url("../../../resources/icons/profile.png");
//   background-repeat: no-repeat;
//   width: 24px;
//   height: 24px;
//   margin: 0 10px 0 15px;
//   padding-right: 15px;
//   border: none;
// }

// .profile-menu:hover {
//   background: url("../../../resources/icons/profile-dark.png");
//   background-repeat: no-repeat;
//   background-size: cover;
//   text-decoration: none;
// }

// .account-menu {
//   background: url("../../../resources/icons/my-account.png");
//   background-repeat: no-repeat;
//   width: 24px;
//   height: 24px;
//   margin: 0 10px 0 15px;
//   border: none;
// }

// .account-menu:hover {
//   background: url("../../../resources/icons/my-account-dark.png");
//   background-repeat: no-repeat;
//   background-size: cover;
//   text-decoration: none;
// }



// // .notifications-menu {
// //   background: url("../../../resources/icons/notifications.png");
// //   background-repeat: no-repeat;
// //   width: 24px;
// //   height: 24px;
// //   margin: 0 10px 0 15px;
// //   border: none;
// // }

// // .notifications-menu:hover {
// //   background: url("../../../resources/icons/notifications-dark.png");
// //   background-repeat: no-repeat;
// //   background-size: cover;
// //   text-decoration: none;
// //   border: none;
// // }

// // .deactivate-menu {
// //   background: url("../../../resources/icons/deactivate.png");
// //   background-repeat: no-repeat;
// //   width: 24px;
// //   height: 24px;
// //   margin: 0 10px 0 15px;
// //   border: none;
// // }

// // .deactivate-menu:hover {
// //   background: url("../../../resources/icons/deactivate-dark.png");
// //   background-repeat: no-repeat;
// //   background-size: cover;
// //   text-decoration: none;
// }

// .logout-menu {
//   background: url("../../../resources/icons/logout.png");
//   background-repeat: no-repeat;
//   width: 24px;
//   height: 24px;
//   margin: 0 10px 0 15px;
// }

// .logout-menu:hover {
//   background: url("../../../resources/icons/logout-dark.png");
//   background-repeat: no-repeat;
//   background-size: cover;
//   text-decoration: none;
// }

.menu-item {
  font-size: 13px;
  font-weight: 600;
  padding-top: 2px;
  color: #8b8b8b;
  min-width: 200px;
  padding-left: 30px;
  height: 23px;
}

.menu-item:hover {
  color: #353535;
  font-weight: 900;
}

a.customer-view-btn {
  color: #353535;
}

.customer-view-btn:hover {
  text-decoration: none;
  cursor: pointer;
  color: #353535;
}

.customer-view {
  width: 150px;
  text-align: center;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 5px;
  left: 0;
  right: 0;
  font-size: 13px;
  font-weight: 600;
  color: #353535;
}

/*PROFILE TABBED MENU*/

.tabbed-card {
  background-color: #ffffff;
  border: 1px solid rgba(0, 34, 51, 0.1);
  box-shadow: 2px 4px 10px 0 rgba(0, 34, 51, 0.05),
    2px 4px 10px 0 rgba(0, 34, 51, 0.05);
  border-radius: 0.15rem;
  margin-right: 0;
  border: none;
  box-shadow: none;
}

.tabbed-card:hover {
  margin-right: 0;
  border: none;
  box-shadow: none;
}

/* Tabs Card */

.mt-3,
.my-3 {
  margin-top: 0 !important;
  margin-left: 16px;
}

.details-text {
  font-size: 14px;
  border-bottom: 2px solid rgba(134, 134, 134, 0.075);
  margin: 0;
  padding: 10px 0 5px 0;
  display: inline-block;
  width: 100%;
}

.edit-myDetails {
  float: right;
  color: rgb(238, 238, 238);
  width: 30px;
  height: 30px;
  background: rgb(37, 134, 245);
  border-radius: 20px;
  padding: 5px 0 0 8px;
  margin-top: -4px;
  cursor: pointer;
}

.card-header {
  background: none;
  padding: 0;
  border-bottom: 2px solid rgba(134, 134, 134, 0.075);
}

.tab-title {
  font-size: 13px;
  font-weight: 700;
}

/* PROFILE TABS */

.tab-card-header > .nav-tabs {
  border: none;
  margin: 0px;
  height: 30px;
}

.tab-card-header > .nav-tabs > li {
  margin-right: 5px;
  min-width: 15%;
}

.tab-card-header > .nav-tabs > li > a {
  border: 0;
  margin-right: 0;
  text-align: center;
  color: #737373;
  padding: 0 3px 0 3px;
  height: 32px;
}

.tab-card-header > .nav-tabs > li > a.active {
  border-bottom: 2px solid #007bff;
  color: #007bff;
  height: 32px;
}

.tab-card-header > .nav-tabs > li > a:hover {
  color: #007bff;
  height: 32px;
}

.p-3 {
  padding: 0 !important;
}

.tab-card-header > .tab-content {
  padding-bottom: 0;
}

.nav-tabs .nav-link {
  border: 0;
}

.user-attribute,
.feedback-cat {
  font-size: 12px;
  color: #aaa;
  margin: 7px 0 0 0;
}

.user-parameter {
  font-size: 13px;
  font-weight: bold;
  padding-bottom: 5px;
  margin: 0;
}

.edit-details {
  text-align: right;
  margin-top: 15px;
  font-size: 13px;
}

.details-hr {
  height: 2px;
  background: rgba(134, 134, 134, 0.075);
  border-width: 0px;
  margin: 0;
}

.id-card {
  padding: 10px 0 10px 0;
}

.job-cat-container {
  background: #f8f8f8;
  border-radius: 5px;
  margin-top: 20px;
}

.job-cat-inner {
  padding: 20px;
}

.job-category {
  background: #ffffff;
  border-radius: 5px;
  padding: 20px 0 20px 0;
  margin: 0 0 20px 0;
}

.job-category-txt {
  font-size: 14px;
  font-weight: bolder;
  margin: 0 8px 0 0;
}

.delete-btn {
  float: right;
  margin-right: 8px;
  cursor: pointer;
}

.delete-btn-modal {
  background: none;
  border: none;
  color: #eb0404;
  font-weight: 600;
}
.edit-btn-modal {
  background: none;
  border: none;
  color: #3989f4;
  font-weight: 600;
}

.dismiss-btn-modal {
  background: none;
  border: none;
  color: #353535;
  font-weight: 600;
}

.add-category-btn {
  font-size: 13px;
  font-weight: 600;
  margin-top: -3px;
}

.edit-template,
.feedback-date {
  text-align: right;
  font-size: 13px;
}

.template-title,
.row-margin {
  margin: 0;
}

.template-txt {
  word-wrap: break-word;
  margin: 0 50px 0 20px;
  font-size: 13px;
}

.template-btn {
  padding: 0 !important;
  margin-top: 20px;
}

.add-template {
  padding: 5px 0 5px 0;
}

.upload-certificate {
  margin-top: 30px;
}

.upload-certificate-icon {
  text-align: left;
  float: left;
  font-size: 18px;
  color: #007bff;
  margin-top: -1px;
}

.upload-certificate-btn {
  border: 2px solid #dfdfdf;
  border-radius: 3px;
  background: none;
}

.upload-certificate-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  font-size: 13px;
  color: #007bff;
  font-weight: bold;
  margin: 10px 0 0 20px;
}
.upload-portfolio-img {
  font-size: 14px;
  width: 30%;
}

.cat-modal-dialog {
  max-width: 350px;
  margin: 1.75rem auto;
  // max-height: calc(100vh-5rem) !important;
}

.cat-container {
  border-bottom: 1px solid #e6e6e66b;
}

.cat-modal-header {
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #e6e6e66b;
}

.cat-checkbox label:after {
  content: "";
  display: table;
  clear: both;
}

.cat-checkbox {
  position: relative;
  display: inline-block;
  width: 100%;
  min-height: 40px;
  border: none;
  padding: 10px 0 5px 15px;
}

#catRow {
  display: none;
}

.cr {
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
  border: none;
}

.cat-checkbox .bi-check {
  position: absolute;
  font-size: 24px;
  color: #0cb458;
  line-height: 0;
  right: 10px;
}

.cat-checkbox label input[type="checkbox"] {
  display: none;
}

.cat-checkbox input[type="checkbox"] + .cr > .bi-check {
  opacity: 0;
}

.cat-checkbox input[type="checkbox"]:checked + .cr > .bi-check {
  opacity: 1;
}

.cat-checkbox label input[type="checkbox"]:checked + .cr {
  color: #0cb458;
}

.cat-checkbox label input[type="checkbox"]:disabled + .cr {
  opacity: 0.5;
}

.cat-modal-footer {
  padding: 0 12px 12px 12px;
  margin: 0;
}

.cat-submit {
  font-size: 13px;
  font-weight: 600;
}

#portfolio-img {
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

#portfolio-img:hover {
  opacity: 0.7;
}

/* The Modal (background) */

.portfolioModal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 2;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.9);
  /* Black w/ opacity */
}

/* Modal Content (image) */

.img-lg {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}

/* Add Animation */

.img-lg {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

.portfolio-imgrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 5px;
  margin-top: 15px;
}

.portfolio-grid-img {
  width: 100%;
  max-height: 110px;
  object-fit: cover;
}

.portfolio-img-container {
  position: relative;
}

.portfolio-img-remove {
  position: absolute;
  width: 22px;
  height: 22px;
  background: rgb(82, 82, 82);
  border-radius: 50%;
  color: white;
  right: 0;
  margin: 3px 3px 0 0;
  cursor: pointer;
  z-index: 1;
}

.img-remove-x {
  position: absolute;
  font-size: 20px;
  margin-left: 5px;
  margin-top: -4px;
}

@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
  }
}

@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

/* The Close Button */

.closemodal {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.closemodal:hover,
.closemodal:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

.addcat-btn-container {
  position: relative;
  max-width: 300px;
  padding-bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.uploadcert-btn-container {
  position: relative;
  max-width: 300px;
  padding-bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.uploadimg-btn-container {
  position: relative;
  max-width: 260px;
  padding-bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.feedback-filter {
  margin-top: 20px;
}

.feedback-userid {
  font-size: 13px;
  font-weight: bold;
  margin: 0;
  color: #007bff;
}

.feedback-container {
  background: #ffffff;
  border-radius: 5px;
  padding: 20px 12px 20px 12px;
  margin: 0 0 20px 0;
}

.feedback-emoji {
  margin-top: 12px;
  border-top: 2px solid #f1f1f1a8;
}

.feedback-txt {
  word-wrap: break-word;
  margin: 0 50px 0 0;
  font-size: 13px;
  vertical-align: bottom;
  color: #aaa;
}

.emoji-img {
  margin-top: 12px;
  width: 24px;
  height: 24px;
}

.feedback-positive {
  font-size: 13px;
  font-weight: bold;
  color: #0cb458;
  vertical-align: bottom;
  margin-left: 3px;
}

.feedback-neutral {
  font-size: 13px;
  font-weight: bold;
  color: #f3ac04;
  vertical-align: bottom;
  margin-left: 3px;
}

.feedback-negative {
  font-size: 13px;
  font-weight: bold;
  color: #eb2d2d;
  vertical-align: bottom;
  margin-left: 3px;
}

.feedback-pagination {
  margin-top: 35px;
}

.pagination-controls {
  position: relative;
  display: block;
  padding: 6px;
  color: #202020;
  font-weight: bold;
  border: 0;
  font-size: 12px;
}

.pagination-controls:hover {
  text-decoration: none;
}

.page-number-container {
  width: 28px;
  height: 24px;
}
.page-number-container.active .pagination-numbers {
  background: rgb(58, 137, 255);
  border-radius: 50%;
  text-decoration: none;
  color: #fff;
}
.pagination-numbers {
  font-size: 13px;
  position: relative;
  display: block;
  text-align: center;
  color: #202020;
  font-weight: bold;
  border: 0;
  padding: 2px 0 2px 0;
  margin-top: 4px;
  margin-left: 4px;
}

// .pagination-numbers:hover {
//   background: rgb(58, 137, 255);
//   border-radius: 50%;
//   text-decoration: none;
//   color: #fff;
// }

.quotation-mark {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: bold;
  font-size: 35px;
  line-height: 20px;
  color: #007bff;
}

.not-reviewed {
  font-size: 13px;
  text-align: center;
  color: #aaa;
  margin-top: 12px;
}

.feedback-remineder-btn {
  padding: 0 !important;
}

.edit-details-icon {
  position: absolute;
  z-index: 9;
  display: block;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
  margin-top: 10px;
  right: 25px;
  color: #000;
}
.edit-details-label {
  position: absolute;
  z-index: 9;
  display: block;
  font-size: 12px;
  pointer-events: none;
  color: #aaa;
  margin: 8px 0 0 15px;
}
.details-form {
  padding-top: 25px;
  height: 55px;
  font-weight: 600;
}
.details-symbol-limit {
  margin-right: 10px;
}
.details-textarea {
  padding-top: 25px;
  font-weight: 600;
  margin-bottom: 10px;
}
.upload-form {
  opacity: 0;
  height: 100px;
  width: 100%;
}
.upload-details-form {
  padding-top: 25px;
  height: 135px;
  font-weight: 600;
}
.id-card-edit {
  background-repeat: no-repeat;
  background-size: contain;
  width: auto;
  height: 80px;
  position: absolute;
  top: 40px;
}
.upload-id-icon {
  margin: 0 !important;
}
.quit-profile {
  position: absolute;
  top: 35px;
  right: 20px;
}
/* JOBS TABS */
.jobs-tabbed-menu {
  margin-top: 0 !important;
}

.jobs-tab-header > .nav-tabs {
  border: none;
  margin: 0px;
  height: 30px;
}
.jobs-tab-header > .nav-tabs > li {
  margin-right: 5px;
  min-width: 10%;
}
.jobs-tab-header > .nav-tabs > li > a {
  border: 0;
  margin-right: 0;
  text-align: center;
  color: #737373;
  padding: 0 3px 0 3px;
  height: 32px;
}
.jobs-tab-header > .nav-tabs > .alljobsTab > a.active {
  border-bottom: 2px solid #ff7b00;
  color: #ff7b00;
  height: 32px;
}
.jobs-tab-header > .nav-tabs > .alljobsTab > a:hover {
  height: 32px;
  color: #ff7b00;
}
.jobs-tab-header > .nav-tabs > .interestedjobsTab > a.active {
  border-bottom: 2px solid #007bff;
  color: #007bff;
  height: 32px;
}
.jobs-tab-header > .nav-tabs > .interestedjobsTab > a:hover {
  height: 32px;
  color: #007bff;
}
.jobs-tab-header > .nav-tabs > .shortlistedTab > a.active {
  border-bottom: 2px solid #12b448;
  color: #12b448;
  height: 32px;
}
.jobs-tab-header > .nav-tabs > .shortlistedTab > a:hover {
  height: 32px;
  color: #12b448;
}
.jobs-tab-header > .nav-tabs > .hiredTab > a.active {
  border-bottom: 2px solid #ffe600;
  color: #ffe600;
  height: 32px;
}
.jobs-tab-header > .nav-tabs > .hiredTab > a:hover {
  height: 32px;
  color: #ffe600;
}
.jobs-tab-header > .nav-tabs > .unavailable > a.active {
  border-bottom: 2px solid #999999;
  color: #999999;
  height: 32px;
}
.jobs-tab-header > .nav-tabs > .unavailable > a:hover {
  height: 32px;
  color: #999999;
}
.jobs-filter,
.jobsItem-inner {
  margin-top: 20px;
}
.jobs-cat-inner {
  background: #f8f8f8;
  border-radius: 5px;
  margin-top: 20px;
  padding: 12px 12px 12px 12px;
}
.jobs-content {
  background: #ffffff;
  border-radius: 5px;
  padding: 10px 10px 8px 8px;
  margin: 0 0 20px 0;
  border-bottom: 1px #f8f8f8 solid;
}
.jobs-title {
  font-size: 13px;
  font-weight: bold;
  margin: 0;
  padding: 0;
  color: #408df4;
  cursor: pointer;
}
.jobs-title:hover{
  color: #1461c5;
}

.jobs-title-profile {
  font-size: 13px;
  font-weight: bold;
  margin: 0;
  padding: 0;
  color: #408df4;
  cursor: pointer;
}
.jobs-title-profile:hover{
  color: #1461c5;
}


.jobs-img-container {
  max-width: 100px;
}
.jobs-img {
  width: 70px;
  height: 90px;
  object-fit: cover;
  margin: 4px 8px 8px 5px;
  border-radius: 3px;
}
.jobs-inner {
  margin: 3px 0 0 3px;
}
.jobs-price,
.invoice-price {
  font-size: 15px;
  color: #408df4;
  font-weight: bold;
  padding: 0;
  margin: 3px 0 0 0;
}
.jobs-price-mobile {
  font-size: 15px;
  color: #408df4;
  font-weight: bold;
  padding: 0;
  margin: 5px 0 0 0;
}
.jobs-invited {
  font-size: 12px;
  color: #0cb458;
  margin: 3px 0 0 0;
  text-align: right;
  font-weight: 600;
}
.invited-check {
  font-size: 14px;
  margin-right: 5px;
  vertical-align: middle;
}
.jobs-content-footer {
  font-size: 13px;
  border-top: 2px #f8f8f8 solid;
  margin: 4px 0 4px 6px;
}
.jobs-location {
  width: 50%;
  margin-top: 8px;
  display: flex;
  color: #202020;
}
.jobs-location-name {
  margin-left: 3px;
}
.jobs-post-date,
.shortlisted,
.interested {
  font-size: 12px;
  width: 50%;
  text-align: right;
  margin-top: 8px;
  color: #aaa;
}
#tab-2,
#tab-3,
#tab-4 {
  display: none;
} /*JOBS VERTICAL TAB*/

.jobs-gallery-container {
  position: absolute;
  display: flex;
  bottom: -20px;
  margin-left: 5%;
  margin-right: 5%;
  width: 100%;
  z-index: 2;
}
.jobs-tabbed-gallery,
.apply-container {
  position: relative;
  margin: 20px 0 0 10px;
}

.jobs-tabbed-gallery-mobile {
  position: relative;
  margin: 0;
}

.jobs-gallery {
  display: inline-flex;
  width: 18%;
  padding: 5px;
}

/* Style the images inside the grid */
.jobs-gallery img {
  cursor: pointer;
  border-radius: 3px;
}

/* The expanding image container */
.jobs-img-expand {
  display: flex;
  width: 100%;
  height: 300px;
  object-fit: contain;
  margin-top: 12px;
}
.no-photo {
  background: #f8f8f8;
  border-radius: 3px;
}
.no-photo-expanded {
  display: block;
  width: 48px;
  height: 48px;
  margin: 120px auto;
}

.jobs-gallery-tag {
  position: absolute;
  right: 20px;
  margin-top: 15px;
  background: #3989f4;
  font-size: 11px;
  color: #fff;
  padding: 3px 10px;
  border-radius: 20px;
  font-style: normal;
  font-weight: 600;
  z-index: 9;
}

.jobs-gallery-tag-mobile {
  margin-bottom: -10px;
  background: #3989f4;
  font-size: 11px;
  color: #fff;
  padding: 3px 10px;
  border-radius: 20px;
  font-style: normal;
  font-weight: 600;
  z-index: 9;
}

.tag-icon {
  vertical-align: middle;
  margin-right: 4px;
}

.tag-shortlisted {
  background: #0cb458;
  z-index: 9;
}
.tag-hired {
  background: #ffe600;
  z-index: 9;
}
.tag-unavailable {
  background: #6b6b6b;
  z-index: 9;
}

.jobs-galleryImg-lg {
  width: 100%;
  max-height: 300px;
  object-fit: cover;
  border-radius: 3px;
}
.jobs-details-container {
  display: flex;
  height: 25px;
}
.job-poster {
  font-size: 13px;
  font-weight: 600;
  color: #408df4;
  width: 50%;
  text-align: left;
}
.jobs-price-label {
  font-size: 12px;
  font-weight: 600;
  width: 50%;
  text-align: right;
}
.jobs-category {
  font-size: 15px;
  width: 50%;
  font-weight: 600;
}
.jobs-details-location {
  color: #aaa;
}
.jobs-apply-container {
  margin: 30px 0 0 10px;
}
.jobs-apply-info {
  font-size: 12px;
  color: #aaa;
  border-bottom: 2px #f8f8f8 solid;
  padding-bottom: 10px;
  margin: 5px 0 10px 0;
}
.fee {
  margin-left: 8px;
}
.interested {
  text-align: left !important;
}
.apply-container {
  display: flex;
  margin-top: 0;
}
.apply-btn {
  margin-left: 10px;
  font-size: 13px;
}
.accept-btn,
.interest-btn {
  margin: 10px 0 0 10px;
  font-size: 12px;
  width: 100%;
  padding: 10px;

  @include mobile-breakpoint {
    font-size: 13px;
  }
}
.jobs-tablinks:hover,
.jobs-tablinks.active {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.jobs-salary {
  display: flex;
}
.jobs-salary-checkbox {
  margin: 2px 5px 0 3px;
  vertical-align: middle;
}
.jobs-salary-label {
  font-size: 10px;
  font-weight: 600;
}
.jobs-apply-modal,
.invoice-description {
  font-size: 12px;
  color: #aaa;
}
.jobs-apply-success-icon {
  font-size: 36px;
  color: #0cb458;
  display: block;
  margin-left: auto;
  width: 55%;
}
.apply-success {
  text-align: center !important;
  font-weight: normal !important;
}
.bi-telephone,
.bi-chat-left-dots {
  margin-right: 5px;
}
.hire-request-container {
  display: flex;
  margin-bottom: 15px;
}
.hire-request-txt {
  max-width: 60% !important;
  width: auto;
  margin: 0;
}
.hire-request-btn {
  font-size: 13px;
  max-width: 50%;
  width: 40%;
}
.hired-feedback-img {
  margin: 8px 0 5px 0;
}
.hired-feedback {
  margin: 10px 0 0 7px;
}
.feedback-reminder {
  width: 50%;
  font-size: 12px;
  text-align: right;
  margin-top: 8px;
  font-weight: bold;
  text-decoration: underline;
}
.reminder-container {
  border: 2px #ced4da solid;
  border-radius: 3px;
  padding: 0 8px;
  height: 38px;
}
.unavailable-job {
  text-align: center;
}
.chat-modal-footer {
  flex-wrap: nowrap !important;
}
.chat-input {
  width: 100%;
  border: none;
  font-size: 12px;
}
.send-msg-btn {
  width: 120px;
}
.send-msg-icon {
  margin-right: 7px;
  vertical-align: text-bottom;
}
.chat-msg-line {
  display: flex;
  float: left;
  margin-bottom: 10px;
  clear: both;
}
.chat-msg-line-right {
  display: flex;
  float: right;
  margin-top: 12px;
  clear: both;
}
.chat-profile-pic {
  width: 35px;
  height: 35px;
}
.chat-msg-me,
.apply-form {
  background: #f8f8f8;
  font-size: 12px;
  padding: 8px;
  border-radius: 3px;
  margin-left: 5px;
  max-width: 150px;
  color: #545454;
}
.chat-msg-other {
  background: #0cb458;
  font-size: 12px;
  padding: 8px;
  border-radius: 3px;
  color: #fff;
  margin: 0 5px 12px 5px;
}
.chat-msg-time {
  height: 30px;
  font-size: 8px;
  color: #aaa;
  margin-left: 5px;
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
}
.form-dot {
  display: inline-block;
  color: #fff;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  border: 2px #59a1ff solid;
  margin-right: 8px;
}
.job-start-date,
.work-time,
.salary {
  font-size: 12px;
  display: inline-block;
  margin-bottom: 10px;
}
.salary {
  margin-left: 14px;
}
.apply-form {
  width: 185px;
  max-width: 300px;
}
.form-modal-footer {
  border-top: 0 !important;
}
.send-new-form {
  width: fit-content;
  background: #f8f8f8;
  border-radius: 20px;
  margin-left: 15px;
  padding: 8px 12px;
  cursor: pointer;
  color: #59a1ff;
  font-size: 13px;
}
.use-template {
  margin-top: 8px;
  margin-bottom: 8px;
}
.send-new-form a,
.send-new-form a:hover {
  font-size: 13px;
  padding: 12px;
  text-decoration: none;
}
#chat-template {
  position: absolute;
  font-size: 13px;
  bottom: 125px;
  margin-left: 20px;
  max-width: 260px;
  box-shadow: 2px 2px 8px 4px rgba(0, 0, 0, 0.2);
  display: none;
}

.chat-template-header {
  font-weight: bold;
  color: rgb(26, 26, 26);
  margin-bottom: 3px;
  margin-top: 10px;
}
.chat-template-txt {
  padding-bottom: 10px;
  border-bottom: 2px #f8f8f8 solid;
  margin: 0;
}
#chat-template-msg,
#chat-send-msg {
  display: none;
}
#chat-date {
  margin-bottom: 0;
}
.chat-template-container {
  padding: 15px 15px 0 15px;
  background-color: rgb(255, 255, 255);
  color: rgb(43, 43, 43);
  cursor: pointer;
}

.chat-template-closebtn {
  position: absolute;
  right: 10px;
  color: rgb(75, 75, 75);
  font-size: 30px;
  cursor: pointer;
}

.chat-template-closebtn:hover {
  color: rgb(51, 51, 51);
}

/* NOTIFICATIONS PAGE */
.notifications-container {
  margin-bottom: 10px;
}
.notification-date {
  color: #0095f8;
  font-size: 13px;
  font-weight: bold;
}
.notification-grey,
.notification-white {
  background: #f8f8f8;
  font-size: 12px;
  padding: 10px;
  margin: 0;
}
.notification-white {
  background: none;
}

/* ACCOUNT */
.invoice-datepicker {
  width: 168px;
  font-weight: bold;
  font-size: 13px;
}
.invoice-datepicker-container {
  display: flex;
  float: right;
}
.invoice-datepicker-container > input[type="date"]::-webkit-inner-spin-button,
.invoice-datepicker-container
  > input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
  -webkit-appearance: none;
  z-index: 2;
}
.invoice-datepicker-icon {
  position: absolute;
  right: 12px;
  top: 14px;
  font-size: 13px;
  z-index: 1;
}
.unpaid-invoices-title {
  width: 100%;
}
.all-unpaid-label {
  font-size: 11px;
  float: right;
}
.payAll-invoice-checkbox {
  margin-top: 13px;
}
.unpaid-invoice-item {
  display: flex;
}
.unpaid-invoice-container {
  width: 100%;
  padding: 10px;
  margin-right: 12px;
}
.title-container {
  padding: 10px 10px 0 0;
}
.unpaid-invoice-number,
.automatic-payment {
  font-size: 13px;
  font-weight: bold;
}
.invoice-pay-day,
.all-unpaid-label {
  font-size: 12px;
  margin-bottom: 0;
}
.invoice-checkbox {
  margin-top: 30px;
}
.invoice-paid {
  font-size: 13px;
  margin: 12px 0 0 0;
  border-top: 2px #f8f8f8 solid;
  padding-top: 10px;
}
.paid-price {
  color: #474747;
}
.invoice-paid-badge {
  font-size: 11px;
  font-weight: bold;
  color: #0cb458;
  float: right;
}
.invoice-paid-icon {
  vertical-align: middle;
  margin-right: 8px;
}
#payment-options {
  display: none;
}
.select-card {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  font-weight: bold;
  height: auto;
}
.payment-dropdown-menu {
  margin-top: 0;
  width: 300px;
}
.payment-dropdown-icon {
  float: right;
}
.card-type-img {
  width: auto;
  height: 15px;
  margin-right: 10px;
}
.pay-btn-container {
  text-align: center;
  margin: 15px 0 25px 0;
}
.pay-now-btn {
  font-size: 13px;
  width: 300px;
  padding: 8px 0;
}
#pay-button {
  display: none;
}
.addCard-container {
  width: 260px;
  margin: auto;
  text-align: center;
  margin-top: 100px;
}
.addCard-title {
  font-weight: bold;
}
.addCard-button {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  border: 2px #007bff dashed;
  margin: 12px auto;
}
.addCard-plus {
  color: #007bff;
  font-size: 50px;
  margin-top: 24px;
}
.card-month {
  padding-right: 6px;
}
.card-year {
  padding-left: 6px;
}
.automatic-payment-container {
  display: flex;
}
.automatic-payment {
  margin-top: 15px;
}

/*---Automatic payment toggle*/
.auto-pay-switch {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 26px;
  margin: 12px 0 0 10px;
}

.auto-pay-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.auto-pay-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.auto-pay-slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .auto-pay-slider {
  background-color: #12b448;
}

input:focus + .auto-pay-slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .auto-pay-slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.auto-pay-slider.round {
  border-radius: 34px;
}

.auto-pay-slider.round:before {
  border-radius: 50%;
}

#added-card {
  display: none;
}
.card-background {
  width: 320px;
  height: 170px;
  border-radius: 12px;
  background: rgb(163, 163, 163);
  // background: -moz-linear-gradient(left, rgb(28, 182, 97) 0%, rgba(0, 119, 255) 100%);
  // background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgb(28, 182, 97)), color-stop(100%, rgb(0, 119, 255)));
  // background: -webkit-linear-gradient(left, rgb(28, 182, 97) 0%, rgba(0, 119, 255) 100%);
  // background: -o-linear-gradient(left, rgba(28, 182, 97) 0%, rgba(0, 119, 255) 100%);
  // background: -ms-linear-gradient(left, rgba(28, 182, 97) 0%, rgba(0, 119, 255) 100%);
  // background: linear-gradient(to right top, rgba(28, 182, 97) 0%, rgba(0, 119, 255) 100%);
  // filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#ff0000', endColorstr='#00ff00', GradientType=1);
}
.card-details-top,
.card-details-bottom {
  padding: 20px 20px;
  height: 50%;
  display: flex;
}
.card-details-bottom {
  margin-top: 20px;
}
.card-owner-name {
  color: #fff;
  font-weight: bold;
  width: 100%;
}
.delete-card-btn {
  float: right;
  height: 24px;
  width: auto;
  cursor: pointer;
}
.card-number {
  color: #fff;
  width: 100%;
}

.cardNumber,
.cvcNumber input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.cardNumber input[type="number"] {
  -moz-appearance: textfield;
}
.invoice-preview {
  font-size: 13px;
  margin-top: 10px;
  padding-bottom: 14px;
}
.invoice-logo {
  margin-top: -10px;
}
.invoice-preview-details {
  text-align: right;
}
.invoice-addressee {
  display: grid;
  line-height: 22px;
  margin-top: 12px !important;
}
.invoice-borders {
  border-bottom: 2px #f8f8f8 solid;
}
.invoice-borders-top {
  border-top: 2px #f8f8f8 solid;
}
.invoice-description-container {
  border: 2px #f8f8f8 solid;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 12px;
}
.invoice-sum,
.invoice-sum-right {
  width: 50%;
  display: grid;
  line-height: 30px;
  margin-top: 12px;
}
.invoice-sum-right {
  text-align: right;
}
.invoice-sum-details {
  padding-top: 8px;
}
.invoice-total-sum {
  font-size: 13px;
  color: #408df4;
  font-weight: bold;
}
.invoice-modal-footer {
  border: none;
  display: grid;
}
.print-invoice,
.pay-now-btn {
  font-size: 13px;
  font-weight: 600;
  padding: 10px 100px;
  color: #007bff;
  margin: 10px auto;
}
.pay-now-btn {
  color: #fff;
  width: auto;
  margin-top: 5px;
  margin-bottom: 20px;
}

/* =====================CUSTOMER SIDE======================= */
.customer-jobs-container {
  background: #f8f8f8;
  border-radius: 5px;
}
.job-inner-customer {
  padding: 20px 20px 0 20px;
}
.job-inner-container {
  background: #ffffff;
  border-radius: 5px;
  padding: 10px 10px 8px 8px;
}
.job-inner-container:hover {
  // box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  cursor: default;
}
.job-top-section {
  border-bottom: 2px #f8f8f8 solid;
  margin: 0 6px;
}
.job-top-section-left {
  width: 50%;
}
.job-top-section-right {
  width: 50%;
  text-align: right;
}
.job-category-customer {
  font-size: 13px;
  color: black;
  margin-top: 5px;
  font-weight: 600;
}
.job-posted-time {
  font-size: 11px;
  color: #aaa;
  margin-bottom: 5px;
}
.jobs-reacted {
  font-size: 12px;
}
.job-bottom-section {
  display: grid;
}
.invite-sp {
  font-size: 12px;
  font-weight: bold;
  text-decoration: underline;
  margin: 20px auto 10px auto;
}
.shortlist-persons {
  font-size: 12px;
  color: #aaa;
  margin: auto;
}
.interested-sp-btn-container {
  max-width:375px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px auto 12px auto;
}
.interested-sp-btn {
  font-size: 12px;
  padding: 10px 15px;
}
.hired-job-btn-container {
  width: 80%;
  margin: 20px auto 0 auto;
}
.customer-msg-badge {
  position: absolute;
  top: 0px;
  right: 0;
  padding: 3px 8px;
  border-radius: 50%;
  background-color: red;
  color: white;
  border: 2px #fff solid;
  font-size: 9px;
}
.empty-space {
  padding-top: 20px;
}

/* 404 */
.not-found-container {
  width: 300px;
  margin: 100px auto 100px auto;
  display: grid;
}
.not-found-img {
  height: 150px;
  width: auto;
  margin: auto;
}
.not-found {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
}
.not-found-txt {
  font-size: 12px;
  color: #aaa;
  text-align: center;
}
.not-found-btn {
  font-size: 12px;
  width: 100px;
  margin: auto;
  background: #007bff;
  border-radius: 3px;
  color: #fff;
  text-align: center;
  padding: 7px 0;
}
.not-found-btn:hover {
  color: #fff;
  text-decoration: none;
}

/* CONTACT US */
.contact-container,
.whoweare-container {
  margin-top: 30px;
  display: flex;
}
.contact-img {
  max-height: 350px;
  width: auto;
}
.contact-info {
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 6px;
  margin-top: 35px;
}
.contact-detail {
  font-size: 12px;
  color: #aaa;
}
.contact-form-title {
  margin-top: 40px;
  margin-left: 12px;
  font-size: 16px;
  font-weight: bold;
}
.contact-text textarea {
  height: 106px;
}
.contact-send-btn {
  font-size: 14px;
  padding: 10px 0;
}
.contact-form-container {
  margin-bottom: 160px;
}

/* CAREER */
.career-vacancies-bg {
  background: #f8f8f8;
}
.vacancies-container {
  margin: 50px auto 100px auto;
}
.vacancy-row {
  padding-right: 8px;
  padding-left: 8px;
}
.vacancy-container {
  background: #fff;
  border-radius: 4px;
  padding: 12px;
  margin-top: 16px;
}
.vacancy-container-expired {
  background: rgba(0, 0, 0, 0.61);
  border-radius: 4px;
  padding: 12px;
  margin-top: 16px;
}
.vacancy-title {
  width: 100%;
}
.vacancy-date {
  font-size: 11px;
  text-align: right;
}
.exp-dot,
.exp-dot-expired {
  position: absolute;
  right: 13px;
  margin-top: -7px;
  width: 5px;
  height: 5px;
  background: #0cb458;
  border-radius: 50%;
}
.exp-dot-expired {
  background: #850101;
}
.vacancy-description,
.whoWeAre-description {
  font-size: 13px;
  color: #aaa;
  margin-bottom: 0;
}
.vacancy-description-expired {
  font-size: 13px;
  margin-bottom: 0;
}
.vacancy-expired-text {
  position: absolute;
  margin-top: 26px;
  left: 50%;
  font-size: 18px;
  color: white;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
.vacancy-description-full {
  margin-left: 0;
}
.vacancies-container > li {
  list-style-type: circle;
  color: #0076d6;
  margin-top: 12px;
}
.job-apply-container {
  margin: 20px auto 70px auto;
}
.job-apply-section {
  border-top: 2px #f8f8f8 solid;
}
.contact-form-info {
  font-size: 16px;
  font-weight: bold;
  margin-top: 20px;
}
.upload-cv-container {
  width: 300px;
  margin: 150px auto;
}
.upload-cv-inner {
  padding: 15px 0;
  border-top: 2px #f8f8f8 solid;
  border-bottom: 2px #f8f8f8 solid;
}
.upload-cv-label {
  position: absolute;
  z-index: 1;
  font-size: 13px;
  font-weight: bold;
  margin-left: 80px;
  margin-top: 10px;
}
.upload-cv-icon {
  text-align: left;
  float: left;
  font-size: 18px;
  margin-top: -1px;
}
.upload-cv-btn {
  font-size: 13px;
  width: 300px;
  margin: 18px auto;
}
#send-form {
  display: block;
}
#send-success {
  display: none;
  text-align: center;
  margin-top: 100px;
}
.send-success-icon {
  color: #0cb458;
  font-size: 30px;
  border: 2px #0cb458 solid;
  border-radius: 50%;
  padding: 6px 10px;
}
.send-success-txt {
  font-size: 13px;
  color: #0cb458;
  margin-top: 20px;
}

/* WHO WE ARE */
.whoweare-container {
  margin-bottom: 70px;
}
.whoWeAre-title {
  margin-top: 40px;
  font-size: 16px;
  font-weight: bold;
}
.whoWeAre-carousel {
  width: 45%;
  float: left;
  margin-right: 15px;
}
.about-slider {
  width: 100%;
  height: 260px;
  object-fit: cover;
}
.who-we-are {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}
.whoWeAre-bottom-section {
  margin-bottom: 100px;
}

/* FAQ */
.question-container {
  background: #fff;
  border-radius: 4px;
  padding: 20px 15px 5px 15px;
  margin-top: 16px;
}
.question-card {
  transition: 0.3s;
  display: inline-block;
  margin-top: 5px;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.question-card-body {
  padding: 20px 0 10px 0;
  width: 100%;
}
.faq-arrow-container {
  float: right;
}
.faq-arrow,
.faq-arrow-down {
  width: 20px;
  height: 20px;
}
.faq-collapsible.collapsed .faq-arrow {
  display: block;
}
.faq-collapsible.collapsed .faq-arrow-down {
  display: none;
}
.faq-collapsible:not(.collapsed) .faq-arrow {
  display: none;
}
.faq-collapsible:not(.collapsed) .faq-arrow-down {
  display: block;
}
.faq-collapsible:not(.collapsed) {
  // box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}

/* HOW IT WORKS */
.howItWorks-img {
  height: 70px;
  width: auto;
}
.howItWorks-mobile {
  display: none;
}
.howItWorks-title {
  font-size: 15px;
  font-weight: bold;
  margin-top: 8px;
}
.howItWoeks-description {
  font-size: 12px;
  color: #aaa;
  width: 140px;
}
.howItWorks-btn-mobile {
  display: none;
}
.howItWorks-btn {
  font-size: 13px;
  float: right;
}
.howItWorks {
  margin: 40px auto 150px auto;
}
.howItWorks-top {
  margin-bottom: 40px;
}
.howItWorks-hr-mobile {
  display: none;
}

/* LEAVE FEEDBACK */
.positive-feedback-img,
.neutral-feedback-img,
.negative-feedback-img {
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.neutral-feedback-img {
  display: block;
  margin: auto;
}
.negative-feedback-img {
  display: block;
  margin: 0 4px 0 auto;
}
.feedback-type-pos,
.feedback-type-neu,
.feedback-type-neg {
  font-size: 12px;
  font-weight: bold;
  margin: 8px 0 0 0;
}
.feedback-type-neu {
  text-align: center;
}
.feedback-type-neg {
  text-align: right;
}
.feedback-modal-footer,
.feedback-modal-header {
  border: none;
}

/* ======CUSTOMER JOBS PAGE====== */
.customer-jobs-menu button {
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  background: #fff;
  padding: 12px 8px;
  border-radius: 4px;
  margin-bottom: 20px;
  cursor: pointer;
  border: none;
  width: 100%;
}

.customer-jobs-menu-mobile button {
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  background: #007bff;
  color: #fff;
  padding: 12px 8px;
  border-radius: 4px;
  margin-bottom: 20px;
  cursor: pointer;
  border: none;
  width: 100%;
}

.customer-job-page,
.edit-customer-job-page,
.delete-customer-job-page {
  margin: 0;
  padding: 0 5px !important;
}

.customer-jobs-menu button:hover {
  background: #408df4;
  color: #fff;
}

.customer-jobs-details-container {
  display: flex;
}
.customer-jobs-img {
  width: 100%;
}
#customer-jobs-gallery {
  position: absolute;
  margin-top: 8px;
  left: 8px;
  width: 142px;
  background: #fff;
  height: 30px;
  border-radius: 20px;
  cursor: pointer;
  display: none;
  z-index: 9;
}
.customer-jobs-addImg-btn {
  font-size: 11px;
  font-weight: bold;
  position: absolute;
  left: 8px;
}
#customer-jobs-gallery-del {
  position: absolute;
  margin-top: 8px;
  right: 8px;
  width: 80px;
  background: #fff;
  height: 30px;
  border-radius: 20px;
  cursor: pointer;
  display: none;
  z-index: 9;
}
.customer-jobs-del-btn {
  font-size: 11px;
  font-weight: bold;
  position: absolute;
  right: 14px;
  margin-top: 6px;
}

.plus-icon {
  font-size: 20px;
  vertical-align: sub;
}
.trash-icon {
  font-size: 14px;
  vertical-align: middle;
}
#customer-edit-container {
  margin-top: 15px;
  border: 2px solid #ced4da;
  border-radius: 4px;
  height: 75px;
  padding-bottom: 115px;
  display: none;
}
.customer-job-description {
  font-size: 13px;
  overflow: hidden;
  border: none !important;
}
.customer-edit-job-submit {
  font-size: 13px;
  padding: 7px 20px;
  position: absolute;
  right: 8px;
  margin-top: 4px;
}
.customer-edit-job-submit-m {
  font-size: 13px;
  padding: 7px 20px;
  position: absolute;
  right: 20px;
  margin-top: 53px;
  margin-bottom:20px;
}
.customer-jobs-gallery {
  margin: 0;
}
.customer-gallery-inner {
  margin: 0 auto;
  display: flex;
  width: 90%;
}
.customer-jobs-gallery-img {
  display: inline-flex;
  padding: 5px;
  width: auto;
  height: 65px;
  margin: auto;
}
.remove-job-modal {
  max-width: 500px;
}
.remove-job-options {
  padding: 15px;
  margin-left: 25px;
}
.remove-job-options-hr {
  border: 1px #f8f8f8 solid;
  margin: 0;
}
.remove-job-pencil-icon {
  position: absolute;
  right: 25px;
  top: 25px;
}
.remove-job-max-symbols {
  position: absolute;
  right: 25px;
  top: 115px;
  font-size: 10px;
  color: #929292;
}
.remove-job-radio:checked ~ label {
  color: #007bff;
}
.sp-list-container {
  height: 1035px;
  overflow-y: auto;
}
.sp-list-container-mobile {
  height: 235px;
  overflow-y: auto;
}
.sp-customerView-msg {
  font-size: 12px;
  margin: auto;
  padding-top: 7px;
  text-align: center;
  color: #0cb458;
}
.customer-jobs-inner {
  background: #f8f8f8;
  border-radius: 5px;
  margin-top: 20px;
}
.customer-jobs-menu {
  display: flex;
  padding: 10px 5px 0 5px;
}
.customer-jobs-menu-mobile {
  display: flex;

  margin-top: 30px;
}
.customer-jobs-invite-txt {
  width: 300px;
  margin: auto;
}
.customer-jobs-invite-btn {
  font-size: 12px;
  font-weight: bold;
  margin: 10px auto 10px auto;
  text-align: center;
}
.customer-jobs-posted {
  font-size: 11px;
  color: #aaa;
  padding: 0 10px 0 10px;
}
.customer-jobs-hired-sp,
.customer-jobs-shortlisted-sp,
.customer-jobs-interested-sp {
  font-size: 13px;
  font-weight: bold;
  color: #ffe600;
  margin: auto 5px auto 10px;
}
.customer-jobs-shortlisted-sp {
  color: #12b448;
}
.customer-jobs-interested-sp {
  color: #007bff;
}
.customer-jobs {
  margin: 10px 10px 20px 10px;
}
.sp-profileView-slider {
  display: flex;
  width: 100%;
  height: 260px;
  object-fit: contain;
  margin-top: 8px;
}
.sp-indicators .active {
  background: none;
  border: 2px #fff solid;
}
.sp-indicators li {
  background: #fff;
  opacity: 1;
}
.sp-profileView-feedback {
  display: flex;
  margin: 12px 0;
  padding-bottom: 15px;
  border-bottom: 2px #f8f8f8 solid;
}
.portfolio-title {
  margin-left: 12px;
}
.sp-portfolio {
  -webkit-mask-image: initial !important;
}
.sp-portfolio-img-container {
  margin-right: 10px;
  box-shadow: none !important;
  border: none;
}
.sp-portfolio-img-container:hover {
  box-shadow: none !important;
}
.sp-portfolio-img {
  height: 120px;
  width: 120px;
  border-radius: 4px;
  object-fit: cover;
}
.sp-portfolio-arrow-l,
.sp-portfolio-arrow-r {
  width: 40px;
  height: 40px;
}
.sp-portfolio-next {
  margin-top: 55px;
  right: 5px;
}
.sp-portfolio-prev {
  margin-top: 55px;
  left: 0;
}
.sp-certificates {
  display: flex;
}
.sp-certificate-container {
  background: #fff;
  border-radius: 4px;
  padding: 15px;
  margin-top: 16px;
}
.sp-certificate-pdf {
  color: #0076d6;
}
.sp-certificate-title {
  margin-top: 7px;
}
.sp-allFeedbacks-container {
  background: #fff;
  border-radius: 4px;
  padding: 15px;
  min-width: 300px;
  max-width: 350px;
  white-space: normal;
}
.sp-allFeedback-next {
  margin-top: 95px;
  right: 5px;
}
.sp-allFeedback-prev {
  margin-top: 95px;
  left: 0;
}
.sp-buttons-container {
  display: flex;
  margin: 10px auto 10px auto;
}
.sp-button-right {
  padding: 0 0 0 5px;
}
.sp-button-left {
  padding: 0 5px 0 0;
}
.sp-button-center {
  padding: 0;
}
.sp-buttons {
  width: 100%;
  font-size: 13px;
}

/* Works on Firefox */
.sp-list-container {
  scrollbar-width: none;
  scrollbar-color: #0076d6 #f8f8f8;
}

/* Works on Chrome, Edge, and Safari */
.sp-list-container::-webkit-scrollbar {
  width: 4px;
}

.sp-list-container::-webkit-scrollbar-track {
  background: none;
}

.sp-list-container::-webkit-scrollbar-thumb {
  background-color: #0076d6;
  border-radius: 30px;
  border: none;
}
.all-feedbacks-nav {
  padding: 0 0 12px 12px;
  margin: 0;
  /* Make this scrollable when needed */
  overflow-x: auto;
  /* We don't want vertical scrolling */
  overflow-y: hidden;
  /* Make an auto-hiding scroller for the 3 people using a IE */
  -ms-overflow-style: -ms-autohiding-scrollbar;
  /* For WebKit implementations, provide inertia scrolling */
  -webkit-overflow-scrolling: touch;
  /* We don't want internal inline elements to wrap */
  white-space: nowrap;
  /* Remove the default scrollbar for WebKit implementations */
  -webkit-mask-image: linear-gradient(90deg, black, rgba(59, 59, 59, 0.6));
}
.all-feedbacks-nav::-webkit-scrollbar {
  display: none;
}
.navv-prev {
  width: 50px;
  height: 50px;
  position: absolute;
  left: 0;
  margin-top: 100px;
  z-index: 3;
  display: none;
  cursor: pointer;
}

.navv-next {
  width: 50px;
  height: 50px;
  position: absolute;
  right: 7px;
  margin-top: 100px;
  z-index: 3;
  cursor: pointer;
}
.all-feedbacks-hr {
  border-top: 2px #f8f8f8 solid;
  margin: 1.2rem -1rem;
}

/* FORGOT PASSWORD SUCCESS TOAST */
.success-toast {
  position: absolute;
  bottom: 0;
  width: 300px;
  z-index: 3;
  margin: auto;
}

/*Device compatibility*/

@media screen and (max-width: 600px) {
  h1 {
    font-size: 28px;
    font-weight: bold;
  }
}

@media screen and (max-height: 450px) {
  .overlay a {
    font-size: 20px;
  }
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}

@media screen and (max-width: 1200px) {
  .nav-button {
    display: block;
  }
  .navi-menu {
    display: none;
  }
}

@media screen and (max-width: 521px) {
  .logo {
    height: 35px;
    margin-top: 22px;
    margin-left: 10px;
  }
  .cards-title {
    padding-left: 40px;
    font-size: 20px;
  }
  .navi-user-container {
    padding-top: 15px;
  }
  .login-button {
    display: none;
  }
  .login-button-mobile {
    display: block;
    float: right;
    padding: 12px;
    background: #f8f8f8;
    border-radius: 50%;
  }
  .profile-button {
    display: none;
  }

  .user-login {
    float: right;
    display: block;
    width: 38px;
    height: 38px;
    padding: 10px;
    background: #f5f5f5;
    border-radius: 20px;
  }
  .dropdown-menu {
    margin-left: -85px;
  }
  .insta-icon {
    margin-left: 5px;
  }
  .job-carousel {
    max-height: 200px;
    object-fit: cover;
  }

  /* SP LISTING */
  .listing-filter {
    width: 50px !important;
    margin-left: 6px;
  }

  .listing-filter-icon {
    margin: 0;
    color: #007bff;
  }

  .sp-listing-inviteBtn-mobile {
    display: block;
    position: relative;
    font-size: 13px;
    padding: 12px 35px;
    border-radius: 4px;
    margin-top: 15px;
  }

  /* CARD DETAIlS */
  .addCard-container {
    margin-top: 50px;
  }

  /* CUSTOMER SIDE */
  .job-top-section-left {
    width: 70%;
  }
  .job-top-section-right {
    width: 30%;
  }
  .hired-job-btn-container {
    width: 100%;
    display: flex;
  }

  /* CAREER */
  .vacancy-title {
    font-size: 14px !important;
  }
  .vacancy-expired-text {
    margin-top: 32px;
  }
}

@media only screen and (max-width: 321px) {
  /* SP LISTING */
  .listing-filter {
    width: 45px !important;
  }
  .auto-pay-switch {
    width: 100px !important;
  }
  .card-background {
    width: 300px;
  }
  /* INVOICE */
  .invoice-logo {
    height: 65px;
  }
}

@media screen and (min-width: 521px) {
  .job-carousel {
    max-height: 350px;
    object-fit: cover;
  }

  .chat-modal {
    max-width: 360px !important;
  }
}

@media screen and (max-width: 769px) {
  .rate-container {
    width: 200px;
    height: 38px;
  }
  .rate-percent {
    background: none;
  }
  .card-name {
    font-size: 15px;
  }
  .card-job-title {
    font-size: 12px;
  }
  .cata-sub-nav {
    -webkit-mask-image: unset;
  }
  .nav-prev {
    visibility: hidden;
  }
  .nav-next {
    visibility: hidden;
  }
  .footer-menu {
    line-height: 10px;
    color: #d4d4d4;
  }
  .language {
    display: none;
  }
  .chat-icon {
    right: 15px;
  }
  /*.post-job-logo {
        height: 75px;
    }*/
  .post-job-bck-btn-lg {
    display: none;
  }
  .post-job-bck-btn-sm {
    display: inline-block;
    text-align: right;
    font-size: 14px;
  }

  /* SP LISTING */
  .listing-filter {
    width: 70px;
    margin-left: 6px;
  }
  .listing-filter-txt {
    display: none;
  }
  .listing-filter-icon {
    color: #007bff;
  }
  .navi-user-container {
    display: none;
  }
  .header-profile-mobile {
    display: flex;
    margin-top: 15px;
  }
  .header-mobile-jobsBtn {
    font-weight: bold;
    margin-top: 12px;
  }

  .system-notifications {
    display: none;
  }
  .system-notifications-dropdown {
    right: -70px !important;
    left: auto;
    margin-right: auto;
    margin-left: auto;
  }

  /* HOW IT WORKS */
  .howItWorks-img {
    display: none;
  }
  .howItWorks-mobile {
    display: block;
  }
  .howItWorks-img-mobile {
    width: auto;
    height: 110px;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 767px) {
  .howItWorks-btn {
    display: none;
  }
  .howItWorks-btn-mobile {
    display: grid;
    margin: 20px auto;
  }
  .howItWorks-mobile-txt {
    font-size: 13px;
    font-weight: bold;
    text-align: center;
  }
  .howItWorks-button {
    margin: auto;
  }
  .howItWorks-hr-mobile {
    display: block;
  }
  .howItWorks-hr {
    border: 1px #f1f1f1 solid;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 991px) {
  .category-list {
    line-height: 15px;
    -moz-column-count: 1;
    -webkit-column-count: 1;
    column-count: 1;
  }
  .services {
    width: 100%;
    float: left;
  }
  .company {
    width: 100%;
    float: left;
  }
  #more {
    display: none;
  }
  #moree {
    display: none;
  }
  .see-more {
    display: block;
    cursor: pointer;
    font-size: 14px;
  }
  .post-job-title {
    display: none;
  }
  .post-job-text {
    display: none;
  }
  .profile-title-bg {
    display: none;
  }

  .header-mobile {
    display: none;
  }
  .sidebar-header-mobile {
    display: flex;
    width: 100%;
  }
  .mobile-sidebar {
    display: inline-block;
    width: 100%;
    margin-top: 25px;
  }

  .account-container {
    background: #f8f8f8;
    padding: 12px 18px 15px 18px;
  }
  a.customer-view-btn {
    color: #408df4;
  }
  .customer-view {
    width: 100%;
    background: #fff;
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    padding: 20px 0 20px 0;
  }
  .account-completion {
    font-size: 18px;
  }
  .mobile-spacing {
    display: none;
  }
  .mobile-menu {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
  }

  .mobile-menu-header {
    margin-bottom: 0;
    border-bottom: 2px solid rgba(0, 0, 0, 0.05);
  }
  .mobile-menu-btn {
    border: none;
    width: 100%;
    padding: 20px 0 20px 0;
    background: none;
  }
  .mobile-menu-btn:not(.collapsed) .menu-arrow {
    color: #000;
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .mobile-menu-btn.collapsed .menu-arrow {
    color: #8b8b8b;
  }
  .mobile-menu-btn:not(.collapsed) .menu-item {
    color: #000;
  }
  .mobile-menu-btn.collapsed .menu-item {
    color: #8b8b8b;
  }
  .mobile-menu-btn:not(.collapsed) .profile-menu {
    background: url(../../../resources/icons/profile-dark.png);
  }
  .mobile-menu-btn.collapsed .profile-menu {
    background: url(../../../resources/icons/profile.png);
  }
  .mobile-menu-btn:not(.collapsed) .myJobs-menu {
    background: url(../../../resources/icons/my-jobs-dark.png);
  }
  .mobile-menu-btn.collapsed .myJobs-menu {
    background: url(../../../resources/icons/my-jobs.png);
  }
  .mobile-menu-btn:not(.collapsed) .account-menu {
    background: url(../../../resources/icons/my-account-dark.png);
  }
  .mobile-menu-btn.collapsed .account-menu {
    background: url(../../../resources/icons/my-account.png);
  }
  .mobile-menu-btn:not(.collapsed) .notifications-menu {
    background: url(../../../resources/icons/notifications-dark.png);
  }
  .mobile-menu-btn.collapsed .notifications-menu {
    background: url(../../../resources/icons/notifications.png);
  }
  .mobile-menu-btn:not(.collapsed) .post-job-menu {
    background: url(../../../resources/icons/post-job-dark.png);
  }
  .mobile-menu-btn.collapsed .post-job-menu {
    background: url(../../../resources/icons/post-job.png);
  }
  .menu-btn-container {
    display: flex;
  }
  .menu-btn-inner {
    width: 100%;
  }
  .menu-item {
    text-align: left;
    font-size: 16px;
    width: 100%;
    padding: 0;
    margin-left: 38px;
  }
  .menu-arrow {
    float: right;
    margin-right: 12px;
    color: #8b8b8b;
  }
  .mobile-sidebar-bottom {
    background: #f8f8f8;
    height: 130px;
  }
  .submenu-container {
    padding: 12px 12px 12px 0;
    margin-left: 10px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.05);
  }
  .mobile-menu-header li:last-child {
    border-bottom: none;
  }
  .mobile-menu-header ul > li {
    display: inline;
  }
  .submenu-icon {
    width: 35px;
    height: 35px;
    padding: 8px;
    background: #f8f8f8;
    border-radius: 3px;
  }
  .submenu-notifications {
    margin-left: 15px;
    font-size: 14px;
    font-weight: bold;
    margin-top: 6px;
    white-space: nowrap;
    width: 100%;
  }
  .submenu-txt {
    display: flex;
    width: 100%;
    color: #474747;
  }
  .submenu-txt:hover {
    text-decoration: none;
    color: #474747;
  }
  .submenu-message-sum {
    margin-left: 8px;
    font-size: 12px;
    font-weight: bold;
    margin-top: 8px;
    color: #408df4;
    width: 100%;
  }
  .submenu-arrow {
    font-size: 13px;
    margin-top: 8px;
  }

  /* HIDDEN TABBED MENU */
  .profile-tabbed-menu {
    // display: none;
  }
  .details-text-mobile {
    font-weight: 600;
    padding: 8px 12px 12px 13px;
    margin-bottom: 18px;
  }
  .edit-details {
    background: #f8f8f8;
    height: 60px;
    margin-bottom: 10px;
    margin-top: 0;
  }
  .editDetails-title-container {
    padding: 12px 0 0 10px;
    display: flex;
  }
  .editDetails-back-btn {
    font-size: 26px;
  }
  .edit-details-title {
    font-weight: bold;
    font-size: 18px;
    width: 80%;
    text-align: left;
    margin-top: 5px;
  }
  .edit-details-btn {
    font-size: 14px;
    font-weight: 600;
    color: #9e9e9e94;
    margin: 0;
    padding: 0 0 5px 0;
    display: inline-block;
    width: 100%;
  }
  .details-container {
    padding: 0 12px 0 12px;
    margin: 0;
  }
  .jobcat-mobile-container {
    background: #f8f8f8;
  }
  .jobcat-mobile-inner {
    padding: 0 0 20px 0;
  }
  .templates-mobile {
    background: #ffffff;
    border-radius: 5px;
    padding: 8px 0 10px 0;
    margin: 0 0 15px 0;
  }
  .edit-template-mobile {
    margin-top: 5px !important;
    left: -50px !important;
  }
  .edit-template-btn {
    text-align: right;
  }
  .edit-template-container {
    display: flex;
    border: none;
  }
  .edit-template-item {
    font-size: 13px;
  }
  .edit-template-icon {
    margin-left: 12px;
    font-size: 15px;
    margin-top: 3px;
  }
  .feedback-filter {
    margin-top: 10px;
    height: 60px !important;
  }
  .feedback-date {
    font-size: 12px;
    padding-top: 25px;
    margin: 0;
  }
  .feedback-cat-title {
    font-size: 14px;
    font-weight: bolder;
    margin: 0 8px 10px 0;
  }
  .portfolio-imgrid {
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0 22px 0 22px;
  }
  .portfolio-img-remove {
    margin: 0;
    right: 3px;
    top: 3px;
  }
  /*JOBS*/
  .all-jobs-title {
    background: #f76437;
    color: #fff;
    margin: 0;
  }
  .interested-jobs-title {
    background: #3989f4;
    color: #fff;
    margin: 0;
  }
  .shortlisted-title {
    background: #0cb458;
    color: #fff;
    margin: 0;
  }
  .hired-title {
    background: #fccc30fa;
    color: #fff;
    margin: 0;
  }
  .unavailable-title {
    background: #8a8a8a;
    color: #fff;
    margin: 0;
  }
  .jobs-filter {
    margin: 0;
    padding: 12px 12px 8px 12px !important;
    background: #fff;
  }
  .jobs-cat-inner {
    margin-top: 0;
  }
  .jobs-title {
    font-size: 15px;
  }
  .jobs-price {
    width: 50%;
    float: left;
  }
  .jobsItem-price {
    width: 50%;
    text-align: right;
  }
  .jobs-invited {
    width: 50%;
    float: right;
  }
  .jobs-invited-mobile {
    width: 100%;
  }
  .feedback-pagination {
    margin: 0;
  }
  .sidebar-jobs {
    background: #f8f8f8;
  }
  .sidebar-header-jobs {
    background: #fff;
  }
  .shortlisted-btns {
    border-bottom: 2px #f8f8f8 solid;
    padding: 5px 0 8px 0;
  }
  .shortlisted-mobile-btns {
    margin-top: 10px;
  }
  .accept-btn {
    margin: 0 0 0 5px;
  }
  .jobsItem-accept-btn {
    margin: 15px 0;
  }
  .interest-btn {
    margin: 0;
  }
  .badge {
    position: absolute;
    top: -10px;
    right: 8px;
    padding: 3px 7px;
    border-radius: 50%;
    background-color: red;
    color: white;
    border: 2px #fff solid;
    font-size: 11px;
  }
  .jobs-location-name {
    font-size: 13px;
  }
  .reminder-txt {
    width: 100%;
    text-align: center;
  }
  .reminder-container {
    width: 100%;
    margin-top: 8px;
    display: flex;
  }
  .feedback-reminder {
    width: 100%;
    text-align: center;
    text-decoration: none;
  }
  .jobs-apply-container {
    margin: 15px 0 0 0;
    border-top: 2px #f8f8f8 solid;
  }
  .jobs-apply-container-mobile {
    margin: 7.5px  ;
    border-top: 2px #f8f8f8 solid;
  }
  .apply-container {
    margin-left: 0;
  }
  .close-btn {
    position: absolute;
    top: 25px;
    right: 15px;
  }
  .jobsItem-close {
    font-size: 30px;
  }
  .apply-btn {
    margin: 0;
  }
  .jobs-img-expand {
    height: 200px;
    margin-top: 0;
  }
  .no-photo-expanded {
    margin: 80px auto;
  }
  .hired-job-feedback {
    border-top: 2px #f8f8f8 solid;
    margin-top: 12px;
    padding-top: 10px;
  }
  .hire-request-container {
    display: block;
    margin: 10px 0;
    padding-bottom: 15px;
    border-bottom: 2px #f8f8f8 solid;
  }
  .hired-txt {
    text-align: center;
    margin-bottom: 10px;
  }
  .send-hire-request {
    color: #408df4;
    font-size: 13px;
    font-weight: 600;
  }

  /*SP LISTING*/
  .system-notifications-dropdown {
    right: -90px;
  }

  /*NOTIFICATIONS*/
  .notifications-page {
    background: #f8f8f8;
    padding-bottom: 60px;
  }
  .notifications-page-header {
    background: #fff;
  }
  .notifications-page-title {
    margin-bottom: 0;
  }
  .notifications-container {
    margin-bottom: 10px;
    background: #fff;
  }
  .notification-white {
    border-top: 2px #f8f8f8 solid;
    padding: 14px;
  }
  .notification-date-container {
    display: flex;
    padding-top: 10px;
  }
  .notification-date {
    margin-left: 14px;
    width: 50%;
    font-size: 14px;
  }
  .date-right {
    font-size: 13px;
    float: right;
    width: 50%;
    text-align: right;
    margin-right: 12px;
  }
  .profile-desktop {
    display: none;
  }

  /* CREDIT CARD DETAILS */
  .auto-pay-switch {
    width: 72px;
  }
  .added-card-container {
    padding: 10px;
  }
  .card-mobile {
    margin: auto;
  }

  .invoice-datepicker-icon {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 13px;
    z-index: 1;
    border: 2px #ced4da solid;
    border-radius: 4px;
    padding: 11px 13px;
    color: #0076d6;
  }
  .search-invoice {
    width: 100%;
  }
  .datepicker-invoice {
    max-width: 50px;
  }
  .listing-search {
    margin: 15px 15px;
  }

  .listing-search input[type="date"] {
    border: 0;
    height: 45px;
    opacity: 0;
    z-index: 99999;
    width: 45px;
  }
  .payment-dropdown-menu {
    margin: auto !important;
  }
  .invoice-logo {
    margin-top: 12px;
  }
  .invoice-preview-details {
    line-height: 10px;
  }
  .print-invoice,
  .pay-now-btn {
    padding: 10px 0;
    width: 100%;
  }
  .pay-btn-container {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  /* CUSTOMER SIDE */
  .customer-menu-title {
    font-weight: bold;
    font-size: 18px;
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }
  .customer-msg-badge {
    top: -10px;
    right: -5px;
  }

  /* CONTACT US */
  .contact-img {
    height: auto;
    width: 100%;
  }
  .contact-info {
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 6px;
    margin-top: 20px;
    text-align: center;
  }
  .contact-detail {
    font-size: 13px;
    color: #aaa;
    text-align: center;
  }
  .contact-details-hr {
    border-top: 2px #f8f8f8 solid;
  }
  .contact-form-container {
    background: #f8f8f8;
    padding: 20px 15px 100px 15px;
    max-width: 100%;
    margin-bottom: 0;
  }
  .contact-mobile-inner-container {
    background: #fff;
    padding: 15px;
    border-radius: 4px;
  }
  .contact-form-title {
    margin-top: 10px;
  }
  .upload-cv-container {
    margin-top: 15px;
  }
  #send-success {
    margin-bottom: 60px;
  }

  /* WHO WE ARE */
  .whoWeAre-carousel {
    width: 100%;
    margin: 0 auto 20px auto;
  }

  /* CUSTOMER JOB */
  .sp-listing-bg {
    display: none;
  }
  .customer-jobs-posted {
    padding-left: 0;
  }
  .customer-job-details {
    display: flex;
  }
  #submit-edited-job {
    display: none;
  }
  .sp-customerView-msg {
    padding: 10px;
  }
  .sp-response-title {
    background: #fff;
    font-size: 13px;
    color: #408df4;
    padding: 0 0 8px 15px;
  }
  .sp-customerView-hr {
    border-top: 2px #f8f8f8 solid;
    margin: 7px auto 10px auto;
  }
  .sp-profileView-title {
    margin-bottom: 0;
  }
  .sp-profileView-slider {
    margin-top: 0;
  }
  .sp-profileView-location {
    width: 50%;
    text-align: right;
  }
  .sp-profileView-feedback {
    border-top: 2px #f8f8f8 solid;
    padding-top: 12px;
  }
  .sp-portfolio {
    padding: 0;
  }
  .portfolio-title {
    margin: 0;
  }
  .sp-certificates-container {
    padding: 0;
  }
  .sp-certificates-inner {
    padding: 25px 15px;
  }
  .open-pdf-icon {
    float: right;
    width: 14px;
    height: 14px;
    margin-top: 4px;
  }
  /* FORGOT PASSWORD SUCCESS TOAST */
  .success-toast {
    position: unset;
  }
}

@media only screen and (max-width: 500px) {
  .portfolio-grid-img {
    max-height: 70px;
  }

  .tag-shortlisted, .tag-hired, .tag-interested{
    z-index: 0;
  }

}
@media only screen and (max-width: 400px) {
  .mobile-menu-header {
    padding-left: 0;
    padding-right: 6px;
  }
  .menu-btn-container {
    width: 100%;
  }
  .submenu-container {
    padding: 12px 6px 12px 0;
    margin-left: 6px;
  }
  .submenu-notifications {
    min-width: inherit !important;
    width: 100% !important;
  }
  .auto-pay-switch {
    width: 82px !important;
  }
  .tag-shortlisted, .tag-hired, .tag-interested{
    z-index: 0;
  }
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
  .modal-content {
    width: 100%;
  }
  .tag-shortlisted, .tag-hired, .tag-interested{
    z-index: 0;
  }
}

//Sass styles
.customer {
  .container  {
    padding: 0;
    margin-top: 20px;

    @include mobile-breakpoint {
      margin-top: 0;
    }
  }
  .sidebar-menu-containers {
    display: block !important;
    padding: 15px;
    min-height: 67px;

    @include mobile-breakpoint {
      display: flex;
      min-height: auto;
    }
  }

  .profile-sidebar-menu {
    background: #f8f8f8;
    height: auto;
    padding: 20px;

    @include mobile-breakpoint {
      // padding: 0;
    }
  }
  .sidebar-menu-items {
    .active {
      p {
      font-weight: bold;
      color: #212529;
      }
    }
  }

  .hide-on-mobile {
    display: none;
  }

  .modal-dialog {
    margin-top: 30%;
    
    @include mobile-breakpoint {
      margin-top: 10%;
    }
  }
}

.cust-manage-profile {
  font-weight: bold;
  font-size: 15px;
}



