.blog__content {
    width: 80%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 30px auto;
}

.blog__categories {
    height: 80px;   
}

.blog__topics {
    display: grid;
    width: fit-content;
    grid-template-columns: 1fr 1fr 1fr 1fr; 
    grid-gap: 20px;
    margin: 20px auto 0 auto;
}

.blog__searchbox {
    width: 300px;
}

.bi-filter-right {
    display: none;
}

.no-article-message {
    position: absolute;
    color: red;
    left: 30%;
}

@media only screen and (max-width: 600px) {
    .blog__content {
        width: 94%;
    }
    .blog__topics {
        grid-template-columns: 1fr; 
        font-size: 12px;
    }
    .blog__categories {
        display: none;   
    }

    .blog__searchbox {
        width: 100%;
    }

    .bi-filter-right {
        display: block;
        font-size: 30px;
        color: #dedede
    }

    .filter__box {
        width: 50px;
        height: 50px;
        margin-left: 1rem;
        padding: 12px;
        border-radius: 4px;
        border: solid 2px lightgray;
      }
    .no-article-message{
        left: 10%;
    }
  }