@import "../../styles/utils/mixins.scss";

.hero {
    img {
      object-fit: cover;
    }
  }

  .hero-text-ab {
    position: absolute;
    left: 0;
    right: 0;
    margin: 50px 15px;
    text-align: center;
    color: #000;
  
    @include mobile-breakpoint {
      margin: 80px auto;
    }
  
    h1 {
      font-weight: normal;
    }
  }

  .white-space {
    width: 100%;
    padding-top: 50px;
  }

  .categories-large {
    padding: 0 15px 0 0;
    margin-top: 15px;
  }


