/* CONTACT US */
.contact-container, .whoweare-container {
    margin-top: 30px;
    display: flex;
}
.contact-img {
    max-height: 350px;
    width: auto;
}
.contact-info {
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 6px;
    margin-top: 35px;
}
.contact-detail {
    font-size: 12px;
    color: #aaa;
}
.contact-form-title {
    margin-top: 40px;
    margin-left: 12px;
    font-size: 16px;
    font-weight: bold;
}
.contact-text textarea {
    height: 106px;
}
.contact-send-btn {
    font-size: 14px;
    padding: 10px 0;
}
.contact-form-container {
    margin-bottom: 160px;
}

#label__text {
    font-size: 13px;
    font-weight: bold;
}

@media only screen and (max-width: 450px) {
    .contact-img {
        width: 100%;        
    }
}