.job-post {
  .card {
    border: none !important;
    max-width: 100%;
  }

  .reg-form-inner {
    padding: 0;
  }
  .sign-in-btn {
    margin: 20px auto !important;
    width: 100% !important;
  }
}
.modal-header {
  display: block !important;
}

.tandclink__jobpost:hover {
  cursor: pointer;
}

.job-progress {
  padding: 10px 40px;

  .job-progress-label {
    padding-bottom: 10px;

    h6 {
      color: #2b2b2b;
      font-weight: bolder;
    }
  }

  .progress {
    height: 5px !important;
  }
}

.carousel-indicators {
  bottom: -50px !important;
}

/*Registration & Post-a-job*/
.register {
  // background: url("../../images/register_bg.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100%;
  margin: 0;
  padding: 0;
  padding-bottom: 100px;
}
.register-header {
  text-align: right;
  margin-top: 10px;
}
.post-job-title {
  color: #fff;
}
.post-job-text {
  color: #ccc;
  font-size: 18px;
}
a.post-job,
a.post-job:hover {
  color: #fff;
  text-decoration: underline;
  font-weight: 500;
}
.post-job-logo {
  height: 65px;
}
.post-job-bck-btn-lg {
  font-size: 13px;
  font-weight: 600;
}
.post-job-bck-btn-sm {
  display: none;
}
.post-job-btn-lg {
  font-size: 14px;
  font-weight: 600;
  text-decoration: none !important;
}
#post-job-sbmt-btn-lg {
  font-size: 14px;
  font-weight: 600;
  text-decoration: none !important;
}

.carousel-indicators li {
  width: 4px !important;
  height: 4px !important;
  border: #ddd 2px solid !important;
  border-radius: 20px !important;
  margin-top: -2px !important;
  transition: opacity 0.6s ease !important;

  &.active {
    border: #313131 2px solid !important;
  }
}

.carousel-control-prev,
.carousel-control-next {
  display: none !important;
}

.error-text-style{
  font-size: 14px;
  margin-bottom: 5px;
}

.post-job-sbmt-btn {
  font-size: 14px;
  font-weight: 600;
}
.post-job-carousel {
  background: #fff;
  padding: 20px;
  border-radius: 4px;
}
.post-job-carousel-inner {
  margin: 45px 0 !important;

  .form-control {
    padding-left: 5px;
  }
}
.progress-bar {
  //     background: rgb(163, 163, 163);
  //   background: -moz-linear-gradient(left, rgb(28, 182, 97) 0%, rgba(0, 119, 255) 100%);
  //   background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgb(28, 182, 97)), color-stop(100%, rgb(0, 119, 255)));
  //   background: -webkit-linear-gradient(left, rgb(28, 182, 97) 0%, rgba(0, 119, 255) 100%);
  //   background: -o-linear-gradient(left, rgba(28, 182, 97) 0%, rgba(0, 119, 255) 100%);
  //   background: -ms-linear-gradient(left, rgba(28, 182, 97) 0%, rgba(0, 119, 255) 100%);
  background: linear-gradient(
    to right,
    rgba(28, 182, 97, 0) 0%,
    rgba(0, 119, 255, 0) 100%
  );

  //filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#ff0000', endColorstr='#00ff00', GradientType=1);
}
.carousel-indicators .active {
  background: #ffffff;
  width: 4px;
  height: 4px;
  border: rgb(49, 49, 49) 2px solid;
  border-radius: 20px;
  margin-top: -2px;
}
.carousel-indicators li {
  background: #707070;
  width: 3px;
  height: 3px;
  border: 0;
  border-radius: 10px;
  vertical-align: bottom;
}
.post-job-form {
  margin-top: 15px;
}
.post-job-file-label {
  font-size: 14px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.post-job-file-label-icon {
  text-align: right;
  float: right;
  font-size: 22px;
  font-weight: bold;
  margin-top: -10px;
}
.post-job-input-label-icon {
  float: right;
  font-size: 16px;
  font-weight: bolder;
  margin-top: -32px;
  margin-right: 8px;
}
.custom-control-label {
  font-size: 12px;
  padding-top: 3px;
}
.post-job-chckbox {
  padding-top: 12px;
  padding-left: 0 !important;
}
.progress {
  height: 5px !important;
}
.progress-bar {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
}
#progressbar {
  width: 25%;
}

#progressbar-1 {
  width: 50%;
}

#progressbar-2 {
  width: 75%;
}

#progressbar-3 {
  width: 100%;
}

#catasterisk, 
#titleasterisk, 
#descasterisk, 
#budgcasterisk, 
#citycasterisk,
#streetasterisk,
#nameasterisk,
#surnameasterisk,
#emailasterisk,
#startdataasetrisk{
  position: relative;
} 

.asteriskpos,
.titleasterisk,
.descasterisk,
.budgcasterisk,
.citycasterisk,
.streetasterisk,
.nameasterisk,
.surnameasterisk,
.emailasterisk,
.startdataasetrisk{
  position: absolute;
  // height: 50px;
  font-size: 16px;
  color: red;  
  left: 0;
  top: 0;
  // text-align: left;
  // vertical-align: top;
}

.warningasterisk{
  margin-top: 10px;
  font-size: 13px;
  text-align: center;
}

.read-terms {
  font-size: 13px;
  padding-top: 13px;
  text-align: right;
  color: #3989f4;
  cursor: pointer;
  text-decoration: underline;
}
.read-terms-text {
  font-size: 13px;
  color: #929292;
}
.modal-title {
  font-size: 14px;
  font-weight: bold;
}
.pencil-icon {
  position: absolute;
  right: 10px;
  top: 8px;
  width: auto !important;
}
.max-symbols {
  position: absolute;
  right: 15px;
  top: 100px;
  font-size: 10px;
  color: #929292;
}
.ui-widget.ui-widget-content {
  margin: 10px;
}
option {
  font-size: 13px;
}
.post-job-feedback {
  min-height: 400px;

  .job-posted {
    display: flex !important;
    min-height: 100px;
  }
}

.loader-wrapper {
  position: absolute;
  z-index: 3000;
  width: 100%;
  left: 0;
  right: 0;
  height: 100%;
  text-align: center;

  .spinner-border {
    position: absolute;
    top: 50%;
    /* transform: translateY(-50%); */
    margin: auto;
    left: 0;
    right: 0;
  }
}

.react-datepicker-wrapper {
  display: contents;
}

.react-datepicker {
  display: flex;
}

.react-datepicker-popper {
  display: contents;
  z-index: 10000;
}

.image-count {
  display: inline;
  padding-top: 8px;
  font-size: 11px;
  padding-left: 10px;
}

.login-item {
  .login-card {
    max-width: 100%;
    border: none;
    padding: 0 !important;
    margin: auto !important;
    justify-content: center;
    display: block !important;

    .register-message,
    .jobPost-message {
      display: none;
    }

    .login-header {
      display: none;
    }

    .form-wrapper {
      border: none;
    }
  }
  .form-inner {
    padding: 0;
  }
}

.post-job-wrapper {
  justify-content: center;
}

.post-job-left-col {
  justify-content: start;
  display: flex;
  flex-direction: column;
}
.disable-indicators {
  .carousel-indicators,
  .carousel-indicators li {
    cursor: default;
    pointer-events: none;
  }
}
