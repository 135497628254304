@import "../../styles/utils/mixins.scss";

@font-face {
  font-family: "helvetica";
  src: url(../../../src/resources/fonts/helvetica_bold.ttf) format("ttf");
}

body {
  background: #ffffff;
  font-family: helvetica;
}
b {
  font-weight: bolder;
}
hr {
  margin: 10px 0 0 0;
}
h1 {
  font-size: 35px;
  font-weight: bold;
}

.header-container {
  width: 100%;
}

.header {
  background: #ffffff;
  height: 75px;
}

.banner-top-button {
  position: absolute;
  text-align: center;
  margin: 0;
  padding-top: 200px;
  left: 0;
  right: 0;
  color: #ffffff;
}
.main-container {
  width: 90%;
}
.logo {
  min-width: 95px;
}
.nav-link {
  padding-top: 25px;
  // font-family: helvetica-b;
}

.navi-menu {
  font-weight: bold;
  padding: 0 30px;

  @include mobile-breakpoint {
    //  margin-left: 5%;
    justify-content: space-around;
  }

  .main-logo {
    padding: 15px;
    margin-right: 80px;
  }

  .main-logo a:hover {
    border: none;
  }

  a {
    color: rgb(82, 82, 82);

    @include mobile-breakpoint {
      font-size: 16px;
    }
    &:hover {
      color: rgb(58, 137, 255);
      border-top: rgb(58, 137, 255) 2px solid;
    }
  }
}
.logo-container {
  display: block;

  @include mobile-breakpoint {
    display: none;
  }
}
.header-user-login-wrapper {
  float: none;
  display: flex;
  padding: 10px;
  background: transparent;
  margin-top: -10px;
  line-height: 0 !important;
  position: absolute;
  right: 0;

  @include mobile-breakpoint {
    position: relative;
    margin: 0 5px;
  }

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #f5f5f5;
    padding: 2px;

    @include mobile-breakpoint {
      border: none;
      margin-right: 10px;
      width: 30px;
      height: 30px;
    }
  }
}

.bell-icon {
  float: none !important;
  width: 40px;
  margin-top: 6px;
  padding-left: 0;
  position: absolute;
  right: 55px;
  margin-right: 15px;

  @include mobile-breakpoint {
    position: relative;
    margin-top: 8px !important;
  }

  img {
    @include mobile-breakpoint {
      width: 28px;
      height: 28px;
    }
  }
}
.user-login {
  display: none;
}
.login-button {
  float: none;
  min-height: 44px;
  cursor: pointer;
}

.login-button-capt {
  display: flex;
}

.login-button-lbl {
  padding: 8px 0 0 8px;
}

.jobs-button {
  min-height: 44px;
  min-width: 100px;
}
.form-control {
  border: 2px solid #ced4da;
  padding-left: 45px;
  height: 45px;
  font-size: 14px;
}

.seeAll-categories {
  display: none;
}

.cat-small-icon {
  margin-top: 25px;
}

.categories-title {
  font-size: 20px;
  font-weight: bold;
  padding-top: 5px;
}
.white-space {
  width: 100%;
  padding-top: 50px;
}
#loadMore {
  font-size: 14px;
  text-align: center;
  vertical-align: text-bottom;
  color: #3989f4;
  font-weight: bold;
}
#loadMore:hover {
  color: #3989f4;
  text-decoration: none;
}

/*overlay menu*/
.nav-button {
  display: none;
  padding-top: 17px;
}
.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgb(31, 31, 31);
  overflow-x: hidden;
  transition: 0.5s;
}
.overlay-content {
  position: relative;
  top: 25%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}
.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 28px;
  color: #818181;
  display: block;
  transition: 0.3s;
}
.overlay a:hover,
.overlay a:focus {
  color: #f1f1f1;
}
.overlay .closebtn {
  position: absolute;
  top: 10px;
  right: 45px;
  font-size: 35px;
  color: #ffffff;
}
.overlay .title {
  position: absolute;
  top: 20px;
  left: 45px;
  font-size: 30px;
  color: #ffffff;
}
hr.overlay-menu {
  border: 1px solid rgb(43, 43, 43);
}

/*horizontal srolling cards*/
.cards-title {
  word-wrap: break-word;
  white-space: normal;
}
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  display: inline-block;
  margin-right: 20px;
  margin-top: 15px;
  border-radius: 5px;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.card-container {
  width: 260px;
  padding: 12px 10px 0 10px;
  word-wrap: break-word;
  white-space: normal;
}
.card-img {
  border-radius: 2px 2px 0 0;
  width: 260px;
}
.card-name {
  margin: 0;
}
.card-job-title {
  color: #3989f4;
  font-weight: bold;
  font-size: 15px;
  margin: 0;
}
.rate-container {
  width: 240px;
  height: 24px;
}
.rate {
  float: left;
  height: 26px;
  padding: 0;
}
.rate:not(:checked) > input {
  position: absolute;
  top: -9999px;
}
.rate:not(:checked) > label {
  float: right;
  width: 1em;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 20px;
  color: #ccc;
}
.rate:not(:checked) > label:before {
  content: "★ ";
}
.rate > input:checked ~ label {
  color: #ffc700;
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
  color: #deb217;
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
  color: #c59b08;
}
.rate-percent {
  float: right;
  font-size: 12px;
  margin-top: 5px;
  padding: 3px 10px 3px 10px;
  background: #f5f5f5;
  color: #818181;
  border-radius: 5px;
}
.card-description {
  font-size: 13px;
  padding-top: 10px;
  word-wrap: break-word;
  white-space: normal;
  color: #818181;
}
.hr-container {
  width: 100%;
  background: #f5f5f5;
  height: 500px;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}
.hr-container::-webkit-scrollbar {
  display: none;
}
.inner-container {
  padding-top: 50px;
}
.cata-sub-nav {
  padding: 0 0 12px 12px;
  margin: 0;
  /* Make this scrollable when needed */
  overflow-x: auto;
  /* We don't want vertical scrolling */
  overflow-y: hidden;
  /* Make an auto-hiding scroller for the 3 people using a IE */
  -ms-overflow-style: -ms-autohiding-scrollbar;
  /* For WebKit implementations, provide inertia scrolling */
  -webkit-overflow-scrolling: touch;
  /* We don't want internal inline elements to wrap */
  white-space: nowrap;
  /* Remove the default scrollbar for WebKit implementations */
  -webkit-mask-image: linear-gradient(90deg, black, rgba(59, 59, 59, 0.6));
}
.cata-sub-nav::-webkit-scrollbar {
  display: none;
}

.customnav {
  flex-wrap: nowrap !important;
}

.nav-prev {
  width: 50px;
  height: 50px;
  position: absolute;
  left: 40px;
  margin-top: 200px;
  z-index: 999999;
}
.nav-next {
  width: 50px;
  height: 50px;
  position: absolute;
  right: 7px;
  margin-top: 200px;
  z-index: 999999;
}
.first-flow-image {
  float: left;
}
.flow-image {
  float: right;
  max-width: 120px;
  height: auto;
}
.flow-hr-line {
  position: absolute;
  width: 100%;
  background: #408df4;
  height: 2px;
  margin-top: 100px;
  z-index: -1;
}

.footer {
  background: #383838;
  width: 100%;
  height: auto;
  // position: sticky;
  bottom: 0;
}
.footer-titles {
  font-size: 14px;
  padding-top: 30px;
  color: #fff;
  font-weight: bold;
}
a.footer-menu-urls {
  color: #dfdfdf;
  text-decoration: none;
  line-height: 28px;
}
a:hover.footer-menu-urls {
  text-decoration: underline;
}
.category-list {
  -moz-column-count: 4;
  -moz-column-gap: 30px;
  -webkit-column-count: 4;
  -webkit-column-gap: 30px;
  column-count: 4;
  column-gap: 30px;
  font-size: 14px;
  line-height: 15px;
  color: #d4d4d4;
}
.services {
  width: 70%;
  float: left;
}
.company {
  width: 30%;
  float: right;
}

.copyright-text {
  font-size: 14px;
  text-align: center;
  vertical-align: middle;
  color: #6d6d6d;
}
.copyright-symbol {
  font-size: 30px;
}
a.language {
  font-size: 14px;
  float: right;
  margin-left: 10px;
  color: #f5f5f5;
  cursor: pointer;
}
/*Device compatibility*/
@media screen and (max-width: 600px) {
  h1 {
    font-size: 28px;
    font-weight: bold;
  }
}
@media screen and (max-height: 450px) {
  .overlay a {
    font-size: 20px;
  }
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}

@media screen and (max-width: 1200px) {
  .nav-button {
    display: block;
  }
}

@media screen and (max-width: 1200px) {
  .navi-menu {
    display: none;
  }
}

@media screen and (max-width: 521px) {
  .logo {
    width: 100%;
  }
}
@media screen and (max-width: 521px) {
  .cards-title {
    padding-left: 40px;
    font-size: 20px;
  }
}
@media screen and (max-width: 521px) {
  // .login-button {
  //   display: none;
  // }
}
@media screen and (max-width: 521px) {
  .bell-icon {
    float: right;
    width: 40px;
    margin-top: 5px;
    padding-left: 0;
  }
}
@media screen and (max-width: 521px) {
  .user-login {
    float: right;
    display: block;
    width: 38px;
    height: 38px;
    padding: 10px;
    background: #f5f5f5;
    border-radius: 20px;
  }
}

@media screen and (max-width: 769px) {
  .categories-large {
    display: none;
  }
}
@media screen and (max-width: 769px) {
  .categories-title {
    float: left;
    width: 70%;
  }
}
@media screen and (max-width: 769px) {
  .seeAll-categories {
    float: left;
    width: 30%;
    text-align: right;
    margin-top: 10px;
    font-weight: bold;
    color: #408df4;
  }
}

@media screen and (max-width: 769px) {
  .cat-horizontal::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (max-width: 769px) {
  .card-bottom-hline {
    display: none;
  }
}
@media screen and (max-width: 769px) {
  .card-description {
    display: none;
  }
}
@media screen and (max-width: 769px) {
  .flow-container {
    display: none;
  }
}
@media screen and (max-width: 769px) {
  .card-container {
    width: 220px;
  }
}
@media screen and (max-width: 769px) {
  .card-img {
    width: 220px;
  }
}
@media screen and (max-width: 769px) {
  .rate-container {
    width: 200px;
    height: 38px;
  }
}
@media screen and (max-width: 769px) {
  .rate-percent {
    background: none;
  }
}
@media screen and (max-width: 769px) {
  .card-name {
    font-size: 15px;
  }
}
@media screen and (max-width: 769px) {
  .card-job-title {
    font-size: 12px;
  }
}
@media screen and (max-width: 769px) {
  .cata-sub-nav {
    -webkit-mask-image: unset;
  }
}
@media screen and (max-width: 769px) {
  .nav-prev {
    visibility: hidden;
  }
}
@media screen and (max-width: 769px) {
  .nav-next {
    visibility: hidden;
  }
}
@media screen and (max-width: 992px) {
  .category-list {
    line-height: 15px;
    -moz-column-count: 1;
    -webkit-column-count: 1;
    column-count: 1;
  }
}
@media screen and (max-width: 769px) {
  .footer-menu {
    line-height: 10px;
    color: #d4d4d4;
  }
}
@media screen and (max-width: 992px) {
  .services {
    width: 100%;
    float: left;
  }
}
@media screen and (max-width: 992px) {
  .company {
    width: 100%;
    float: left;
  }
}

@media screen and (max-width: 992px) {
  #more {
    display: none;
  }
}
@media screen and (max-width: 992px) {
  #moree {
    display: none;
  }
}
@media screen and (max-width: 992px) {
  .see-more {
    display: block;
    cursor: pointer;
    font-size: 14px;
  }
}
@media screen and (max-width: 769px) {
  .language {
    display: none;
  }
}

@media screen and (min-width: 769px) {
  .header-wrapper {
    height: 70px;
  }
}

.header-wrapper {
  height: 82px;
  padding: 5px 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  display: flex;

  @include mobile-breakpoint {
    padding: 0;
  }

  .btn {
    line-height: 0 !important;
  }

  @media screen and (max-width: 365px) {
    .logo-container {
      padding-left: 52px;      
   } 
  }  

  @media screen and (max-width: 365px) {
    .bell-icon {
      right: 30px;      
   }
  }
   
  

  .logo-mobile {
    // width: 100%;
    margin: 0 45px;
  }
  .logo-container{
    margin-right: 20px;
  }

  .login-btn-wrapper {
    width: 100%;
    padding: 15px 30px 15px 15px;
    display: flex !important;
    justify-content: flex-end !important;

    @include mobile-breakpoint {
      display: block;
      position: relative;
      right: 0;
    }

    &.is-logged {
      @include mobile-breakpoint {
        position: relative;
        right: 0;
      }
    }

    .dropdown {
      position: absolute;
      right: 10px;
      top: 10px;

      .btn-secondary {
        background-color: transparent !important;
        border: none !important;
        outline: none !important;
        -webkit-appearance: none !important;
        box-shadow: none !important;
      }
    }

    .dropdown-menu {
      width: 250px;
      transform: translate3d(-98px, 50px, 0px) !important;
    }

    .login-button {
      width: 100%;
      background: none;
      border: none;
      padding: 20px;
      outline: none !important;

      @include mobile-breakpoint {
        // position: absolute;
        // right: 0;
        padding: 10px 15px;
        width: auto;
        background-color: #007bff;
        border-color: #007bff;
      }
    }

    .jobs-button {
      width: 100%;
      background: none;
      border: none;
      outline: none !important;
      color: #007bff;
      font-weight: bold;
      padding: 0;
      margin: 20px 0;
      display: none;

      @include mobile-breakpoint {
        display: inline-block;
        color: #fff;
        position: relative;
        padding: 10px;
        width: auto;
        background-color: #28a745;
        border-color: #28a745;
        margin-right: 5px;
        margin: 0;
        padding: 20px;
      }
    }
  }

  .menu-head {
    z-index: 45;
    position: absolute;
    background: #2b2b2b;
    width: 100%;
    top: 0;
    min-height: 80px;
    padding: 20px 10px;

    .menu-text {
      color: #fff;
      position: absolute;
      left: 20px;
      z-index: 20;
      font-size: 25px;
      font-weight: bold;
    }
  }

  .social-icons {
    display: inline-flex;

    a {
      border: none;
    }
  }
}

.auth-header-wrapper {
  justify-content: space-around;
  margin-bottom: 20px;
  padding-left: 30px;
  // height: 80px;
  // @include mobile-breakpoint {
  //   height: 30px;
  // }

  .logo {
    display: flex;
    justify-content: flex-end;
    padding: 10px 0;
    width: 50px;

    @include mobile-breakpoint {
      margin: 20px 0;
    }

    img {
      width: 100%;
    }
  }

  .back-button {
    display: flex;
    justify-content: flex-start;
    float: right;
    margin: 20px 10px;
    background-color: #1ab253;
    border: 0;

    @include mobile-breakpoint {
      margin: 20px;
    }
  }
}
