@import "../../styles/utils/mixins.scss";

.loginText {
  color: #007bff;
  font-weight: bold;
  cursor: pointer;
}

.verify-btn {
  display: block;
  margin: 20px auto;
}

.successMessage {
  font-weight: bold;
}

.sign-up-header {
  padding: 15px;
  border-bottom: 2px solid #eee;
  margin-bottom: 0;

  @include mobile-breakpoint {
    margin-bottom: 20px;
  }

  h3 {
    font-weight: bold;
    font-size: 18px;

    @include mobile-breakpoint {
      font-size: 20px;
    }
  }
}

.reg-form-inner {
  padding: 15px;
}

.error,
#error {
  border: 1px solid red !important;
}

.bd-example {
  padding: 1.5rem;
  margin-right: 0;
  margin-left: 0;
  border-width: 1px;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.MuiOutlinedInput-root {
  margin-bottom: 10px;
}

.citysel{
  font-family: 'helvetica-m';
}

.description {
  min-height: 150px;
}

.alert-danger {
  margin: auto 15px;
}

.PhoneInputInput {
  display: block;
  width: 100%;
  border: 2px solid #ced4da;
  height: 45px;
  font-size: 14px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.PhoneInputCountryIcon {
  height: 30px !important;
  width: auto !important;
}

.JobCatInput{
  font-size: 14px;
}

.MuiInputLabel-outlined, .MuiInputBase-input, .MuiAutocomplete-listbox{
  font-family: 'helvetica-m' !important;
  -moz-font-feature-settings: "liga" !important;
  -webkit-font-feature-settings: "liga" !important;
  font-feature-settings: "liga"  !important;
  font-size: 15px !important;
}



.error-message {
  color: red !important;
  text-align: center;
  font-size: 11px;
}

.btn-wapper {
  margin-left: 0 !important;
}

.g-recaptcha{
  transform:scale(0.9);
  -webkit-transform:scale(0.9);
  transform-origin:0 0;
  -webkit-transform-origin:0 0;
  }


  .register-btn {
    //  width: 40%;
    margin: 20px 15px;
    min-height: 50px;
    padding: 15px 0;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.first-step-btn {
      display: block;
      margin: 20px auto;
      display: flex;
      justify-content: center;
    }

    @include mobile-breakpoint {
      width: 94%;
      padding: 15px;
    }
  }