.blog__topics__container {
  width: 80%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 3rem;
  padding: 10px;
  margin: 30px auto;
  font-family: Montserrat;
  // background-color: red;
}

.blog__text, .blog__comments {
  grid-column: span 3;
  // background-color: red;
  // // width: 20%;
}

.blog__recomended {
  grid-row: span 2;
}

.article__name {
  font-weight: bold;
  font-size : clamp(10px, 5vw, 2rem);
}

.blog__info__panel {
  display: flex;
  font-size: 14px;
  margin: 1rem 0;
  justify-content: space-between;
}

.blog__text p, .blog__comments p {
  font-size: 14px;  
  color: #707070;  
  line-height: 1.79;  
} 

.line__between {
  width: 2px;
  height: 18px;
  background-color: #f7f7f7;
}

.no-italic {
  font-style: normal;
}

.share__menu {
  position: absolute;
  top: 22px;
  width: 250px;
  height: 168px;
  padding: 16px 0;
  border-radius: 4px;
  box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
}

.share__links__linebetween {
  width: 100%;
  height: 2px;
  margin: 15px 0;
  background-color: #f7f7f7;
}

.article__text{
  white-space: pre-wrap;
}

@media only screen and (max-width: 600px) {

  .blog__topics__container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .blog__info__panel{
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

}





