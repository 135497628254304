.modalfade {
  animation: fadeIn 2s;
  -moz-animation: fadeIn 2s; /* Firefox */
  -webkit-animation: fadeIn 2s; /* Safari and Chrome */
  -o-animation: fadeIn 2s; /* Opera */
}
@keyframes fadeIn {
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-moz-keyframes fadeIn { /* Firefox */
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-webkit-keyframes fadeIn { /* Safari and Chrome */
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-o-keyframes fadeIn { /* Opera */
  from {
      opacity:0;
  }
  to {
      opacity: 1;
  }
}

.profile__details__image { 
  width: 180px;
  height: 120px;
  border-radius: 5px;
}

.cover {
  object-fit: cover;
}

.profile__details__portfolio {
  display: grid;
  grid-auto-flow: column;
  gap: 5px;
  // width: auto;  
  overflow-y: scroll;
}

.profile__details__portfolio::-webkit-scrollbar {
  display: none;

}