@import "../../styles/utils/mixins.scss";

.login-card {
  margin: auto !important;
  display: flex !important;
  justify-content: space-evenly;
  padding: 0 !important;
  box-shadow: none !important;
  width: 90% !important;
  margin: 40px auto !important;

  @include mobile-breakpoint {
    width: 100%;
  }

  .form-wrapper {
    .login-header {
      padding: 15px;

      h4 {
        font-weight: bold;
        font-size: 16px;
        @include mobile-breakpoint {
        font-size: 18px;
        }
      }
    }
  }

  .form-inner {
    padding: 15px;
    border-bottom: 2px solid #eee;
    padding-bottom: 30px;
  }

  .register-message {
    margin-top: 20px;
    padding-left: 15px;
    font-size: 12px;

    @include mobile-breakpoint {
      font-size: 16px;
    }
  }

  .jobPost-message {
    margin-top: 15px;
    padding-left: 15px;
    font-size: 12px;

    @include mobile-breakpoint {
      font-size: 16px;
    }
  }

  .forgot-password {
    padding-top: 10px;
  }

  .form-control {
    padding-left: 5px !important;
  }

  .sign-in-btn {
    width: 40%;
    margin: 20px 15px;
    min-height: 50px;
    padding: 15px 0;
    font-size: 14px;

    &.first-step-btn {
      display: block;
      margin: 20px auto;
      display: flex;
      justify-content: center;
    }

    @include mobile-breakpoint {
      width: 94%;
      padding: 15px;
    }
  }
}
