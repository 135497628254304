.HIW__container {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    align-items: center;
    padding-top: 70px;
    padding-bottom: 70px;
}

.HIW__title {
    display: flex;
    justify-content: space-between;
    width: 70%;
    color: grey;
    // background-color: green;
}

.HIW__title p {
    max-width: 700px;
}

.HIW__title h4 {
    color: black;
    font-weight: bold;
}
.HIW__diagram {
    display: flex;
    gap: 5rem;
    // align-items: center;
    justify-content: center;
    width: 70%;
    height: 550px;
}


.HIW__section {
    max-width: 200px;
    height: 420px;
    font-size: 13px;
    color: grey;
}


.HIW__diagram h3 {
    font-size: 14px;
    font-weight: bold;
    color: black;
}

.HIW__button {
    align-self: flex-start;
}

@media only screen and (max-width: 600px) {

    .HIW__container {
        position: relative;
    }
   .HIW__diagram {
        height: auto;
        flex-direction: column;
        width: auto;
        gap: 1rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    .HIW__section {
        height: auto;
        display: flex;
        flex-direction: row-reverse;
        gap: 10px;
        max-width: none;
        & img {
            align-self: flex-start;
        }
    }

    .HIW__title {
        max-width: none;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        font-size: 15px;
        width: auto;
    }

    .HIW__button {
        position: absolute;
        width: fit-content;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto 2rem auto;
    }

  }