@import "../../styles/utils/mixins.scss";

@font-face {
  font-family: "helvetica-b";
  src: url("../../resources/fonts/helveticageobold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: "helvetica-m";
  src: url("../../resources/fonts/helveticageomed.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: "helvetica-l";
  src: url("../../resources/fonts/helveticageolight.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: auto;
}
.fcaps {
  -moz-font-feature-settings: "case";
  -webkit-font-feature-settings: "case";
  font-feature-settings: "case" on;
}

body {
  background: #ffffff;
  font-family: helvetica-m !important;
  // height: 100vh;
}

b {
  font-weight: bolder;
}
hr {
  margin: 10px 0 0 0;
}
h1 {
  font-size: 35px;
  font-weight: bold;
}
.header {
  background: #ffffff;
  height: 75px;
}
.top-banner {
  object-fit: cover;
}
.banner-top-title {
  position: absolute;
  text-align: center;
  margin: 0;
  padding-top: 165px;
  left: 0;
  right: 0;
  color: #ffffff;
}
.banner-top-text {
}
.banner-top-button {
  position: absolute;
  text-align: center;
  margin: 0;
  padding-top: 285px;
  left: 0;
  right: 0;
  color: #ffffff;
  @include sm-breakpoint {
    padding-top: 300px !important;
  }

}
.main-container {
  width: 90%;
}
.logo {
  min-width: 95px;
}
.nav-link {
  padding-top: 25px;
}
.navi {
  height: 82px;
}
.navi-menu a {
  color: rgb(82, 82, 82);
}
.navi-menu a:hover {
  color: rgb(58, 137, 255);
  border-top: rgb(58, 137, 255) 2px solid;
}
.navi-user-container {
  padding-top: 20px;
}
.login-icon {
  vertical-align: text-bottom;
}

.user-login {
  display: none;
}

.form-control {
  border: 2px solid #ced4da;
  padding-left: 45px;
  height: 45px;
  font-size: 14px;
}
.ic-search {
  margin-top: 14px;
  margin-left: 14px;
}

.ic-search {
  position: absolute;
  z-index: 1;
  display: block;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}
.seeAll-categories {
  display: none;
}
.cat-small-card-align {
  float: left;
}
.cat-small-card {
  background: #e8eff8;
  font-weight: bold;
  color: #545454;
  height: 100px;
  // min-width: 105px;

  text-align: center;
  border-radius: 4px;
}
.cat-small-title {
  font-size: 11px;
  font-weight: bold;
  white-space: normal;
  text-align: left;
  margin: 25px 0;

  @include mobile-breakpoint {
    font-size: 12px;
  }
}
.cat-small-icon {
  margin-top: 25px;
}
.category-icons {
  position: relative;
  left: 0;
  right: 0;
  margin: 20px auto;

  @include mobile-breakpoint {
    position: absolute;
  }
}
.categories-title {
  font-size: 20px;
  font-weight: bold;
  padding-top: 5px;
}

.category-row {
  display: block;
  cursor: pointer;
  transition: all 1s ease;
  text-decoration: none;
  // margin: 0 0 15px 15px;
  margin: 0;
  padding: 7px !important;
  &:hover {
    //  transform: scale(1.05);
    text-decoration: none;
  }
}

.category-box {
  background: #f7f7f7;
  overflow: hidden;
  height: 100%;
  transform: translate(0, 0);
}

.category-box span {
  display: block;
  background: #007bff;
  transition: 0.2s;
}

.category-box span:nth-child(1),
.category-box span:nth-child(3) {
  position: absolute;
  height: 1px;
  width: 100%;
}
.category-box span:nth-child(2),
.category-box span:nth-child(4) {
  position: absolute;
  height: 100%;
  width: 1px;
}

.category-box span:nth-child(1) {
  top: 0;
  left: -100%;
}
.category-box span:nth-child(2) {
  top: -100%;
  right: 0;
}
.category-box span:nth-child(3) {
  bottom: 0;
  right: -100%;
}
.category-box span:nth-child(4) {
  bottom: -100%;
  left: 0;
}

.category-box:hover span:nth-child(1) {
  top: 0;
  left: 0;
}
.category-box:hover span:nth-child(2) {
  top: 0;
  right: 0;
}
.category-box:hover span:nth-child(3) {
  bottom: 0;
  right: 0;
}
.category-box:hover span:nth-child(4) {
  bottom: 0;
  left: 0;
}

.categories-cards {
  font-weight: bold;
  color: #545454;

  height: 146px;
  font-size: 13px;
  text-align: center;
  padding: 80px 10px 0;
  border-radius: 4px;
  text-decoration: none;
}
.categories-small-cards {
  font-weight: bold;
  color: #545454;
  height: 76px;
  font-size: 13px;
  text-align: center;
  padding: 20px 10px 0;
  border-radius: 4px;
  text-decoration: none;
}
.categories-large {
  padding: 0 15px 0 0;
  margin-top: 15px;
}
.categories-small {
 // display: none;
}
.white-space {
  width: 100%;
  padding-top: 50px;
}
#loadMore-container {
  text-align: center;
}
#loadMore {
  font-size: 14px;
  text-align: center;
  vertical-align: text-bottom;
  color: #3989f4;
  font-weight: bold;
}
#loadMore:hover {
  color: #3989f4;
  text-decoration: none;
}
.providers-right {
  width: 150px;
}
.all-providers-btn {
  float: right;
}

/*overlay menu*/
.nav-button {
  display: none;
  padding-top: 17px;
  font-size: 30px;
  cursor: pointer;
}
.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgb(31, 31, 31);
  overflow-x: hidden;
  transition: 0.5s;
}
.overlay-content {
  position: relative;
  top: 25%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}
.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 28px;
  color: #818181;
  display: block;
  transition: 0.3s;
}
.overlay a:hover,
.overlay a:focus {
  color: #f1f1f1;
}
.overlay .closebtn {
  position: absolute;
  top: 10px;
  right: 45px;
  font-size: 35px;
  color: #ffffff;
}
.overlay .title {
  position: absolute;
  top: 20px;
  left: 45px;
  font-size: 30px;
  color: #ffffff;
}
hr.overlay-menu {
  border: 1px solid rgb(43, 43, 43);
}

/*horizontal srolling cards*/
.cards-title {
  word-wrap: break-word;
  white-space: normal;
}
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  display: inline-block;
  margin-right: 20px;
  margin-top: 15px;
  border-radius: 5px;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.card-container {
  width: 260px;
  padding: 12px 10px 0 10px;
  word-wrap: break-word;
  white-space: normal;
}
.card-img {
  border-radius: 2px 2px 0 0;
  width: 260px;
}
.card-name {
  margin: 0;
}
.card-job-title {
  color: #3989f4;
  font-weight: bold;
  font-size: 15px;
  margin: 0;
}
.rate-container {
  width: 240px;
  height: 24px;
}
.rate {
  float: left;
  height: 26px;
  padding: 0;
}
.rate:not(:checked) > input {
  position: absolute;
  top: -9999px;
}
.rate:not(:checked) > label {
  float: right;
  width: 1em;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 20px;
  color: #ccc;
}
.rate:not(:checked) > label:before {
  content: "★ ";
}
.rate > input:checked ~ label {
  color: #ffc700;
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
  color: #deb217;
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
  color: #c59b08;
}
.rate-percent {
  float: right;
  font-size: 12px;
  margin-top: 5px;
  padding: 3px 10px 3px 10px;
  background: #f5f5f5;
  color: #818181;
  border-radius: 5px;
}
.card-description {
  font-size: 13px;
  padding-top: 10px;
  word-wrap: break-word;
  white-space: normal;
  color: #818181;
}
.hr-container {
  width: 100%;
  background: #f5f5f5;
  height: 500px;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}
.hr-container::-webkit-scrollbar {
  display: none;
}
.inner-container {
  padding-top: 50px;
}
.cata-sub-nav {
  padding: 0 0 12px 12px;
  margin: 0;
  /* Make this scrollable when needed */
  overflow-x: auto;
  /* We don't want vertical scrolling */
  overflow-y: hidden;
  /* Make an auto-hiding scroller for the 3 people using a IE */
  -ms-overflow-style: -ms-autohiding-scrollbar;
  /* For WebKit implementations, provide inertia scrolling */
  -webkit-overflow-scrolling: touch;
  /* We don't want internal inline elements to wrap */
  white-space: nowrap;
  /* Remove the default scrollbar for WebKit implementations */
  -webkit-mask-image: linear-gradient(90deg, black, rgba(59, 59, 59, 0.6));
}
.cata-sub-nav::-webkit-scrollbar {
  display: none;
}

.nav-prev {
  width: 50px;
  height: 50px;
  position: absolute;
  left: 40px;
  margin-top: 200px;
  z-index: 999999;
  display: none;
  cursor: pointer;
}
.nav-next {
  width: 50px;
  height: 50px;
  position: absolute;
  right: 7px;
  margin-top: 200px;
  z-index: 999999;
  cursor: pointer;
}
.first-flow-image {
  float: left;
}
.flow-image {
  float: right;
  max-width: 120px;
  height: auto;
}
.flow-hr-line {
  position: absolute;
  width: 100%;
  background: #408df4;
  height: 2px;
  margin-top: 100px;
  z-index: -1;
}
.post-job-btn {
  float: right;
}
.banner-bottom {
  object-fit: cover;
}
.banner-bottom-title {
  position: absolute;
  text-align: center;
  margin: 0;
  padding-top: 45px;
  left: 0;
  right: 0;
  color: #ffffff;
}

.banner-bottom-head {
  margin-top: -25px !important;
}

.banner-bottom-text,
.banner-bottom-head {
  position: absolute;
  text-align: center;
  margin: 0;
  padding-top: 45px;
  left: 0;
  right: 0;
  color: #ffffff;
  @include sm-breakpoint {
    font-size: 18px;
  }

}

.banner-bottom-text {
  margin-top: 45px;
  @include sm-breakpoint {
    margin-top: 15px !important;
  }
}

.banner-bottom-button {
  position: absolute;
  text-align: center;
  margin: 0;
  padding-top: 135px;
  left: 0;
  right: 0;
  color: #ffffff;
}

.banner-bottom-img {
  object-fit: cover !important;
}

.chat-icon {
  display: none;
  position: fixed;
  top: 92%;
  right: 40px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
}

.chat-icon a {
  display: block;
  text-align: center;
  padding: 16px;
  transition: all 0.3s ease;
  color: white;
  font-size: 20px;
}
.footer {
  background: #383838;
  width: 100%;
  height: auto;
}
.footer-titles {
  font-size: 14px;
  padding-top: 30px;
  color: #fff;
  font-weight: bold;
}
a.footer-menu-urls {
  display: block;
  color: #dfdfdf;
  text-decoration: none;
  line-height: 28px;
}
a:hover.footer-menu-urls {
  text-decoration: underline;
}
.category-list {
  -moz-column-count: 4;
  -moz-column-gap: 30px;
  -webkit-column-count: 4;
  -webkit-column-gap: 30px;
  column-count: 4;
  column-gap: 30px;
  font-size: 14px;
  line-height: 15px;
  color: #d4d4d4;
}
.services {
  width: 70%;
  float: left;
}
.company {
  width: 30%;
  float: right;
}

.footer-menu {
  font-size: 14px;
}
.see-more {
  display: none;
}

.footer-bottom {
  // position: relative;
  // background: #2b2b2b;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 60px;
  // bottom: 0;
  // margin-top: 0;
  padding-bottom: 40px;

  // position: fixed;
  left: 0;
  // bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
}

.auth-background {
  .footer-bottom {
    // position: absolute;
    background: #2b2b2b;
  }
}

.footer-line {
  margin-top: 20px;

  .footer-social-fb {
    margin-right: 20px;
  }
}
.insta-icon {
  margin-left: 20px;
}
.copyright-text {
  font-size: 14px;
  text-align: center;
  vertical-align: middle;
  color: #6d6d6d;
}
.copyright-symbol {
  font-size: 30px;
}
.language-icon {
  float: right;
}
a.language {
  font-size: 14px;
  float: right;
  margin-left: 10px;
  color: #f5f5f5;
  cursor: pointer;
}

.howitworks__mobile {
  display: none;
}

/*Device compatibility*/
@media screen and (max-width: 600px) {
  h1 {
    font-size: 28px;
    font-weight: bold;
  }
}
@media screen and (max-height: 450px) {
  .overlay a {
    font-size: 20px;
  }
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}

@media screen and (max-width: 1200px) {
  .nav-button {
    display: block;
  }
}

@media screen and (max-width: 1200px) {
  .navi-menu {
    display: none;
  }
}

@media screen and (max-width: 521px) {
  .logo {
    width: 100%;
  }
}
@media screen and (max-width: 521px) {
  .cards-title {
    padding-left: 40px;
    font-size: 20px;
  }
}
@media screen and (max-width: 521px) {
}
@media screen and (max-width: 521px) {
  .user-login {
    float: right;
    display: block;
    width: 38px;
    height: 38px;
    padding: 10px;
    background: #f5f5f5;
    border-radius: 20px;
  }
}

@media screen and (max-width: 769px) {
  .categories-large {
    display: none;
    padding: 0 15px 0 0;
    margin-top: 25px;
  }
  
}
@media screen and (min-width: 769px) {
  .categories-small {
    display: none;
  }

}
@media screen and (max-width: 769px) {
  .categories-title {
    float: left;
    width: 70%;
  }
}
@media screen and (max-width: 769px) {
  .seeAll-categories {
    display: block;
    float: left;
    width: 30%;
    text-align: right;
    margin-top: 10px;
    font-weight: bold;
    color: #408df4;
  }
}
// @media screen and (max-width: 769px) {
//   .categories-small-container {
//     width: 100%;
//     margin-top: 15px;
//     overflow-x: scroll;
//     overflow-y: hidden;
//     -webkit-overflow-scrolling: touch;
//     white-space: nowrap;
//   }
// }
// @media screen and (max-width: 769px) {
//   .categories-small {
//     min-height: 150px;
//     width: 100%;
//     display: inline-flex;
//     overflow-x: auto;
//     overflow-y: hidden;
//     -ms-overflow-style: -ms-autohiding-scrollbar;
//     -webkit-overflow-scrolling: touch;
//     white-space: nowrap;
//   }
// }
// @media screen and (max-width: 769px) {
//   .categories-small::-webkit-scrollbar {
//     display: none;
//   }
// }
// @media screen and (max-width: 769px) {
//   .cat-horizontal::-webkit-scrollbar {
//     display: none;
//   }
// }

@media screen and (max-width: 769px) {
  .card-bottom-hline {
    display: none;
  }
}
@media screen and (max-width: 769px) {
  .card-description {
    display: none;
  }
}
@media screen and (max-width: 769px) {
  .flow-container {
    display: none;
  }
}
@media screen and (max-width: 769px) {
  .card-container {
    width: 220px;
  }
}
@media screen and (max-width: 769px) {
  .card-img {
    width: 220px;
  }
}
@media screen and (max-width: 769px) {
  .rate-container {
    width: 200px;
    height: 38px;
  }
}
@media screen and (max-width: 769px) {
  .rate-percent {
    background: none;
  }
}
@media screen and (max-width: 769px) {
  .card-name {
    font-size: 15px;
  }
}
@media screen and (max-width: 769px) {
  .card-job-title {
    font-size: 12px;
  }
}
@media screen and (max-width: 769px) {
  .cata-sub-nav {
    -webkit-mask-image: unset;
  }
}
@media screen and (max-width: 769px) {
  .nav-prev {
    visibility: hidden;
  }
}
@media screen and (max-width: 769px) {
  .nav-next {
    visibility: hidden;
  }
}
@media screen and (max-width: 992px) {
  .category-list {
    line-height: 15px;
    -moz-column-count: 1;
    -webkit-column-count: 1;
    column-count: 1;
  }
}
@media screen and (max-width: 769px) {
  .footer-menu {
    line-height: 10px;
    color: #d4d4d4;
  }
}
@media screen and (max-width: 992px) {
  .services {
    width: 100%;
    float: left;
  }
}
@media screen and (max-width: 992px) {
  .company {
    width: 100%;
    float: left;
  }
}

@media screen and (max-width: 992px) {
  #more {
    display: none;
  }
}
@media screen and (max-width: 992px) {
  #moree {
    display: none;
  }
}
@media screen and (max-width: 992px) {
  .see-more {
    display: block;
    cursor: pointer;
    font-size: 14px;
  }
}
@media screen and (max-width: 769px) {
  .language {
    display: none;
  }
}
@media screen and (max-width: 769px) {
  .chat-icon {
    right: 15px;
  }
}

.hero {
  img {
    object-fit: cover;
  }
}

.hero-text {
  position: absolute;
  left: 0;
  right: 0;
  margin: 150px 15px;
  text-align: center;
  color: #fff;

  @include mobile-breakpoint {
    margin: 150px auto;
  }

  h1 {
    font-weight: normal;
  }
}

@media screen and (max-width: 450px) {
  .howitworks__mobile {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
  }
}


